import { Stack, Alert, Typography, CircularProgress } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { PodSessionCard } from "./PodSessionCard";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useCallback, useEffect, useState } from "react";
import { GetAccountability } from "../../Services/PodComunnityService";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RefreshIcon from "@mui/icons-material/Refresh";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import Button from "../Button";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

export const FinishEngagement = () => {
  // ====================== STATE
  const [engagement, setEngagement] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [postLink, setPostLink] = useState("");

  // ====================== HOOKS
  const { session_id } = useParams();
  const navigate = useNavigate();

  // ====================== API
  const fetchAccountability = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await GetAccountability(session_id);
      const data = await response.json();
      if (data.status === "success") {
        setEngagement([...data.data.data]);
        setPostLink(data.data.author_post_link);
      }
    } catch (error) {
      console.error("Error fetching accountability:", error);
    } finally {
      setIsLoading(false);
    }
  }, [session_id]);

  // ====================== EFFECT
  useEffect(() => {
    if (!isEmpty(session_id)) {
      fetchAccountability();
    }
  }, [fetchAccountability, session_id]);

  // ====================== HANDLER
  const onHandleReport = () => {
    return navigate("/ReportMember");
  };

  const onHandleMySeePost = () => {
    window.open(postLink, "_blank").focus();
  };

  // ====================== RENDER
  const renderRightView = (isEngaged) => {
    if (!isEngaged) {
      return (
        <Stack direction={"row"} gap={1} alignItems="center">
          <HistoryToggleOffIcon style={{ color: "#415058" }} />
          <Typography color="#415058" fontWeight={600} fontSize={".875rem"}>
            Pending
          </Typography>
        </Stack>
      );
    }
    return (
      <>
        <Stack direction={"row"} gap={1} alignItems="center">
          <DoneAllIcon sx={{ color: "#0A72C3" }} />
          <Typography color="#0A72C3">Engaged</Typography>
        </Stack>
        <Button
          variant="outlined"
          color="error"
          style={{
            color: "#D61F1F",
            border: "1px solid #D61F1F",
            borderRadius: ".65rem",
            fontWeight: 600,
          }}
          onClick={onHandleReport}
        >
          Report
        </Button>
      </>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", py: 15 }}
        >
          <CircularProgress />
        </Stack>
      );
    }

    if (isEmpty(engagement)) {
      return (
        <Stack justifyContent={"center"} alignItems={"center"} py={10}>
          <Typography color="#415058" fontWeight={700} fontSize={"1rem"}>
            No people have been allocated to engage with your post yet
          </Typography>
        </Stack>
      );
    }

    return (
      <Stack gap={3}>
        {engagement.map((item) => (
          <PodSessionCard
            isCompletedEngage
            pod={item}
            rightItem={renderRightView}
          />
        ))}
      </Stack>
    );
  };

  // ====================== RETURN
  return (
    <Stack gap={2} pt={1}>
      <Alert
        severity="success"
        icon={<CheckCircleIcon sx={{ color: "#64BE37" }} />}
        sx={{
          border: "1px solid #64BE37",
          borderRadius: "1rem",
          color: "#33621C",
          backgroundColor: "#EFF8EB",
          fontWeight: 500,
          paddingBlock: ".5rem",
        }}
      >
        You have completed your engagements. While waiting for the session to
        end, here is your post engagements status.
      </Alert>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack gap={1}>
          <Typography color="#415058" fontWeight={700} fontSize={"1rem"}>
            People allocated to engage with your post
          </Typography>
          <Typography color="#415058" fontSize={".875rem"}>
            Please report anyone marked as engaged with your post but who did
            not actually engage.
          </Typography>
        </Stack>
        <Stack direction="row" gap={1.5} maxHeight={"2rem"}>
          <Button
            sx={{
              "&:hover": {
                backgroundColor: "#0A72C3",
                color: "#FFFFFF",
                borderColor: "#085A9E",
              },
            }}
            startIcon={<OpenInNewIcon />}
            variant="outlined"
            style={{
              backgroundColor: "#F3F8FC",
              fontWeight: 600,
              color: "#0A72C3",
              border: "1px solid #0A72C3",
              paddingInline: "1rem",
              borderRadius: ".6rem",
              textAlign: "center",
            }}
            onClick={onHandleMySeePost}
            size="small"
          >
            See my post
          </Button>
          <Button
            startIcon={<RefreshIcon />}
            style={{
              backgroundColor: "#F2F2F3",
              fontWeight: 600,
              color: "#415058",
              border: "1px solid #415058",
              paddingInline: "1rem",
              borderRadius: ".6rem",
              textAlign: "center",
            }}
            onClick={fetchAccountability}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>
      {renderContent()}
    </Stack>
  );
};

export default FinishEngagement;
