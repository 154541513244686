import { Box, Typography } from "@mui/material";
import { PrimaryButton, StyledLink } from "./StyledButton";
import { mixpanelCTAEvent } from "../../../Utils/mixpanelUtils";

const LISTS_FOOTER_TEXT = "Upgrade your account to create multiple lists for different groups of prospects";
const PROSPECTS_FOOTER_TEXT =
  "Be the first to engage and get their attention. Upgrade to the Pro Plan now and save more than 10 prospects to monitor.";

const styles = {
  btn: {
    width: "130px",
    alignSelf: "end",
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
  },
  footerText: {
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#0A72C3",
  },
};

function TableFooterFree({ ressource }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="2.5rem"
      sx={{ width: "100%" }}
    >
      <StyledLink
        to="/Upgrade"
        target="_blank"
        rel="noreferrer"
        onClick={() => mixpanelCTAEvent({ buttonName: "Upgrade Now", ctaPosition: "footer", screenName: "Free account" })}
      >
        <PrimaryButton sx={styles.btn}>
          <img style={{ width: "16px", height: "16px" }} src="/images/crown.svg" alt="crown icon" />
          <span>Upgrade now</span>
        </PrimaryButton>
      </StyledLink>
      <Typography sx={styles.footerText}>
        {ressource === "lists" ? LISTS_FOOTER_TEXT : PROSPECTS_FOOTER_TEXT}
      </Typography>
    </Box>
  );
}

export default TableFooterFree;
