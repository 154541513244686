import { Box, Skeleton, Stack, Typography } from "@mui/material";
import Button from "../../Button";

export const PlanCard = (props) => {
  const {
    title,
    subTitle,
    icon,
    text,
    textProps,
    buttonText,
    buttonProps,
    isTrial,
    loading,
    onClick,
    color,
    ...restProps
  } = props;

  // ====================== RETURN
  if (loading) {
    return (
      <Skeleton
        variant="rounded"
        sx={{
          borderRadius: "12px",
        }}
        height={"100%"}
        width={"50%"}
        minHeight={200}
      />
    );
  }
  const renderFooter = () => {
    if (text) {
      return (
        <Typography
          color={color}
          fontSize={".875rem"}
          fontWeight={500}
          paddingBlock={".4rem"}
          {...textProps}
        >
          {text}
        </Typography>
      );
    }
    return (
      <Button {...buttonProps} onClick={onClick}>
        <Typography color={color} fontSize={".875rem"} fontWeight={500}>
          {buttonText}
        </Typography>
      </Button>
    );
  };
  return (
    <Stack
      width="50%"
      {...restProps}
      border="0.5px solid rgba(0, 0, 0, 0.10)"
      p={2}
      borderRadius={2}
      position={"relative"}
    >
      {isTrial && (
        <Box
          position={"absolute"}
          border="1px solid #EAAB3E"
          borderRadius={"10px"}
          p={0.5}
          px={2}
          backgroundColor="#FEFBF5"
          boxShadow={"0px 4px 4px 0px rgba(234, 171, 62, 0.12);"}
          top={-16}
          left={0}
          right={0}
          marginLeft={"auto"}
          marginRight={"auto"}
          color="#EAAB3E"
          width={"fit-content"}
          fontWeight={500}
        >
          Currently on trial
        </Box>
      )}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <Stack gap={1}>
          <Typography fontWeight={600} color="#415058">
            {title}
          </Typography>
          <Typography fontWeight={500} color="#415058" fontSize={".875rem"}>
            {subTitle}
          </Typography>
        </Stack>
        {icon}
      </Stack>
      <Box mt="auto">{renderFooter()}</Box>
    </Stack>
  );
};

export default PlanCard;
