import {
  Alert,
  Breadcrumbs,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { IconButton, Stack } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ErrorIcon from "@mui/icons-material/Error";
import { ContainerWrapper } from "../common/ContainerWrapper";
import { SessionWrapper } from "./SessionWrapper";
import Engagement from "./Engagement";
import FinishEngagement from "./FinishEngagement";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useCallback, useEffect, useState } from "react";
import InstallExtensionModal from "./containers/InstallExtension";
import { useParams } from "react-router-dom";
import { GetEngagement } from "../../Services/PodComunnityService";
import { isEmpty } from "lodash";
import toast from "react-hot-toast";

export const PodSessionCommunity = () => {
  const { isExtensionInstalled, checkExtensionInstalled } = useGlobalContext();

  // ====================== PARAMS
  const { session_id } = useParams();

  // ====================== STATE
  const [engagement, setEngagement] = useState([]);
  const [deadlineTime, setDeadlineTime] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // ====================== VARIABLES
  const hasExtensionModalOpened =
    localStorage.getItem("hasExtensionModalOpened") === "true";
  const isCompleted =
    !isEmpty(engagement) && engagement.every((data) => data.is_complete);

  // ====================== API
  const fetchEngagement = useCallback(async () => {
    try {
      const response = await GetEngagement(session_id);
      const data = await response.json();
      if (data.status === "success") {
        setEngagement([...data.data.data]);
        setDeadlineTime(data.data.engagementDeadline);
      } else {
        setEngagement([]);
      }
    } catch (error) {
      setEngagement([]);
      toast.error("Error fetching engagement. Please try again later");
    } finally {
      setIsLoading(false);
    }
  }, [session_id]);

  // ====================== HANDLER
  const onCompleted = () => {
    fetchEngagement();
  };

  const onHandleBack = () => {
    window.location.href = "/PodCommunity";
  };

  // ====================== EFFECTS
  useEffect(() => {
    const hasModalOpen = localStorage.getItem("hasExtensionModalOpened");
    if (!hasModalOpen) {
      localStorage.setItem("hasExtensionModalOpened", "true");
    }
  }, []);
  useEffect(() => {
    if (session_id) {
      fetchEngagement();
    }
  }, [fetchEngagement, session_id]);

  useEffect(() => {
    checkExtensionInstalled();
  }, [checkExtensionInstalled]);

  // ====================== RENDER
  const renderContent = () => {
    if (isLoading) {
      return (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", py: 15 }}
        >
          <CircularProgress />
        </Stack>
      );
    }

    if (isEmpty(engagement)) {
      return (
        <Stack justifyContent={"center"} alignItems={"center"} py={10}>
          <Typography color="#415058" fontWeight={700} fontSize={"1rem"}>
            No links have been assigned to you yet
          </Typography>
        </Stack>
      );
    }

    if (isCompleted) {
      return <FinishEngagement />;
    }

    return <Engagement engagement={engagement} onCompleted={onCompleted} />;
  };

  // ====================== RETURN
  return (
    <ContainerWrapper gap={2}>
      <InstallExtensionModal
        open={!isExtensionInstalled && !hasExtensionModalOpened}
      />
      <Stack direction={"row"} alignItems={"center"} gap={2}>
        <IconButton
          sx={{
            borderRadius: ".65rem",
            border: "1px solid #E6E9EC",
            background: "#F7F7F7",
          }}
          size="small"
          onClick={onHandleBack}
        >
          <ChevronLeftIcon sx={{ color: "#415058" }} />
        </IconButton>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="#415058"
            fontWeight={500}
            href="/PodCommunity"
          >
            Community
          </Link>
          <Typography color="#415058" fontWeight={500}>
            Live session
          </Typography>
        </Breadcrumbs>
      </Stack>
      <Alert
        severity="warning"
        icon={<ErrorIcon sx={{ color: "#EAAB3E" }} />}
        sx={{
          border: "1px solid #EAAB3E",
          borderRadius: "1rem",
          color: "#8B5800",
          fontWeight: 500,
        }}
      >
        Protect your <span style={{ fontWeight: 700 }}>Reputation Score</span>{" "}
        by engaging on time and making meaningful and relevant comments.{" "}
        <Link
          href="https://apps.engage-ai.co/CustomizeAI"
          target="_blank"
          fontWeight={700}
          color="#8B5800"
        >
          Customize your tones here
        </Link>
      </Alert>
      <SessionWrapper time={deadlineTime}>{renderContent()}</SessionWrapper>
    </ContainerWrapper>
  );
};

export default PodSessionCommunity;
