import React from "react";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const PercentageChip = (props) => {
  const { value } = props;
  const isPositive = value >= 0;
  if (value === 0) {
    return null;
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      px={1.5}
      py={0.5}
      borderRadius={50}
      bgcolor={isPositive ? "#EFF8EB" : "#FDECEA"}
      color={isPositive ? "#64BE37" : "#E53935"}
    >
      {isPositive ? (
        <ArrowUpward fontSize="medium" />
      ) : (
        <ArrowDownward fontSize="medium" />
      )}
      <Typography fontSize={"1.25rem"} fontWeight={500}>
        {Math.abs(value)}%
      </Typography>
    </Box>
  );
};

export default PercentageChip;
