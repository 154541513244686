import { useContext } from "react";
import { Context } from "../../../Store/Store";
import Nav from "./Nav";

const styles = {
  Sidebar: {
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    backgroundColor: "#0A72C3",
    color: "white",
    gridRow: " 1/-1",
    height: "100vh",
  },
  Logo: {
    paddingLeft: "12px",
  },
};

function Sidebar() {
  const context = useContext(Context);
  const state = context[0];
  const userIsMember = state.user?.data?.group === "Member" || state.user?.data?.podAccess === true;

  return (
    <div style={styles.Sidebar}>
      <div>
        <img
          src="/images/sidebar_logo.png"
          alt="logo"
          style={{ ...styles.Logo, marginBottom: userIsMember ? "1.5rem" : "2.5rem" }}
        />
      </div>
      <Nav />
    </div>
  );
}

export default Sidebar;
