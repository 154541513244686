import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../Store/Store";
import { InteractChart } from "./ChartBox/InteractChart";
import { CommentChart } from "./ChartBox/CommentChart";
import { PropsectResponseChart } from "./ChartBox/ProspectResponseChart";
import { SuggestedNextStepContainer } from "./NextStep";
import { GetDashboardAnalytics } from "../../Services/AdminDashboardService";
import toast from "react-hot-toast";
import { times } from "lodash";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import moment from "moment";
export const MainV2 = () => {
  // ====================== STATE
  const [metricsData, setMetricsData] = useState({});
  const [loading, setLoading] = useState(true);

  // ====================== CONTEXT
  const [state] = useContext(Context);

  // ====================== VARIABLES
  const user = state?.user?.data;
  const userName = user?.firstName;
  const userId = user?.id;
  const isFreeTrial = user?.group === "Trial";
  const targetDate = moment(user?.trial_end_date);
  const today = moment();

  const daysLeft = targetDate.diff(today, "days");

  // ====================== EFFECT
  useEffect(() => {
    const fetchMetrics = async () => {
      if (!userId) return;
      try {
        const res = await GetDashboardAnalytics(userId);
        const data = await res.json();
        setMetricsData({ ...data });
      } catch (error) {
        toast.error("Error fetching metrics. Please try again later");
      } finally {
        setLoading(false);
      }
    };
    fetchMetrics();
  }, [userId]);

  // ====================== RENDER
  const renderChart = () => {
    if (loading) {
      return times(3, (index) => (
        <Box key={index} height={"100%"} gridColumn={"span 1"}>
          <Skeleton
            variant="rounded"
            sx={{ borderRadius: "1rem" }}
            width={"100%"}
            height={400}
          />
        </Box>
      ));
    }
    return (
      <>
        <Box gridColumn={"span 1"} height={"100%"}>
          <InteractChart data={metricsData?.percentageInteraction} />
        </Box>
        <Box gridColumn={"span 1"} height={"100%"}>
          <CommentChart data={metricsData?.comments} />
        </Box>
        <Box gridColumn={"span 1"} height={"100%"}>
          <PropsectResponseChart data={metricsData?.prospectsResponses} />
        </Box>
      </>
    );
  };

  const renderFreeTrialBox = () => {
    return (
      <Box display={"grid"} gridTemplateColumns={"1fr 3fr"} height={"100%"}>
        <Box gridColumn={"span 1"} height="100%" width={"100%"} minWidth={0}>
          <Stack
            borderRadius={"1rem"}
            border="1px solid #B47B1F"
            backgroundColor="#FDF7EC"
            boxShadow={"0px 4px 24px 0px rgba(90, 113, 130, 0.15)"}
            paddingInline={3}
            paddingBlock={2}
            color="#B47B1F"
            gap={1.5}
          >
            <Stack gap={1}>
              <AccessTimeFilledIcon
                sx={{ color: "#B47B1F", width: 30, height: 30 }}
              />
              <Typography fontWeight={600} fontSize={"1rem"}>
                Free trial
              </Typography>
            </Stack>
            <Stack>
              <Typography
                color="#825916"
                fontWeight={600}
                fontSize={"2.5rem"}
                lineHeight={1}
              >
                {daysLeft}
              </Typography>
              <Typography fontSize={"1rem"}>
                {daysLeft === 1 ? "day" : "days"} left
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  };

  // ====================== RETURN
  return (
    <Box pt="1.25rem" px={"1.5rem"} pb={"4rem"}>
      <Stack direction={"column"} gap={3}>
        <Stack direction={"column"} gap={1}>
          <Typography fontWeight={700} fontSize={"1.5rem"}>
            Welcome {userName}!
          </Typography>
          <Typography color="#707981" fontSize={"1rem"}>
            {isFreeTrial
              ? "Let's take a look at your analytics"
              : "Measure your impact, optimize your outreach, and turn connections into opportunities."}
          </Typography>
        </Stack>
        {isFreeTrial && renderFreeTrialBox()}
        <Box
          display={"grid"}
          sx={{
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
            },
          }}
          height={"100%"}
          gap={3}
        >
          {renderChart()}
        </Box>
        <SuggestedNextStepContainer />
      </Stack>
    </Box>
  );
};
