import { Stack } from "@mui/material";
import { useModal } from "../ModalProvider";
import {
  ModalCancelActionButton,
  ModalPlainButton,
} from "../../components/ModalButton";

export const FirstStepConfirmation = () => {
  // ====================== HOOKS
  const { nextStep, closeModal } = useModal();

  // ====================== RETURN
  return (
    <Stack gap={2}>
      <ModalCancelActionButton onClick={nextStep}>
        Yes, I want to unsubscribe
      </ModalCancelActionButton>
      <ModalPlainButton onClick={closeModal}>
        No, it was a mistake
      </ModalPlainButton>
    </Stack>
  );
};
