import { Alert, Stack, Typography } from "@mui/material";
import { PodSessionCard } from "./PodSessionCard";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "../Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { MarkComplete } from "../../Services/PodComunnityService";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import toast from "react-hot-toast";
export const Engagement = (props) => {
  const { engagement, onCompleted } = props;

  const navigate = useNavigate();
  const [engagementList, setEngagementList] = useState([]);

  const isAllEngagementCompleted =
    !isEmpty(engagementList) &&
    engagementList.every((item) => item.is_complete);

  // ====================== EFFECTS
  useEffect(() => {
    setEngagementList(engagement);
  }, [engagement]);

  // ====================== HANDLER
  const onHandleComplete = (id) => {
    const updatedList = engagementList.map((item) => {
      if (item.id === id) {
        return { ...item, is_complete: true };
      }
      return item;
    });
    setEngagementList(updatedList);
  };

  // ====================== HANDLERS
  const onEngageClick = async (postLink, pod) => {
    window.open(postLink, "_blank").focus();

    try {
      const res = await MarkComplete(pod.id);
      const data = await res.json();
      if (data.status === "success") {
        onHandleComplete(pod.id);
      }
    } catch (error) {
      toast.error("Error marking complete. Please try again later");
    }
  };

  // ====================== RENDER
  const renderRightItem = (isEngaged, pod) => {
    const postLink = pod?.link;

    if (isEngaged) {
      return (
        <Stack direction={"row"} gap={1} alignItems="center">
          <DoneIcon style={{ color: "#7A858B" }} />
          <Typography color="#7A858B" fontWeight={600} fontSize={".875rem"}>
            Completed
          </Typography>
        </Stack>
      );
    }
    return (
      <>
        <Button
          variant="text"
          style={{
            paddingInline: "1.5rem",
            height: "2.875em",
          }}
          onClick={() => navigate("/ReportMember")}
        >
          <Typography
            style={{
              color: "#415058",
              fontWeight: 600,
              fontSize: ".875rem",
            }}
          >
            Report
          </Typography>
        </Button>
        <Button
          startIcon={<OpenInNewIcon />}
          style={{
            paddingInline: "1.5rem",
            height: "2.875em",
          }}
          onClick={() => onEngageClick(postLink, pod)}
        >
          <Typography
            style={{
              color: "#FFF",
              fontWeight: 600,
              fontSize: ".875rem",
            }}
          >
            Engage
          </Typography>
        </Button>
      </>
    );
  };

  // ====================== RETURN
  return (
    <Stack gap={2}>
      <Stack gap={1}>
        {isAllEngagementCompleted && (
          <Stack pb={1}>
            <Alert
              severity="success"
              icon={<CheckCircleIcon sx={{ color: "#64BE37" }} />}
              sx={{
                border: "1px solid #64BE37",
                borderRadius: "1rem",
                color: "#33621C",
                backgroundColor: "#EFF8EB",
                fontWeight: 500,
                paddingBlock: ".5rem",
              }}
            >
              Congrats! You have completed all engagements.{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={onCompleted}
              >
                Please click here
              </span>{" "}
              to refresh and see who are the person that is allocated to engage
              with your post.
            </Alert>
          </Stack>
        )}
        <Typography color="#415058" fontWeight={700} fontSize={"1rem"}>
          Complete your engagements
        </Typography>
        <Typography color="#415058" fontSize={".875rem"}>
          Engage with the following posts and their authors will reciprocate by
          engaging with your post
        </Typography>
      </Stack>
      <Stack gap={3}>
        {engagementList.map((item) => (
          <PodSessionCard
            id={item.id}
            pod={item}
            onHandleComplete={onHandleComplete}
            rightItem={renderRightItem}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default Engagement;
