import { useState, useEffect, useCallback } from "react";
import { Typography } from "@mui/material";

export const CountdownTimer = (props) => {
  const { targetTime, render, ...restProps } = props;
  const calculateTimeLeft = useCallback(() => {
    const countDownDate = new Date(targetTime).getTime();
    const now = new Date().getTime();

    const difference = countDownDate - now;
    if (difference <= 0) return { hours: 0, minutes: 0, seconds: 0 };

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  }, [targetTime]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft, targetTime]);

  if (render) {
    return render(timeLeft);
  }

  if (
    timeLeft.hours === 0 &&
    timeLeft.minutes === 0 &&
    timeLeft.seconds === 0
  ) {
    return (
      <Typography color="#D61F1F" fontWeight={600} {...restProps}>
        0d 0h 0m 0s
      </Typography>
    );
  }

  return (
    <Typography
      color="rgba(255, 255, 255, 0.70)"
      fontWeight={500}
      {...restProps}
    >
      Ends in {timeLeft.days ? `${timeLeft.days}d ` : ""}
      {timeLeft.hours ? `${timeLeft.hours}h ` : ""}
      {timeLeft.minutes ? `${timeLeft.minutes}min ` : ""}
      {timeLeft.seconds ? `${timeLeft.seconds}s` : ""}
    </Typography>
  );
};

export default CountdownTimer;
