import { useEffect, useState } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { GetCTACard } from "../../../Services/BillingService";
import CountdownTimer from "../../CountdownTimer";
import Button from "../../Button";

export const CTACard = () => {
  // ====================== STATE
  const [ctaCard, setCTACard] = useState({});
  const [loading, setLoading] = useState(false);

  // ====================== EFFECT
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await GetCTACard();
        const data = await res.json();
        setCTACard({ ...data });
      } catch (error) {
        console.error("Error fetching CTA card:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setCTACard]);

  // ====================== HELPERS
  const replaceUnicodeWithEmoji = (text) => {
    return text?.replace(/U\+([0-9A-Fa-f]{4,5})/g, (_, hex) =>
      String.fromCodePoint(parseInt(hex, 16))
    );
  };

  // ====================== HANDLER
  const onHandleClick = () => {
    window.open(ctaCard?.cta_link)
  };

  // ====================== RENDER
  const renderContent = () => {
    if (loading)
      return (
        <Skeleton
          variant="rounded"
          sx={{
            borderRadius: "12px",
          }}
          height={"100%"}
          width={"100%"}
          minHeight={150}
        />
      );

    return (
      <Stack
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(4, 39, 66, 0.50) 0%, #042742 74.05%), url(${ctaCard?.background_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        px={3}
        height={"100%"}
        py={1}
        pb={4}
        borderRadius={"12px"}
        gap={4}
      >
        <Stack pt={4} gap={2} color="#FFF">
          <Typography color="#DBDDDE" fontWeight={400} fontSize={".875rem"}>
            {replaceUnicodeWithEmoji(ctaCard?.label)}
          </Typography>
          <Typography
            fontWeight={"bold"}
            fontSize={"1.35rem"}
            width={{ xs: "100%", xl: "50%" }}
          >
            {ctaCard?.title}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: "column", xl: "row" }}
          alignItems={{ xs: "flex-start", xl: "center" }}
          gap={2}
        >
          <Button
            style={{
              backgroundColor: "#FFF",
              color: "#042B4A",
              fontWeight: "500",
              paddingInline: "1.5rem",
            }}
            sx={{
              borderRadius: ".5rem",
              "&:hover": {
                backgroundColor: "#E0E0E0 !important",
              },
            }}
            onClick={onHandleClick}
          >
            {ctaCard?.cta_text}
          </Button>

          {ctaCard?.count_down && (
            <CountdownTimer targetTime={ctaCard?.count_down_date} />
          )}
        </Stack>
      </Stack>
    );
  };

  // ====================== RETURN
  return (
    <Box
      height="100%"
      width={"100%"}
      gridColumn={{ xs: "span 12", lg: "span 4" }}
    >
      {renderContent()}
    </Box>
  );
};
