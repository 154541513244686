import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./Login/LoginPage";
import EmailVerification from "./Login/EmailVerification";
import ResetPassword from "./Login/ResetPassword";
import AccountabilityPage from "./Accountability/AccountabilityPage";
import EngagementPage from "./Engagement/EngagementPage";
import AccountPage from "./Account/AccountPage";
import PrivateRoute from "./PrivateRoute";
import PodRoute from "./PodRoute";
import AdminRoute from "./AdminRoute";
// import ChangePassword from "./ChangePassword/ChangePasswordPage";
// import ForgotPassword from "./ChangePassword/ForgotPasswordPage";
import ChangeEmail from "./ChangeEmail/ChangeEmailPage";
import Homepage from "./Homepage/Homepage";
import AdminPage from "./Admin/AdminPage";
import InstitutePage from "./Institute/Institute";
import LinkedinHashtagTrend from "./Homepage/LinkedinHashtagTrend";
import LinkedinHashtagTrendDemo from "./Onboarding/LinkedinHashtagTrendDemo";
import UserSourceSurvey from "./Onboarding/UserSourceSurvey";
// import CreateAccount from "./Onboarding/CreateAccount";
import HowItWorks from "./Onboarding/HowItWorks";
import Welcome from "./Onboarding/Welcome";
import StaySubscribed from "./Onboarding/StaySubscribed";
import NotificationPage from "./AnnouncementPage/NotificationPage";
import IndividualNotification from "./AnnouncementPage/IndividualNotification";
import LinkedinTraining from "./Homepage/LinkedinTraining";
import NurtureProspects from "./NurtureProspects/NurtureProspects";
import NurtureLeadsDemo from "./Onboarding/NurtureLeadsDemo";
import AISmartCommentingDemo from "./Onboarding/AISmartCommentingDemo";
import PipedriveRedirect from "./Onboarding/PipedriveRedirect";
import PipedriveRedirectMember from "./Onboarding/PipedriveRedirectMember";
import HubSpotRedirect from "./Onboarding/HubSpotRedirect";
import HubSpotRedirectMember from "./Onboarding/HubSpotRedirectMember";
import ReportMember from "./Complaints/ReportMember";
import ReportForm from "./Admin/MembersReportForm";
import HotApplicants from "./Admin/HotApplicants";
import Notifications from "./Admin/Notifications";
import LinkSubmitPageNew from "./LinkSubmission/LinkSubmitPageNew";
import FPInstitute from "./Onboarding/FPInstitute";
import Dashboard from "./Admin/Dashboard";
import ExitSurvey from "./Onboarding/ExitSurvey";
import Boost from "./Homepage/Boost";
// import BillingPage from "./Account/Billing";
import ExitSurveyEngageAI from "./EngageAI/ExistPage/ExitSurvey";
import Feedback from "./Topbar/Feedback";
import Integrations from "./Integration/integrations";
import Generate from "./Admin/Generate";
import SalesforceRedirect from "./Onboarding/SalesforceRedirect";
import LinkedinRedirect from "./Onboarding/LinkedInAuthorizationRedirect";
import Ecosystem from "./EngageAI/Ecosystem";
import WelcomeEngageAI from "./EngageAI/WelcomePage/WelcomeEngageAI";
import WhyEngageAI from "./EngageAI/WhyEAI";
import EngageWithPeopleWhoMatter from "./EngageAI/EngageWithPeopleWhoMatter";
import OptimizeYourNurturingEfforts from "./EngageAI/OptimizeYourNurturingEfforts";
import NurtureToImproveConversion from "./EngageAI/NurtureToImproveConversion";
// import Settings from "./EngageAI/Settings";
import CustomizeAi from "./CustomizeAi/index";
import PaymentSuccess from "./Payment/Success";
import PaymentFail from "./Payment/Cancel";
import Upgrade from "./Payment/Upgrade";
import Executive from "./Payment/Executive";
import Premium from "./Payment/Premium";
import Starter from "./Payment/Starter";
import CreateAccountAWS from "./Onboarding/CreateAccountAWS";
import Lists from "./NurtureProspects/Tabs/Lists/Index";
import Prospects from "./NurtureProspects/Tabs/Prospects/Index";
import Posts from "./NurtureProspects/Tabs/Posts/Index";
import AddNewList from "./NurtureProspects/Tabs/Lists/AddNewList";
import UpdateList from "./NurtureProspects/Tabs/Lists/UpdateList";
import AppLayout from "./AppLayout";
import BillingPageV2 from "./Account/BillingV2";
import { MainV2 } from "./HomepageV2";
import PodCommunity from "./PodCommunity";
import PodSessionCommunity from "./PodSessionCommunity";
import { PostCommunityHistory } from "./PodCommunity/History";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const AllRoutes = () => (
  // the order here matters, takes the first match!
  <GoogleOAuthProvider clientId={clientId}>
    <Routes>
      <>{/** Admin Pages */}</>
      <Route
        path="/Admin"
        element={
          <AdminRoute>
            <AdminPage />
          </AdminRoute>
        }
      />
      <Route
        path="/Admin/Notifications"
        element={
          <AdminRoute>
            <Notifications />
          </AdminRoute>
        }
      />
      <Route
        path="/Admin/ReportForm"
        element={
          <AdminRoute>
            <ReportForm />
          </AdminRoute>
        }
      />
      <Route
        path="/Admin/HotApplicants"
        element={
          <AdminRoute>
            <HotApplicants />
          </AdminRoute>
        }
      />
      <Route
        path="/Admin/Dashboard"
        element={
          <AdminRoute>
            <Dashboard />
          </AdminRoute>
        }
      />
      <Route
        path="/Admin/Generate"
        element={
          <AdminRoute>
            <Generate />
          </AdminRoute>
        }
      />

      <>{/** Public Pages */}</>
      <Route path="/" element={<LoginPage action="login" />} />
      <Route path="/Login" element={<LoginPage action="login" />} />
      <Route
        path="/ForgotPassword"
        element={<LoginPage action="forgotPassword" />}
      />
      {/* <Route path="/ForgotPassword" element={<ForgotPassword />} /> */}
      {/* <Route path="/ResetPassword" element={<ResetPassword />} /> */}
      <Route path="/ResetPassword" element={<ResetPassword />} />
      <Route
        path="/Onboarding/LinkedinHashtagTrendDemo"
        element={<LinkedinHashtagTrendDemo />}
      />
      <Route
        path="/Onboarding/UserSourceSurvey"
        element={<UserSourceSurvey />}
      />
      {/* <Route path="/Onboarding/CreateAccount" element={<CreateAccount />} /> */}
      <Route
        path="/Onboarding/CreateAccount"
        element={<LoginPage action="signup" />}
      />
      <Route
        path="/Onboarding/EmailVerification"
        element={<EmailVerification />}
      />
      <Route path="/Onboarding/HowItWorks" element={<HowItWorks />} />
      <Route path="/Onboarding/Welcome" element={<Welcome />} />
      <Route
        path="/Onboarding/AISmartCommentingDemo"
        element={<AISmartCommentingDemo />}
      />
      <Route
        path="/Onboarding/NurtureLeadsDemo"
        element={<NurtureLeadsDemo />}
      />
      <Route path="/Onboarding/StaySubscribed" element={<StaySubscribed />} />
      <Route path="/ExitSurvey" element={<ExitSurvey />} />
      <Route
        path="/PipedriveRedirectMember"
        element={<PipedriveRedirectMember />}
      />
      <Route path="/PipedriveRedirect" element={<PipedriveRedirect />} />
      <Route path="/HubSpotRedirect" element={<HubSpotRedirect />} />
      <Route
        path="/HubSpotRedirectMember"
        element={<HubSpotRedirectMember />}
      />
      <Route
        path="/Onboarding/EngageAI/Welcome"
        element={<WelcomeEngageAI />}
      />
      <Route
        path="/Onboarding/EngageAI/WhyEngageAI"
        element={<WhyEngageAI />}
      />
      <Route path="/Onboarding/EngageAI/Ecosystem" element={<Ecosystem />} />
      <Route
        path="/Onboarding/EngageAI/EngageWithPeopleWhoMatter"
        element={<EngageWithPeopleWhoMatter />}
      />
      <Route
        path="/Onboarding/EngageAI/OptimizeYourNurturingEfforts"
        element={<OptimizeYourNurturingEfforts />}
      />
      <Route
        path="/Onboarding/EngageAI/NurtureToImproveConversion"
        element={<NurtureToImproveConversion />}
      />
      <Route path="/EngageAI/ExitSurvey" element={<ExitSurveyEngageAI />} />
      <Route path="/SalesforceRedirect" element={<SalesforceRedirect />} />
      <Route path="/LinkedinAuthRedirect" element={<LinkedinRedirect />} />
      <Route path="/Payment/Success" element={<PaymentSuccess />} />
      <Route path="/Payment/Fail" element={<PaymentFail />} />
      <Route path="/Upgrade" element={<Upgrade />} />
      <Route path="/Executive" element={<Executive />} />
      <Route path="/Premium" element={<Premium />} />
      <Route path="/Starter" element={<Starter />} />
      <Route path="/AWS" element={<CreateAccountAWS />} />

      <>{/** Logged In Users Only */}</>
      <Route
        path="/ChangePassword"
        element={
          <PrivateRoute>
            <LoginPage action="changePassword" />
          </PrivateRoute>
        }
      />

      <Route element={<AppLayout />}>
        <Route index element={<Navigate replace to="/Home" />} />
        <Route
          path="/Integrations"
          element={
            <PrivateRoute>
              <Integrations />
            </PrivateRoute>
          }
        />
        <Route
          path="/Account"
          element={
            <PrivateRoute>
              <AccountPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/Billing"
          element={
            <PrivateRoute>
              <BillingPageV2 />
            </PrivateRoute>
          }
        />
        <Route
          path="/Home"
          element={
            <PrivateRoute>
              <MainV2 />
            </PrivateRoute>
          }
        />
        <Route
          path="/Boost"
          element={
            <PrivateRoute>
              <Boost />
            </PrivateRoute>
          }
        />
        <Route
          path="/Notification/:notificationId"
          element={
            <PrivateRoute>
              <IndividualNotification />
            </PrivateRoute>
          }
        />
        <Route
          path="/Notification"
          element={
            <PrivateRoute>
              <NotificationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/LinkedinHashtagTrend"
          element={
            <PrivateRoute>
              <LinkedinHashtagTrend />
            </PrivateRoute>
          }
        />
        <Route
          path="/LinkedinTraining"
          element={
            <PrivateRoute>
              <LinkedinTraining />
            </PrivateRoute>
          }
        />
        <Route
          path="/NurtureProspects"
          element={
            <PrivateRoute>
              <NurtureProspects />
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to="posts" />} />
          <Route path="posts" element={<Posts />} />
          <Route path="prospects" element={<Prospects />} />
          <Route path="lists" element={<Lists />} />
          <Route path="lists/add" element={<AddNewList />} />
          <Route path="lists/edit/:id" element={<UpdateList />} />
        </Route>

        <Route
          path="/Onboarding/FPInstitute"
          element={
            <PrivateRoute>
              <FPInstitute />
            </PrivateRoute>
          }
        />
        <Route
          path="/Feedback"
          element={
            <PrivateRoute>
              <Feedback />
            </PrivateRoute>
          }
        />
        <Route
          path="/ChangeEmail"
          element={
            <PrivateRoute>
              <ChangeEmail />
            </PrivateRoute>
          }
        />
        <Route
          path="/CustomizeAI"
          element={
            <PrivateRoute>
              {/* <Settings /> */}
              <CustomizeAi />
            </PrivateRoute>
          }
        />

        <Route
          path="/Dashboard"
          element={
            <PodRoute>
              <Homepage />
            </PodRoute>
          }
        />
        <Route
          path="/Engagement"
          element={
            <PodRoute>
              <EngagementPage />
            </PodRoute>
          }
        />
        <Route
          path="/Accountability"
          element={
            <PodRoute>
              <AccountabilityPage />
            </PodRoute>
          }
        />
        <Route
          path="/LinkSubmit"
          element={
            <PodRoute>
              <LinkSubmitPageNew />
            </PodRoute>
          }
        />
        <Route
          path="/Institute"
          element={
            <PodRoute>
              <InstitutePage />
            </PodRoute>
          }
        />
        <Route
          path="/ReportMember"
          element={
            <PodRoute>
              <ReportMember />
            </PodRoute>
          }
        />
        <Route
          path="/PodCommunity"
          element={
            <PodRoute>
              <PodCommunity />
            </PodRoute>
          }
        />
        <Route
          path="/PodCommunity/Session/:session_id"
          element={
            <PodRoute>
              <PodSessionCommunity />
            </PodRoute>
          }
        />
        <Route
          path="/PodCommunity/Post/History"
          element={
            <PodRoute>
              <PostCommunityHistory />
            </PodRoute>
          }
        />
      </Route>
    </Routes>
  </GoogleOAuthProvider>
);

export default AllRoutes;
