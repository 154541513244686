import React, { useEffect, useState, useContext } from "react";
import GetSavedHashtagsService, {
  DeleteSavedHashtagsService,
  GetSuggestedHashtagsService,
  SaveHashtagsService,
} from "../../Services/GetSavedHashtags";
import Spinner from "../Spinner/Spinner";
import LineChart from "../Homepage/Chart";
import styles from "./Homepage.module.css";
import { Context } from "../../Store/Store";
import { TransformUserData } from "../../Services/UserDataService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Profitwell from "../Homepage/Profitwell";
//import SaveDeviceService from '../../Services/SaveDeviceService';
//import app from "../../firebase";
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { setUserDataLocalStorage } from "../../Scripts/getSetLocalStorage";
import ReactJoyRide from "react-joyride";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Box } from "@mui/material";
import { mixpanelEvent } from "../../Utils/mixpanelUtils";

const tourStyles = {
  // modal arrow and background color
  arrowColor: "#eee",
  backgroundColor: "#FFFFFF",
  //button color
  primaryColor: "#2F3EAC",
  //text color
  textColor: "#333",
  //width of modal
  width: 500,
  //zindex of modal
  zIndex: 1000,
};
const notification = {
  title: "Error!",
  message: "",
  type: "danger",
  insert: "top",
  container: "bottom-center",
  animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
  animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
  dismiss: {
    duration: 2000,
    pauseOnHover: true,
  },
};

/** 
const messaging = getMessaging(app);
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});
*/

const crossIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="white"
      d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
    ></path>
  </svg>
);

const tooltip = (
  <div className={`${styles["hover-container"]}`}>
    Leverage this plugin as you browse LinkedIn to <b>unlock more hashtag data</b>:
    <div>1. Follower, like and comment counts</div>
    <div>2. Reach potential</div>
  </div>
);

//const trialUsers = ["Chrome Extension", "Hootsuite", "Pipedrive"];

const LinkedinHashtagTrend = () => {
  const [loading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [hashtags, setHashtags] = useState([]);
  //const [trendingHashtags, setTrendingHashtags] = useState([]);
  const [keyword, setKeyword] = useState();
  const [xValues, setxValues] = useState();
  const [yValues, setyValues] = useState();
  const [state, dispatch] = useContext(Context);
  //const [number, setNumber] = useState(1);
  const [searchingHashtags, setSearchingHashtags] = useState(false);
  const [suggestedHashtags, setSuggestedHashtags] = useState();
  const [run, setRun] = useState(false); //run react-joy-ride tour
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Check localstorage to see if user data exists
    // Use case is when a user refreshes and we lose the context store
    // Rehydrate user data from localstorage
    // Have to use payload: {data: {}} data inside is to match the shape of the original object
    // without this the context store isn't readable
    if (state.user.data === undefined) {
      if (userData) {
        dispatch({
          type: "SET_USER",
          payload: {
            data: TransformUserData(userData),
          },
        });
      }
    }
    if (userData.hashtag) {
      const data = userData.hashtag;
      setData(data);
      setHashtags(Object.keys(data["saved"]));
      //setTrendingHashtags(Object.keys(data['top_weekly']));
      setIsLoading(false);
    }

    //tour
    const queryParams = new URLSearchParams(window.location.search);
    const tour = queryParams.get("tour");
    if (tour === "true") {
      setRun(true);
    }
  }, [dispatch, state.user, userData]);

  useEffect(() => {
    const u = JSON.parse(localStorage.getItem("user"));
    if (!u.hashtag) {
      setIsLoading(true);
      GetSavedHashtagsService().then((res) => {
        res
          .json()
          .then((json) => {
            setData(json.data);
            setHashtags(Object.keys(json.data["saved"]));
            //setTrendingHashtags(Object.keys(json.data['top_weekly']));
            u["hashtag"] = json.data;
            setUserData(u);
            setUserDataLocalStorage(u, token);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      });
    }
  }, [token]);

  const handleChange = (e) => {
    const id = e.currentTarget.id;
    setKeyword(id);
  };

  const handleSearch = () => {
    setSuggestedHashtags();
    setSearchingHashtags(true);
    setTimeout(() => {
      const email = state.user?.data?.email;
      const keyword = document.getElementById("searchHashtag").value.replace("#", "");
      if (!keyword) {
        setSuggestedHashtags();
        setSearchingHashtags(false);
        return;
      }

      mixpanelEvent("hashtag_searched", {
        hashtag_name: `#${keyword}`, 
        search_source: "Hashtag Page",
        screen_name: "Hashtag",
      });
      
      GetSuggestedHashtagsService(email, keyword)
        .then((res) => {
          res.json().then((json) => {
            if (res.status !== 200) {
              setSearchingHashtags(false);
              Store.addNotification({
                ...notification,
                title: "Error retrieving suggested hashtags... but we've got your back!",
                message: (
                  <div>
                    <div>
                      1. Install{" "}
                      <a
                        href="https://chrome.google.com/webstore/detail/hashtag-analytics-by-filt/ocgihmepobcgbfibaopggddieigbopja?hl=en-GB&authuser=0"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Hashtag Analytics Chrome extension
                      </a>
                    </div>
                    <div>2. Sign in to LinkedIn, if you've logged out</div>
                    <div>3. Refresh this page</div>
                  </div>
                ),
                dismiss: {
                  duration: 6000,
                  pauseOnHover: true,
                },
                width: 450,
              });
              return;
            }
            //console.log(json)
            setSearchingHashtags(false);
            setSuggestedHashtags(json);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500);
  };

  const selectHashtag = (e) => {
    const selectedHashtag = e.currentTarget.id;
    document.getElementById("searchHashtag").value = selectedHashtag;
  };

  const saveHashtag = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const keyword = document.getElementById("searchHashtag").value.replace("#", "");
    SaveHashtagsService(keyword)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          mixpanelEvent("hashtag_saved", {
            hashtag_name: `#${keyword}`, 
            tone_name: "Insightful", // ?
            customization_type: "Formal", 
            // prospect_name: "Company A", // ?
            // list_name: "Default List", // ?
            screen_name: "Hashtag Page", 
          });
          delete userData["hashtag"];
          setUserDataLocalStorage(userData, token);
          window.location.reload();
        } else if (res.status === 400) {
          Store.addNotification({
            ...notification,
            type: "warning",
            message: "Maximum number of hashtags saved.",
          });
        } else {
          Store.addNotification({
            ...notification,
            type: "error",
            message: "An error occured. Please try another hashtag.",
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const deleteHashtag = (e) => {
    let hashtag = e.currentTarget.name;
    var index = hashtags.indexOf(hashtag);
    if (index !== -1) {
      hashtags.splice(index, 1);
    }
    DeleteSavedHashtagsService(hashtag).then(() => {
      document.getElementById(hashtag + "Item").remove();
      delete userData["hashtag"]["saved"][hashtag];
      setUserData(userData);
      setUserDataLocalStorage(userData, token);
    });
  };

  /** 
    const changeGraph = (e) => {
        const id = e.currentTarget.id;
        if (id === 'right' && number !== 4){
            setNumber(number+1);
        }
        else if (id === 'left' && number !== 1) {
            setNumber(number-1)
        }
    }*/

  /** 
    const displayNotification = () => {
        Notification.requestPermission().then(()=> {
            return getToken(messaging, {vapidKey: 'BDy8ZpjLtHfj_63yJpUzDqPTew0XalbONBmFGGmZ0GFS17mQldOAFjJ6WBpDTCYImIQRZFpaD13I6dBBVzEdSMY'});
          })
          .then((token) => {
           console.log("firebase token: ", token);
           SaveDeviceService(token, 'general').then((res) => {
                if (res.status === 200) {
                    alert("You've allowed notifications.");
                }
           })
          })
          .catch((error)=>{
            console.log(error)
          })
    }*/

  useEffect(() => {
    if (keyword) {
      const items = Object.keys(data["saved"][keyword]).map((key) => {
        return [key, data["saved"][keyword][key]];
      });
      items.sort((first, second) => {
        return Date.parse(first[0]) - Date.parse(second[0]);
      });
      const sortedDates = items.slice(0, 20).map((e) => {
        const date = new Date(e[0]);
        return date.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
      });
      const fcount = items.map((e) => {
        return e[1];
      });
      setxValues(sortedDates);
      setyValues(fcount);
    }
  }, [keyword, data]);

  // Onboarding tour
  const TOUR_STEPS = [
    {
      target: ".saved_hashtags",
      content: (
        <div style={{ textAlign: "left" }}>
          <div
            className="row"
            style={{
              color: "#0A72C3",
              alignItems: "center",
              gap: "5px",
              borderBottom: "1px solid #E8EEF6",
              paddingBottom: "5px",
              marginBottom: "5px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "500" }}>Your Saved Hashtags</div>
          </div>
          <div style={{ fontSize: "14px" }}>
            Monitor and manage your saved hashtags here. Click on any hashtag to view its follower growth over time.
          </div>
        </div>
      ),
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".saved_hashtags",
      content: (
        <div style={{ textAlign: "left" }}>
          <div
            className="row"
            style={{
              color: "#2F3EAC",
              alignItems: "center",
              gap: "5px",
              borderBottom: "1px solid #E8EEF6",
              paddingBottom: "5px",
              marginBottom: "5px",
            }}
          >
            <img style={{ height: "18px", width: "24px" }} src="/images/hashtag_blue.svg" alt=""></img>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>Hashtag Suggestions</div>
          </div>
          <div style={{ fontSize: "14px", marginBottom: "10px" }}>
            Begin typing a keyword relevant to your post or niche. Get a list of related hashtags.
          </div>
        </div>
      ),
      placement: "bottom",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status } = data;
    //console.log(action, index, status);
    if (status === "finished" || action === "reset") {
      return;
    } else if (index === 1) {
      document.getElementById("searchHashtag").value = "corporate";
      setSuggestedHashtags({
        corporate: 10986,
        corporateevents: 25339,
        corporategifts: 1528,
        corporatelife: 3054,
      });
    }
  };

  return (
    <>
      <ReactJoyRide
        steps={TOUR_STEPS}
        run={run}
        continuous
        showProgress
        showSkipButton
        styles={{ options: tourStyles }}
        callback={handleJoyrideCallback}
      />
      <ReactNotifications />
      <Profitwell
        authToken="bd5c49b3d18639da365a7049e1a64a6c"
        email={state.user?.data?.email}
        shouldNotLoad={state.user?.data?.group === "Member"}
      />
      <div>
        <div
          className="row"
          style={{
            flexWrap: "nowrap",
            padding: "1.5rem 2.5rem",
            flexDirection: "column",
            background: "var(--color-grey-50)",
          }}
        >
          <Box className="headerText" mb='1.5rem'>
            <Box display="flex" alignItems="center" gap="8px">
              <h1>Hashtag</h1>
            </Box>
            <p>
            Seize hashtags with rapid follower growth for greater exposure and audience reach.            
            </p>
          </Box>
          <div style={{ flex: "1" }}>
            {loading ? <Spinner className="spinner-container" /> : ""}
            <div>
              <div className="row">
                <div style={{ marginLeft: "auto" }}></div>
              </div>
              <div
                className="saved_hashtags"
                style={{ background: "#E8EEF6", borderRadius: "8px", padding: "20px", margin: "10px 0 20px" }}
              >
                <div className="row">
                  <div style={{ width: "80%" }}>
                    <div style={{ fontWeight: "700", fontSize: "18px", marginBottom: "10px" }}>Your Saved Hashtags</div>
                    <div className={`${styles["hashtag-container"]}`}>
                      {hashtags.length === 0
                        ? ""
                        : hashtags.map((key) => {
                            let item = (
                              <div
                                className={
                                  keyword === key ? `${styles["hashtag-item-selected"]}` : `${styles["hashtag-item"]}`
                                }
                                id={key + "Item"}
                              >
                                <button
                                  className={
                                    keyword === key
                                      ? `${styles["hashtag-button-selected"]}`
                                      : `${styles["hashtag-button"]}`
                                  }
                                  id={key}
                                  onClick={handleChange}
                                >
                                  {"#" + key}
                                </button>
                                {keyword === key ? (
                                  <button
                                    className={`${styles["hashtag-button"]}`}
                                    id={key + "X"}
                                    name={key}
                                    onClick={deleteHashtag}
                                  >
                                    {crossIcon}
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                            return item;
                          })}
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto", display: "flex", flexDirection: "column" }}>
                    <div style={{ marginLeft: "auto", gap: "5px" }} className="row">
                      <div style={{ cursor: "pointer" }}>
                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltip}>
                          <img src="/images/tooltip.svg" alt=""></img>
                        </OverlayTrigger>
                      </div>
                      <div>Install Chrome Extension :</div>
                    </div>
                    <div
                      className="row"
                      style={{
                        alignItems: "center",
                        padding: "8px 12px",
                        background: "white",
                        borderRadius: "8px",
                        cursor: "pointer",
                        boxShadow: "rgb(0 0 0 / 10%) 0px 3px 4px",
                      }}
                      onClick={() => {
                        window.open(
                          "https://chrome.google.com/webstore/detail/hashtag-analytics-by-filt/ocgihmepobcgbfibaopggddieigbopja?hl=en-GB&authuser=0"
                        );
                      }}
                    >
                      <img src="images/lha_icon.svg" alt=""></img>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>Hashtag Analytics</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ border: "1px solid #CDD3E0", margin: "10px 0", background: "#CDD3E0" }}></div>
                <div style={{ fontWeight: "700", fontSize: "18px", marginBottom: "10px" }}>
                  Want to add another one?
                </div>
                <form style={{ marginBottom: "0" }} onSubmit={saveHashtag}>
                  <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ width: "85%" }}>
                      <div
                        className="row"
                        style={{ background: "white", padding: "12px 16px", borderRadius: "200px", gap: "8px" }}
                      >
                        <img src="/images/search.svg" alt=""></img>
                        <div style={{ width: "85%" }}>
                          <input
                            className={`${styles["text-input"]}`}
                            placeholder="e.g. marketing"
                            onChange={handleSearch}
                            id="searchHashtag"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <button className={`${styles["button"]}`}>
                        <div style={{ display: "flex", gap: "3px" }}>
                          <img style={{ height: "16px", width: "14px" }} src="images/add_white.svg" alt=""></img>
                          <div>Save Hashtag</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
                {suggestedHashtags ? (
                  <div className="row" style={{ justifyContent: "space-between", alignItems: "center" }}>
                    <div
                      style={{
                        width: "85%",
                        padding: "8px 16px",
                        borderRadius: "20px",
                        background: "white",
                        boxShadow: "rgb(34 49 79 / 25%) 7px 4px",
                      }}
                    >
                      {Object.keys(suggestedHashtags).map((key) => {
                        let item = (
                          <div
                            className="row"
                            style={{ color: "#0A72C3", fontStyle: "italic", cursor: "pointer", padding: "5px 0" }}
                            onClick={selectHashtag}
                            id={key}
                          >
                            <div>{"#" + key}</div>
                            {/*<div style={{marginLeft:"auto"}}>{suggestedHashtags[key].toLocaleString() + ' followers'}</div>*/}
                          </div>
                        );
                        return item;
                      })}
                    </div>
                  </div>
                ) : searchingHashtags ? (
                  <div
                    style={{
                      height: "142px",
                      width: "85%",
                      padding: "8px 16px",
                      borderRadius: "20px",
                      background: "white",
                      boxShadow: "rgb(34 49 79 / 25%) 7px 4px",
                    }}
                  >
                    <div className="row" style={{ gap: "5px", alignItems: "center" }}>
                      <img src="images/loading.svg" alt="" className={`${styles["spin"]}`}></img>
                      <div style={{ fontStyle: "italic", color: "#0A72C3" }}>
                        Getting your hashtag suggestions ready...
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/**<div className='row' style={{gap:"20px"}}>
                            <div style={{cursor:"pointer"}} onClick={changeGraph} id='left'><img src='/images/left.svg' alt=''></img></div>
                            <div>{number + '/4'}</div>
                            <div style={{cursor:"pointer"}} onClick={changeGraph} id='right'><img src='/images/right.svg' alt=''></img></div>
                        </div>
                        <div style={{fontSize:"16px", marginBottom:"10px", fontWeight:"700", gap:"7px", margin:"10px 0"}} className='row' >
                            <img src='/images/trending_up.svg' alt=''></img>
                            <div>{number === 1 ? 'Trending': number === 2 ? 'Top 10 Most Followed Hashtags' : number === 3 ? 'Top 10 Weekly % Change' : 'Top 10 Most Searched Hastags'}: Coming Soon!</div>
                        </div>*/}
              <div className={`${styles["hashtag-container"]}`}>
                {/**trendingHashtags.length === 0 ?  '' : (
                                trendingHashtags.map((key) => {
                                    let item = (
                                        <div className={keyword === key ? `${styles['hashtag-item-selected']}`: `${styles['hashtag-item']}`}>
                                            <button className={keyword === key ? `${styles['hashtag-button-selected']}`: `${styles['hashtag-button']}`} id={key} onClick={handleChange}>{'#' + key}</button >
                                        </div>);  
                                    return item
                                }) 
                            )*/}
              </div>
              <div style={{ color: "#0A72C3", fontSize: "24px", fontWeight: "700" }}>
                {keyword ? "#" + keyword : ""}
              </div>
              <div className={`${styles["chart-container"]}`}>
                {typeof keyword != "undefined" && keyword != null ? (
                  <LineChart xValues={xValues} yValues={yValues} keyword={keyword} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkedinHashtagTrend;
