import { Tooltip as MUITooltip } from "@mui/material";
export const Tooltip = (props) => {
  const { children, tooltipText, placement = "right", tooltipSx } = props;

  // ====================== RETURN
  return (
    <MUITooltip
      title={tooltipText}
      placement={placement}
      arrow
      slotProps={{
        arrow: {
          sx: {
            color: "#1F292E",
          },
        },
        tooltip: {
          sx: {
            background: "#1F292E",
            color: "#FFF",
            textAlign: "center",
            maxWidth: "9.5rem",
            borderRadius: "8px",
            fontSize: ".75rem",
            p: 1,
            ...tooltipSx,
          },
        },
      }}
    >
      {children}
    </MUITooltip>
  );
};
