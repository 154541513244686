import React, {useContext} from 'react';
import styles from './Sidebar.module.css';
import { Link } from 'react-router-dom';
import { Context } from '../../Store/Store';

const Sidebar = () => {
  const context = useContext(Context);
  const state = context[0];

  return (
    <div className={`${styles['main']}`}>
        <div>
          <Link to='/Home'>
          <img style={{width:'30px', marginRight:"10px"}} src='/images/engage_ai_logo_white.svg' alt='engageai'></img>
          <span style={{color:"white"}}>Engage AI</span>
          </Link>
        </div>

        <div style={{marginTop:"50px"}}>
        <div className={`engage ${styles['link-container']}`}>
            <Link to='/Home'>
              <div className={`${styles['df-row']}`} style={{alignItems:"center"}}>
                <div><img style={{height:"18px", width:"24px"}} src='/images/dashboard.svg' alt=''></img></div>
                <div className={`${styles['link-text2']}`}>Dashboard</div>
              </div>
            </Link>
          </div>
          <div style={{background:"#BBBBBB", height:"1px"}}></div>
          <div className={`engage ${styles['link-container']}`}>
            <Link to='/NurtureProspects/lists'>
              <div className={`${styles['df-row']}`} style={{alignItems:"center"}}>
                <div><img style={{height:"25px", width:"24px"}} src='/images/monitor_white.svg' alt=''></img></div>
                <div className={`${styles['link-text2']}`}>Monitor</div>
              </div>
            </Link>
          </div>
          <div className={`${styles['link-container']}`}>
            <Link to='/CustomizeAI'>
              <div className={`${styles['df-row']}`} style={{alignItems:"center"}}>
                <div><img style={{height:"18px", width:"24px"}} src='/images/settings.svg' alt=''></img></div>
                <div className={`${styles['link-text2']}`}>Customize AI</div>
              </div>
            </Link>
          </div>
          <div className={`hashtag ${styles['link-container']}`}>
            <Link to='/LinkedinHashtagTrend'>
              <div className={`${styles['df-row']}`}>
                <img style={{height:"18px", width:"24px"}} src='/images/hashtag.svg' alt=''></img>
                <div className={`${styles['link-text2']}`}>Hashtag</div>
              </div>
            </Link>
          </div>
          
          <div className={`integrations ${styles['link-container']}`} style={{display:"block", height:"max-content", cursor:"pointer"}}>
            <Link to='/Integrations'>
              <div className={`${styles['df-row']}`} style={{alignItems:"center"}}>
                <div><img style={{height:"18px", width:"24px"}} src='/images/puzzle.svg' alt=''></img></div>
                <div className={`${styles['link-text2']}`}>Integrations</div>
              </div>
            </Link>
          </div>
          <div style={{background:"#BBBBBB", height:"1px"}}></div>
          {state.user?.data?.group === 'Member' || state.user?.data?.group === 'Prominence Global' ?  
            <div>
              <div className={`${styles['link-container']}`}>
                <Link to='/Dashboard'>
                  <div className={`${styles['df-row']}`}>
                    <img style={{height:"18px", width:"24px"}} src='/images/activity.svg' alt=''></img>
                    <div className={`${styles['link-text2']}`}>Insights</div>
                  </div>
                </Link>
              </div>
              <div className={`${styles['link-container']}`}>
                <Link to='/PodCommunity'>
                  <div className={`${styles['df-row']}`}>
                    <img style={{height:"18px", width:"24px"}} src='/images/boost.svg' alt=''></img>
                    <div className={`${styles['link-text2']}`}>Share</div>
                  </div>
                </Link>
              </div>
              <div className={`${styles['link-container']}`}>
                <Link to='/Engagement'>
                  <div className={`${styles['df-row']}`}>
                    <img style={{height:"18px", width:"24px"}} src='/images/pencil.svg' alt=''></img>
                    <div className={`${styles['link-text2']}`}>Empower</div>
                  </div>
                </Link>
              </div>
              <div className={`${styles['link-container']}`}>
                <Link to='/Accountability'>
                  <div className={`${styles['df-row']}`}>
                    <img style={{height:"18px", width:"24px"}} src='/images/brackets-check.svg' alt=''></img>
                    <div className={`${styles['link-text2']}`}>Review</div>
                  </div>
                </Link>
              </div>
              <div style={{background:"#BBBBBB", height:"1px"}}></div>
            </div> : ''}
          {state.user?.data?.group !== 'Growth Plan' &&  state.user?.data?.group !== 'Member' ?  
            <div className={`${styles['rec18']} `} style={{background:"#8490E2", margin:"15px 0"}}>
              <div  className={`${styles['link-text4']}`}>
                <div style={{fontSize:"18px", textAlign:"center", marginBottom:"10px"}}>Upgrade Account</div>
                <div style={{fontSize:"12px", textAlign:"center", marginBottom:"10px"}}>More unlimited features with Pro</div>
                <div style={{textAlign:"center"}}>
                  <a href='/Upgrade' target='_blank' rel='noreferrer' style={{color:"white", background:"#5B6BDA", padding:"12px", boxShadow:"2px 2px 2px #686262c9"}}>Upgrade</a>
                </div>
              </div>
            </div> : ''}

            <div className={`${styles['link-container']}`} style={{display:"block", height:"max-content", cursor:"pointer"}}>
              <div className={`${styles['df-row']}`}>
                  <a href='https://help.engage-ai.co/en' target='_blank' rel='noreferrer' style={{color:"white", fontSize:"16px"}}>Help & Strategy Hub</a>
                </div>
            </div>

            <div className={`${styles['link-container']}`} style={{display:"block", height:"max-content", cursor:"pointer"}}>
              <div className={`${styles['df-row']}`}>
                  <a href={state.user?.data?.group === 'Growth Plan' ||  state.user?.data?.group === 'Member' ? 'https://tally.so/r/mDkRyN' : 'https://www.facebook.com/groups/engageai'} target='_blank' rel='noreferrer' style={{color:"white", fontSize:"16px"}}>Contact Us</a>
                </div>
            </div>

            <div className={`${styles['link-container']}`} style={{display:"block", height:"max-content", cursor:"pointer"}}>
              <Link to='/Feedback'>
                <div className={`${styles['link-text2']}`}>Feedback</div>
              </Link>
            </div>

            <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"5px", marginTop:"20px"}}>
              <a href='https://www.facebook.com/groups/engageai' target='_blank' rel='noopener noreferrer'><img style={{height:"20px"}} src='images/fb_icon.svg' alt=''></img></a>
              <a href='https://www.linkedin.com/company/engage-ai-official/posts/?feedView=all' target='_blank' rel='noopener noreferrer'><img style={{height:"31px"}} src='images/linkedin.svg' alt=''></img></a>
            </div>
          
        </div>
    </div>
  );
};
export default Sidebar;