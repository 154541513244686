import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select, MenuItem, TextField, Typography, Stack } from "@mui/material";
import { useModal } from "../ModalProvider";
import {
  ModalCancelActionButton,
  ModalPlainButton,
} from "../../components/ModalButton";

const schema = yup.object().shape({
  reason: yup.string().required("Reason is required"),
  note: yup.string().min(2).max(200, "note cannot exceed 200 words"),
});

const reasonPlaceholders = {
  "Switching to another business growth tool":
    "Can you please provide the name or link to the new tools you use and explain why they are better?",
  "Too many technical issues (bugs, loads forever, glitches)":
    "Please let us know which feature you find difficult to use and how we can improve it.",
  "Not enough features / functionality":
    "Which functionality would you like us to add?",
  "Too complex or confusing to use":
    "Can you specify which aspects you find too complex or confusing?",
  Pricing:
    "Can you please tell us why our pricing does not match the value we provide?",
  Other: "Share your feedback here...",
};

const UnsubscribeForm = () => {
  // ====================== HOOKS
  const { nextStep, closeModal, updateFormData } = useModal();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { reason: "", note: "" },
  });

  // ====================== VARIABLES
  const reason = watch("reason");
  const note = watch("note") || "";

  // ====================== HANDLER
  const onHandleSubmit = (value) => {
    updateFormData(value);
    return nextStep();
  };

  // ====================== RETURN
  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <Stack gap={2}>
        <Stack gap={1}>
          <Typography color="#415058" fontWeight={500} fontSize={".875rem"}>
            Tell us why you're unsubscribing from Engage AI
          </Typography>
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <Select
                sx={{
                  borderRadius: "8px",
                }}
                displayEmpty
                renderValue={(selected) => {
                  const renderText = () => {
                    if (selected.length === 0) {
                      return "Select a reason";
                    }
                    return selected;
                  };
                  return (
                    <Typography color="#415058">{renderText()}</Typography>
                  );
                }}
                {...field}
                fullWidth
              >
                {Object.keys(reasonPlaceholders).map((option) => (
                  <MenuItem key={option} value={option}>
                    <Typography color="#415058">{option}</Typography>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Stack>
        <Stack gap={1}>
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder={reasonPlaceholders[reason] || ""}
                multiline
                fullWidth
                rows={6}
                InputProps={{ sx: { borderRadius: "8px" } }}
                inputProps={{ maxLength: 200 }}
              />
            )}
          />
          <Typography
            variant="caption"
            color="#A0A4A6"
            fontWeight={600}
            fontSize={".8rem"}
          >
            {note.length}/200
          </Typography>
        </Stack>
        <Stack direction={"row"} gap={1} sx={{ display: "flex", mt: 2 }}>
          <ModalPlainButton onClick={closeModal} fullWidth>
            Keep my current plan
          </ModalPlainButton>
          <ModalCancelActionButton
            type="submit"
            fullWidth
            disabled={!isDirty || !isValid}
          >
            Cancel Subscription
          </ModalCancelActionButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default UnsubscribeForm;
