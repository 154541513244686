import { Box, Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GetPricingPlan } from "../../Services/BillingService";
import { PricingPlan } from "./components/PricingPlan";
import StripePortalSessionService from "../../Services/StripeService";
export const Plan = () => {
  // ====================== STATE
  const [tab, setTab] = useState("annual");
  const [pricingPlan, setPricingPlan] = useState([]);
  const [stripeSessionUrl, setStripeSessionUrl] = useState("");
  const [stripeSessionLoading, setStripeSessionLoading] = useState(false);
  const [pricingPlanLoading, setPricingPlanLoading] = useState(false);

  // ====================== EFFECT
  useEffect(() => {
    const fetchPlan = async () => {
      setPricingPlanLoading(true);
      try {
        const res = await GetPricingPlan();
        const data = await res.json();
        setPricingPlan([...data]);
      } catch (error) {
        console.error("Error fetching current plan:", error);
      } finally {
        setPricingPlanLoading(false);
      }
    };
    fetchPlan();
  }, [setPricingPlan]);

  useEffect(() => {
    const fetchStripePortalSession = async () => {
      setStripeSessionLoading(true);
      try {
        const result = await StripePortalSessionService();
        const stripePortalSession = await result.json();
        setStripeSessionUrl(stripePortalSession?.data?.url);
      } catch (error) {
        console.error("Error fetching current plan:", error);
      } finally {
        setStripeSessionLoading(false);
      }
    };
    fetchStripePortalSession();
  }, []);

  // ====================== HANDLER
  const onHandleChange = (_, value) => {
    setTab(value);
  };

  // ====================== RENDER
  const renderPlan = () => {
    return pricingPlan.map((plan) => {
      return (
        <PricingPlan
          key={`plan-${plan?.["Plan"]}`}
          plan={plan}
          currentTab={tab}
          stripePortalSessionUrl={stripeSessionUrl}
        />
      );
    });
  };

  const renderTab = () => {
    return (
      <Tabs
        value={tab}
        onChange={onHandleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        sx={{
          ".Mui-selected": {
            background: "#FFF",
            color: "#0A72C3 !important",
          },
          ".MuiTab-root": {
            transition: "all 0.2s ease-in-out",
            px: 3,
            mx: 0.5,
            borderRadius: "8px",
            fontWeight: 700,
            color: "#415058",
            "&:hover": {
              background: "#FFF",
              color: "#0A72C3",
            },
          },
        }}
      >
        <Tab
          sx={{
            py: "2px",
          }}
          label={
            <Stack
              direction={"row"}
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography fontWeight={"bold"} textTransform={"none"}>
                Annual
              </Typography>
              <Box
                textAlign={"center"}
                backgroundColor={
                  tab === "annual" ? "rgba(10, 114, 195, 0.10)" : "transaprent"
                }
                padding={"6px"}
                borderRadius={"4px"}
                fontSize={"16px"}
                color="#0A72C3"
              >
                -20%
              </Box>
            </Stack>
          }
          value="annual"
        />
        <Tab
          label={
            <Stack
              direction={"row"}
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography fontWeight={"bold"} textTransform={"none"}>
                Quarterly
              </Typography>
              <Box
                textAlign={"center"}
                backgroundColor={
                  tab === "quarterly"
                    ? "rgba(10, 114, 195, 0.10)"
                    : "transaprent"
                }
                padding={"6px"}
                borderRadius={"4px"}
                fontSize={"16px"}
                color="#0A72C3"
              >
                -10%
              </Box>
            </Stack>
          }
          value="quarterly"
        />
        <Tab label="Monthly" value="monthly" sx={{ textTransform: "none" }} />
      </Tabs>
    );
  };

  const renderLoading = () => {
    if (pricingPlanLoading || stripeSessionLoading) {
      return (
        <Stack pt={8}>
          <Skeleton variant="rounded" height={350} width={"100%"} />
        </Stack>
      );
    }
    return (
      <section id="pricing-plan">
        <Box
          display={"grid"}
          gridTemplateColumns={{
            xs: "repeat(1,1fr)",
            sm: "repeat(2,1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={3.5}
          pt={6}
          gridAutoRows={"1fr"}
        >
          {renderPlan()}
        </Box>
      </section>
    );
  };

  // ====================== RETURN
  return (
    <Stack gap={2} id>
      <Typography
        fontWeight={"bold"}
        fontSize={"1.15rem"}
        color="#1F292E"
        pb={2}
      >
        Pick a Plan
      </Typography>

      <Stack>
        <Stack
          justifyContent={"center"}
          alignSelf={"center"}
          backgroundColor="#F2F2F3"
          borderRadius={"10px"}
          py={".25rem"}
        >
          {renderTab()}
        </Stack>
        <Typography textAlign={"center"} pt={3}>
          🎁 14-day free trial + 30-day refund for your first payment.
        </Typography>
        {renderLoading()}
      </Stack>
    </Stack>
  );
};

export default Plan;
