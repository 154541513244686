import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ErrorIcon from "@mui/icons-material/Error";
import PeopleIcon from "@mui/icons-material/People";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "../../Button";
import { Context } from "../../../Store/Store";
import { GetPodHistory } from "../../../Services/PodComunnityService";
import toast from "react-hot-toast";
import { TableRowsLoader } from "../../TableRowLoader";
import { formatUtcToLocal } from "../utils";
import { size } from "lodash";
import { ViewEngagementModal } from "./ViewEngagementModal";
import { useNavigate } from "react-router-dom";

const PostTable = () => {
  // ====================== CONTEXT
  const [state] = useContext(Context);

  // ====================== HOOKS
  const navigate = useNavigate();

  // ====================== STATE
  const [podHistory, setPodHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModal, setViewModal] = useState({
    pod: {},
    isOpen: false,
  });

  // ====================== HANDLER
  const onHandleOpenModal = (pod) => {
    setViewModal({ pod, isOpen: true });
  };

  const onHandleCloseModal = () => {
    setViewModal((prev) => ({ ...prev, isOpen: false }));
  };
  // ====================== VARIABLES
  const userId = state.user?.data?.id;

  // ====================== EFFECT
  useEffect(() => {
    const fetchHistory = async () => {
      if (!userId) return;
      setLoading(true);
      try {
        const res = await GetPodHistory(userId);
        const data = await res.json();
        setPodHistory([...data]);
      } catch (error) {
        toast.error("Error fetching pod session. Please try again later");
      } finally {
        setLoading(false);
      }
    };
    fetchHistory();
  }, [userId]);

  // ====================== RENDER
  const renderTableContent = () => {
    if (loading) {
      return <TableRowsLoader rowsNum={5} />;
    }
    if (size(podHistory) === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="center" sx={{ py: 15 }}>
            <Typography>No data available</Typography>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {podHistory.map((row, index) => {
          const { date, time } = formatUtcToLocal(row?.date);
          return (
            <TableRow key={index}>
              <TableCell>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography
                    color="#1F292E"
                    fontSize={".875rem"}
                    fontWeight={600}
                  >
                    {date}
                  </Typography>
                  <CircleIcon sx={{ width: 6, height: 6 }} color="#415058" />
                  <Typography
                    fontSize={".875rem"}
                    color="#415058"
                    fontWeight={600}
                  >
                    {time}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell sx={{ paddingInline: 0 }}>
                <Button
                  size="small"
                  endIcon={<OpenInNewIcon />}
                  style={{
                    color: "#0A72C3",
                    fontWeight: 600,
                    fontSize: ".875rem",
                  }}
                  variant="text"
                  onClick={() => window.open(row.link, "_blank")}
                >
                  Open post
                </Button>
              </TableCell>
              <TableCell>
                {!row.status ? (
                  <Typography
                    color="error"
                    fontWeight={600}
                    fontSize={".875rem"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(`/PodCommunity/Session/${row.sessionId}`)
                    }
                  >
                    <ErrorIcon fontSize="small" sx={{ mr: 1 }} /> Incomplete
                  </Typography>
                ) : (
                  <Typography
                    color="success"
                    fontWeight={600}
                    fontSize={".875rem"}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <DoneAllIcon fontSize="small" sx={{ mr: 1 }} /> Completed
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<PeopleIcon sx={{ width: 20, height: 20 }} />}
                  style={{
                    borderRadius: "20px",
                    color: "#0A72C3",
                    fontWeight: 600,
                    border: "2px solid #0A72C3",
                  }}
                  onClick={() => onHandleOpenModal(row)}
                >
                  View engagements
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  // ====================== RETURN
  return (
    <>
      <ViewEngagementModal
        isOpen={viewModal.isOpen}
        closeModal={onHandleCloseModal}
        currentEngagement={viewModal.pod}
      />
      <TableContainer
        sx={{ border: "1px solid #D1D9E2", borderRadius: ".875rem" }}
      >
        <Table>
          <TableHead sx={{ background: "#F7F7F7" }}>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: 700, color: "#7C8B9D" }}>
                  Submission Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 700, color: "#7C8B9D" }}>
                  Post URL{" "}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 700, color: "#7C8B9D" }}>
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 700, color: "#7C8B9D" }}>
                  Engagements
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableContent()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PostTable;
