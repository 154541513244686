import { Stack, Typography } from "@mui/material";
import Button from "../../Button";

export const EmptyDataHolder = (props) => {
  const {
    icon,
    title,
    description,
    onClick,
    buttonText,
    titleProps,
    buttonProps,
    ...restProps
  } = props;

  // ====================== RETURN
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      gap={1}
      pt={4}
      pb={2}
      {...restProps}
    >
      {icon}
      <Typography
        color="#415058"
        fontSize={"1rem"}
        fontWeight={600}
        {...titleProps}
      >
        {title}
      </Typography>
      <Stack color="#707981" textAlign={"center"} pb={1}>
        {description}
      </Stack>
      <Button
        {...buttonProps}
        style={{
          backgroundColor: "#0A72C3",
          fontWeight: 600,
          paddingInline: "1.5rem",
          ...buttonProps?.style,
        }}
        onClick={onClick}
      >
        {buttonText || "Add a prospect"}
      </Button>
    </Stack>
  );
};
