import Chart from "react-apexcharts";
import { MetricsBox } from "../MetricsBox";
import { Box } from "@mui/material";
import { constructGraphData } from "../utils";

const options = {
  chart: {
    parentHeightOffset: 0,
    background: "transparent !important",
    type: "line",
    zoom: { enabled: false },
    toolbar: { show: false },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
    curve: "straight",
  },
  colors: ["#9900DB"],
  legend: {
    tooltipHoverFormatter: function (val, opts) {
      return (
        val +
        " - <strong>" +
        opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
        "</strong>"
      );
    },
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    tickAmount: 2,
    offsetY: 8,
    labels: {
      rotate: 0,
      style: {
        colors: "#707981",
        fontSize: "14px",
      },
      formatter: (value) => {
        const date = new Date(value);
        return `${date.toLocaleString("default", {
          month: "short",
        })} ${date.getDate()}`;
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      color: "#D7D7DD",
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#707981",
        fontSize: "14px",
      },
      tickAmount: 5,
      formatter: (value) => value.toFixed(0),
    },
  },

  grid: {
    strokeDashArray: 3,
    borderColor: "#D7D7DD",
  },
};

export const CommentChart = (props) => {
  const { data } = props;

  // ====================== VARIABLES
  const total = data?.total;
  const trend = data?.trend;
  const percentage = data?.percentageChange;

  // ====================== RENDER
  return (
    <MetricsBox
      title="Your comments"
      tooltipText={
        "Your comment activity with other LinkedIn posts, using EngageAI."
      }
      tooltipPlacement={"top"}
      headers={{
        count: total,
        percentage,
      }}
      height="100%"
      width="100%"
      paddingBottom={3}
      {...props}
    >
      <Box pt={1} pb={1} height={"100%"} paddingInline={1}>
        <Chart
          type="line"
          options={options}
          series={[
            {
              name: "Comments",
              data: constructGraphData(trend),
            },
          ]}
          width={"100%"}
          height={"100%"}
        />
      </Box>
    </MetricsBox>
  );
};
