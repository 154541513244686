import { Skeleton, TableCell, TableRow } from "@mui/material";

export const TableRowsLoader = ({ rowsNum }) => {
  const commonStyle = {
    height: 35,
  };
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        <Skeleton animation="wave" variant="text" {...commonStyle} />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" {...commonStyle} />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" {...commonStyle} />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" {...commonStyle} />
      </TableCell>
    </TableRow>
  ));
};
