import {
  Breadcrumbs,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { ContainerWrapper } from "../../common/ContainerWrapper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PostTable from "./PostTable";
export const PostCommunityHistory = () => {
  // ====================== HANDLER
  const onHandleBack = () => {
    window.location.href = "/PodCommunity";
  };

  // ====================== RETURN
  return (
    <ContainerWrapper gap={2}>
      <Stack direction={"row"} alignItems={"center"} gap={2}>
        <IconButton
          sx={{
            borderRadius: ".65rem",
            border: "1px solid #E6E9EC",
            background: "#F7F7F7",
          }}
          size="small"
          onClick={onHandleBack}
        >
          <ChevronLeftIcon sx={{ color: "#415058" }} />
        </IconButton>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="#415058"
            fontWeight={500}
            href="/PodCommunity"
          >
            Community
          </Link>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <HistoryIcon fontSize="small" sx={{ color: "#415058" }} />
            <Typography color="#415058" fontWeight={700}>
              Posts History
            </Typography>
          </Stack>
        </Breadcrumbs>
      </Stack>
      <Typography color="#415058" fontWeight={500} pb={1}>
        Below are the results of the community sessions you participated in.
      </Typography>
      <PostTable />
    </ContainerWrapper>
  );
};
