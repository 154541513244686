// ----------------------------------------------------------------------------
// IMPORTS
// ----------------------------------------------------------------------------
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, useLocation } from "react-router-dom";
import AllRoutes from "./Components/Routes";
import Store from "./Store/Store";
import { Toaster } from "react-hot-toast";
import Gleap from "gleap";
import { useEffect } from "react";
import { mixpanelEvent } from "./Utils/mixpanelUtils";
import { GlobalProvider } from "./Context/GlobalContext";
// Please make sure to call this method only once!
Gleap.setUseCookies(true);
Gleap.initialize("vI5VUG0MUvgFmdmMRQUn9mFKCShwPA7w");

// ----------------------------------------------------------------------------

const App = () => {
  const location = useLocation();

  useEffect(() => {
    mixpanelEvent("screen_loaded", {
      load_time: performance.now(),
      url: location.pathname,
      page_title: document.title,
      screen_name: location.pathname.split("/").pop() || "home",
    });
  }, [location]);

  return (
    <GlobalProvider>
      <AllRoutes />
    </GlobalProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Store>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <Toaster
        position="bottom-right"
        gutter={12}
        containerStyle={{ margin: "8px" }}
        toastOptions={{
          success: {
            duration: 5000,
            style: {
              backgroundColor: "#0A72C3",
              color: "#fff",
            },
            iconTheme: {
              primary: "#fff",
              secondary: "#0A72C3",
            },
          },
          error: {
            duration: 5000,
            style: {
              backgroundColor: "#d32f2f",
              color: "#fff",
            },
            iconTheme: {
              secondary: "#d32f2f",
              primary: "#FFFAFA",
            },
          },
          style: {
            fontSize: "14px",
            padding: "12px 16px",
            borderRadius: "10px",
            backgroundColor: "#0A72C3",
            color: "#fff",
            maxWidth: "400px",
          },
        }}
      />
    </React.StrictMode>
  </Store>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
