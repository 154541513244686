import moment from "moment";

export const formatPodDateTime = (dateString) => {
  const currentDate = moment(dateString).local();
  const date = currentDate.format("DD MMM YYYY");
  const time = currentDate.format("HH:mm");

  return { date, time, currentDateString: currentDate };
};

export const formatUtcToLocal = (utcDateString) => {
  const localDateTime = moment.utc(utcDateString).local();
  const date = localDateTime.format("DD MMM YYYY");
  const time = localDateTime.format("HH:mm");

  return { date, time, localDateTimeString: localDateTime };
};

export const getCountdown = (targetTime) => {
  const now = moment();
  const target = moment(targetTime);
  const diff = moment.duration(target.diff(now));

  if (diff.asSeconds() <= 0) {
    return { timeLeft: "LIVE", isLive: true };
  }

  const days = Math.floor(diff.asDays());
  const hours = diff.hours();
  const minutes = diff.minutes();

  const timeLeft = [
    days > 0 ? `${days}d` : "",
    hours > 0 ? `${hours}h` : "",
    minutes > 0 ? `${minutes}m` : "",
  ]
    .filter(Boolean)
    .join(" ");

  return { timeLeft, isLive: false };
};
