import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
export const NextStepList = (props) => {
  const { data } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  // ================== HANDLER
  const onHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onHandleItemClick = (target) => {
    window.open(target, "_blank");
    onHandleClose();
  };

  const onHandleClose = (target) => {
    setAnchorEl(null);
  };

  const commonTextStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
    <Stack gap={1.5} width={"100%"}>
      {data?.interactionType && (
        <Box
          backgroundColor="#FFF0E2"
          borderRadius={"0px 50px 50px 0px"}
          paddingBlock={"4px"}
          paddingRight={"12px"}
          paddingLeft={"10px"}
          width={"fit-content"}
        >
          <Typography color="#BD6110" fontSize={".75rem"}>
            {data?.interactionType}
          </Typography>
        </Box>
      )}
      <Stack direction={"row"} width={"100%"} alignItems={"center"} gap={3}>
        <Stack direction={"row"} width={"90%"} gap={2} alignItems={"center"}>
          <Avatar src={data?.profilePhotoURL} alt={data?.name} />
          <Stack width={"100%"} gap={0.3} overflow={"hidden"}>
            <Typography
              noWrap
              color="#415058"
              fontWeight={600}
              sx={{
                ...commonTextStyle,
              }}
            >
              {data?.name}
            </Typography>
            <Typography
              color="#707981"
              fontSize={".875rem"}
              fontStyle={"italic"}
              sx={{ ...commonTextStyle }}
            >
              {data?.title}
            </Typography>
            <Divider sx={{ backgroundColor: "#EDEDEE" }} />
            <Box>
              <Typography
                color="#707981"
                fontSize={".875rem"}
                fontStyle={"italic"}
                sx={{ ...commonTextStyle }}
              >
                {data.list}
              </Typography>
              <Typography
                color="#0A72C3"
                fontSize={".875rem"}
                fontStyle={"italic"}
              >
                Touchpoints: {data?.touchpoints}
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <IconButton onClick={onHandleClick} width={"10%"}>
          <MoreVertIcon sx={{ color: "#0A72C3" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={onHandleClose}
          slotProps={{
            paper: {
              sx: {
                minWidth: "13rem",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
              },
            },
          }}
        >
          <MenuItem
            onClick={() => onHandleItemClick(data?.latestPostUrl)}
            sx={{
              color: "#0A72C3",
            }}
          >
            Go to latest post
          </MenuItem>
          <MenuItem
            sx={{
              color: "#0A72C3",
            }}
            onClick={() => onHandleItemClick(data?.makeConnectionLink)}
          >
            Make a connection
          </MenuItem>
          <MenuItem
            sx={{
              color: "#0A72C3",
            }}
            onClick={() => onHandleItemClick(data?.conversationLink)}
          >
            Start a conversation
          </MenuItem>
        </Menu>
      </Stack>
    </Stack>
  );
};
