import { Stack, Typography } from "@mui/material";

export const DealDescription = (props) => {
  const { title, backgroundColor, textColor, Icon, description } = props;

  const renderDescription = () => {
    return description.map((item, index) => {
      return (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          // Can use index here because the array is static
          key={index}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            backgroundColor={backgroundColor}
            borderRadius={"8px"}
            p="4px"
          >
            <Icon
              sx={{
                color: "#FFF",
                width: "15px",
                height: "15px",
              }}
            />
          </Stack>
          <Typography color={textColor} fontWeight={500}>
            {item}
          </Typography>
        </Stack>
      );
    });
  };
  return (
    <>
      <Typography color={textColor} fontWeight={600}>
        {title}
      </Typography>
      <Stack gap={2}>{renderDescription()}</Stack>
    </>
  );
};
