import { useNurtureContext } from "../../../../Context/NurtureContext";
import { UpdatePostToCompletedService } from "../../../../Services/GetProspectsPosts";
import moment from "moment/moment";

import { Avatar, Box, Paper, Tooltip, Typography } from "@mui/material";
import { PiGlobeHemisphereWestFill } from "react-icons/pi";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { BiRepost } from "react-icons/bi";

import StyledButton from "../../ReusableComponents/Button";
import StyledCheckbox from "../../ReusableComponents/StyledCheckbox";

import { useState } from "react";
import { mixpanelEvent } from "../../../../Utils/mixpanelUtils";

const styles = {
  Paper: {
    display: " flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #E0E2E4",
    borderRadius: "10px",
    paddingBlock: "16px",
    minHeight: "340px",
    position: "relative",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.04)",
  },
  Box: {
    paddingInline: "20px",
    display: "flex",
    alignItems: "flex-end",
  },
  Date: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "9px",
    color: "#666666",
    whiteSpace: "nowrap",
  },
  Paragraph: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#415058",
    display: "-webkit-box",
    "-webkit-line-clamp": "6",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  PostFooter: {
    marginTop: "auto",
    gap: "1rem",
    padding: "20px 16px 0 16px",
  },
  Btn: {
    fontSize: "14px",
    padding: "8px 32px",
    height: "36px",
    borderRadius: "12px",
    "&:disabled": {
      color: "#000",
    },
  },
  engageBtn: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    background: "#F3F3F3",
    color: "#404040",

    "&:hover": {
      background: "#eee",
    },

    "& span": {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "600",
      color: "#404040",
      lineHeight: "19px",
    },

    "& svg": {
      width: "22px",
      height: "18px",
    },
  },
  ProspectName: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "10px",
    color: "#1F292E",
  },
  reshared: {
    border: "1px solid #415058",
    borderRadius: "200rem",
    padding: "6px 12px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "14px",
    color: "#415058",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  newBadge: {
    background: "rgb(10, 114, 195, 0.1)",
    color: "#0A72C3",
    padding: "4px",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "1",
    marginLeft: "2px"
  }
};

function Post({ postData }) {
  const { setRefreshPostsKey } = useNurtureContext();

  const {
    "is complete": isComplete = false,
    "post summary": postSummary = "",
    "post date": postDate = "",
    "post link": postLink = "",
    first_name: firstName = "",
    last_name: lastName = "",
    id: postId,
    is_repost,
    is_reshare,
    profile_photo_url
  } = postData ?? {};

  const [completed, setCompleted] = useState(isComplete);
  const prospectName = `${firstName} ${lastName}`.trim();

  const newPosts = JSON.parse(localStorage.getItem("newPosts")) || [];
  const isNew = newPosts.some((post) => post.id === postLink);

  function daysPassed(dateString) {
    const date = moment(dateString);
    const now = moment();
    const diff = now.diff(date, "days");
    if (diff === 0) return "Today";
    if (diff >= 7) return `${Math.floor(diff / 7)}w`;

    return `${diff}d`;
  }

  const handleCompletePost = async () => {
    setCompleted(true);
    mixpanelEvent("post_marked", {
      name: prospectName,
      post_content: postSummary,
      screen_name: 'posting activity by monitored prospects', 
    });
    await UpdatePostToCompletedService(postId);
    setRefreshPostsKey((oldKey) => oldKey + 1);
    // Remove the post from newPosts if it is marked as read
    if (isNew) {
      const updatedPosts = newPosts.filter((post) => post.id !== postLink);
      localStorage.setItem("newPosts", JSON.stringify(updatedPosts));
    }
  };

  return (
    <Paper sx={styles.Paper}>
      <Box sx={styles.Box} justifyContent="space-between" mb="24px">
        <Box display="flex" gap="8px">
        <Avatar sx={{ height: "32px", width: "32px" }} alt="user" src={profile_photo_url}/>
          <Box display="flex" flexDirection="column" justifyContent="center" gap="8px">
            <Typography sx={styles.ProspectName}>{prospectName}</Typography>
            <Box display="flex" alignItems="center" gap="4px">
              <span style={styles.Date}>{daysPassed(postDate)}</span>
              <span style={{ color: "#666", lineHeight: "9px" }}>&bull;</span>
              <PiGlobeHemisphereWestFill style={{ color: "#666", height: "12px", width: "12px" }} />
              {isNew && (<span style={styles.newBadge}>New</span>)}
            </Box>
          </Box>
        </Box>
        <Tooltip title={!completed ? "Mark as read" : ""} placement="bottom-end">
          <StyledCheckbox checked={completed} disabled={completed} onClick={handleCompletePost} />
        </Tooltip>
      </Box>
      {(is_repost || is_reshare) && (
        <Box sx={styles.Box}>
          <div style={styles.reshared}>
            <BiRepost style={{ height: "17px", width: "17px" }} />
            <span>{`${is_repost ? "Reposted" : "Reshared"} a post`}</span>
          </div>
        </Box>
      )}
      <Box sx={styles.Box}>
        <Typography sx={styles.Paragraph} variant="h6">
          {postSummary}
        </Typography>
      </Box>
      <Box sx={{ ...styles.Box, ...styles.PostFooter }} display="flex">
        <a
          onClick={handleCompletePost}
          style={{ display: "inline-block", color: "white", textDecoration: "none", flex: 1 }}
          href={postLink}
          target="_blank"
          rel="noreferrer"
        >
          <StyledButton
            sx={styles.engageBtn}
            onClick={() => {
              mixpanelEvent("post_engaged", {
                post_id: postId,
                post_url: postLink,
                screen_name: 'posting activity by monitored prospects', 
              });
            }}
          >
            <BiMessageRoundedDetail />
            <span>Engage</span>
          </StyledButton>
        </a>
      </Box>
    </Paper>
  );
}

export default Post;
