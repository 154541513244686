import { Box, Tooltip, Typography } from "@mui/material";
import { PrimaryButton } from "./StyledButton";
import { FaPlus } from "react-icons/fa6";

const styles = {
  btn: {
    padding: "8px 24px",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    borderRadius: "12px",
    fontSize: "13px",
    alignSelf: "end",
    "& span": {
      textTransform: "Capitalize",
    },
  },
  filterText: {
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#0A72C3",
  },
  Filters: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "& > *": {
      backgroundColor: "#F0F1F3",
      borderRadius: "100px",
      padding: "4px 14px !important",
      color: "#7C8B9D99",
    },
  },
};

function TableFilter({ children, premiumUser, ressource, onNewRessource, disableAdd, style = {} }) {
  const tooltipText = {
    List: "Upgrade your account to create multiple lists for different groups of prospects",
    Prospect:
      "Be the first to engage and get their attention. Upgrade to the Pro Plan now and save more than 10 prospects to monitor.",
  };

  return (
    <Box sx={{...style}} display="flex" alignItems="center">
      <Box display="flex" alignItems="center" gap="1rem" sx={{ mr: "auto" }}>
        <Box display="flex" alignItems="center" gap=".5rem">
          <img style={{ width: "15px", height: "15px" }} src="/images/filterIcon.svg" alt="filter icon" />
          <Typography sx={styles.filterText} variant="h6">
            Filter By:
          </Typography>
        </Box>
        <Box sx={styles.Filters}>{children}</Box>
      </Box>

      {ressource ? (
        premiumUser || !disableAdd ? (
          <PrimaryButton disabled={disableAdd} sx={styles.btn} onClick={onNewRessource}>
            <FaPlus style={{ height: "14px", width: "14px" }} />
            <span>{`New ${ressource}`}</span>
            {!premiumUser && <img style={{ width: "13px", height: "13px" }} src="/images/crown.svg" alt="crown icon" />}
          </PrimaryButton>
        ) : (
          <Tooltip title={tooltipText[ressource]} placement="bottom-end">
            <span>
              <PrimaryButton disabled={disableAdd} sx={styles.btn} onClick={onNewRessource}>
                <FaPlus style={{ height: "14px", width: "14px" }} />
                <span>{`New ${ressource}`}</span>
                {!premiumUser && (
                  <img style={{ width: "13px", height: "13px" }} src="/images/crown.svg" alt="crown icon" />
                )}
              </PrimaryButton>
            </span>
          </Tooltip>
        )
      ) : (
        ""
      )}
    </Box>
  );
}

export default TableFilter;
