import domain from "./DomainService";

/**
 * Get the user's accountability data to display on Accountability Page.
 * This is the list of people who have been assigned to comment on this uers's link.
 *
 * Uses JWT token to identify user
 * @return {Promise}
 */
export default function AdminDashboardService() {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
  };
  return fetch(domain() + "app-metrics", options);
}

export const GetDashboardAnalytics = (userId) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ userId }),
  };
  return fetch(
    "https://tpcx54d5y7yzgsneyjcvryio6u0vebrc.lambda-url.ap-southeast-2.on.aws/",
    options
  );
};

export const GetSuggestedNextStep = (userId) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ userId }),
  };
  return fetch(
    "https://36zouad3djkvurwukymtmwlkdq0bhhyd.lambda-url.ap-southeast-2.on.aws/",
    options
  );
};
