import { Box, Stack, Typography } from "@mui/material";
import PlanCard from "./PlanCard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GroupsIcon from "@mui/icons-material/Groups";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { cancellationReasons, formatDate, planMap } from "../constant";
import { useModal } from "../containers/ModalProvider";
import StripePortalSessionService from "../../../Services/StripeService";
import { useGlobalContext } from "../../../Context/GlobalContext";

export const CurrentPlanCard = () => {
  // ====================== STATE
  const [eaiButtonLoading, setEaiButtonloading] = useState(false);
  const [podButtonLoading, setPodButtonLoading] = useState(false);

  // ====================== HOOKS
  const { openModal } = useModal();
  const { plan: userPlan } = useGlobalContext();

  // ====================== VARIABLES
  const EAIPlan = userPlan?.eai;
  const PodPlan = userPlan?.pod;

  // ======================= EAI Plan
  const EAICurrentPlan = EAIPlan?.plan;
  const isEAISubscribed = EAIPlan?.active;
  const isEAIGiven = EAIPlan?.freeSubscription;
  const EAICurrentPlanName = planMap[EAICurrentPlan || "Trial"];
  const currentEAIPlanEndDate = EAIPlan?.periodEnds
    ? formatDate(EAIPlan.periodEnds)
    : null;
  const isTrial = EAICurrentPlan === "Trial";
  const isEAIPaymentFailed = cancellationReasons.includes(
    EAIPlan?.cancellation_reason
  );
  const isEAICancellationRequested =
    isEAISubscribed &&
    EAIPlan?.cancellation_reason === "cancellation_requested";

  const isEAICancelManually = EAIPlan?.cancellation_reason === "Cancelled";

  const EAIHasStripeId = EAIPlan?.stripeCustomerId;

  // ======================= POD Plan
  const isPodSubscribed = PodPlan?.active;
  const isGiven = PodPlan?.freeSubscription;
  const currentPodPlanEndDate = PodPlan?.periodEnds
    ? formatDate(PodPlan?.periodEnds)
    : null;
  const podCancellationReason = PodPlan?.cancellation_reason;
  const isPodCancellationRequested =
    isPodSubscribed && podCancellationReason === "cancellation_requested";
  const isPodPaymentFailed = cancellationReasons.includes(
    PodPlan?.cancellation_reason
  );
  const isPodCancelManually = podCancellationReason === "Cancelled";
  const PodHasStripeId = PodPlan?.stripeCustomerId;

  // ====================== HANDLER
  const onHandleEAIPlanClick = async () => {
    // If user doesn't have a stripe id, scroll to pricing plan section
    if (!EAIHasStripeId) {
      const element = document.getElementById("pricing-plan");
      if (element) {
        return element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
    // We don't want to show modal for trial and engage ai because modal is for cancel subscription
    // trial and free plan doesn't require subscription cancellation
    // we also don't want to show modal if payment failed
    // if user has already requested for cancellation, we want to show resubscribe button with redirecting to portal
    if (
      EAICurrentPlan !== "Trial" &&
      EAICurrentPlan !== "Engage AI" &&
      !isEAIPaymentFailed &&
      !isEAICancellationRequested &&
      !isEAICancelManually
    ) {
      return openModal();
    }
    setEaiButtonloading(true);
    try {
      const result = await StripePortalSessionService();
      const stripePortalSession = await result.json();
      if (stripePortalSession?.data?.url) {
        window.open(stripePortalSession.data.url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching current plan:", error);
    } finally {
      setEaiButtonloading(false);
    }
  };

  const onHandlePodPlanClick = async () => {
    // If user doesn't have a stripe id, redirect to pod pricing plan section
    if (!PodHasStripeId) {
      return window.open(
        "https://engage-ai.co/linkedin-engagement-community/",
        "_blank"
      );
    }
    setPodButtonLoading(true);
    try {
      const result = await StripePortalSessionService();
      const stripePortalSession = await result.json();
      if (stripePortalSession?.data?.url) {
        window.open(stripePortalSession.data.url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching current plan:", error);
    } finally {
      setPodButtonLoading(false);
    }
  };

  // ====================== RENDER
  const renderText = () => {
    if (isGiven) {
      return "Active";
    }

    if (isPodCancelManually) {
      return "Cancelled";
    }

    if (isPodCancellationRequested) {
      return `Cancels on ${currentPodPlanEndDate}`;
    }
    return `Renews on ${currentPodPlanEndDate}`;
  };

  const renderEAISubtitle = () => {
    if (isEAICancellationRequested) {
      return `Cancels on ${currentEAIPlanEndDate}`;
    }
    if (isEAICancelManually) {
      return "Cancelled";
    }
    return `Renews on ${currentEAIPlanEndDate}`;
  };

  // ====================== BUTTON EVENTS
  const commonPodPlanButtonEvent = {
    loading: podButtonLoading,
    disabled: podButtonLoading,
  };

  const commonEAIPlanButtonEvent = {
    loading: eaiButtonLoading,
    disabled: eaiButtonLoading,
  };

  const renderEAIButton = (color) => {
    if (isEAIGiven) {
      return {
        text: "Complementary",
      };
    }

    return {
      buttonText:
        isEAICancellationRequested || isEAICancelManually
          ? "Resubscribe"
          : "Cancel subscription",
      buttonProps:
        isEAICancellationRequested || isEAICancelManually
          ? {
              variant: "outlined",
              startIcon: <AddIcon sx={{ color }} />,
              style: {
                borderRadius: "10px",
                borderColor: color,
              },
              ...commonEAIPlanButtonEvent,
            }
          : {
              variant: "text-animated",
              ...commonEAIPlanButtonEvent,
            },
    };
  };

  // ====================== PLAN MAP
  const PodActionMap = {
    Inactive: {
      title: "Engagement community",
      subTitle: "Inactive membership",
      cardStyle: {
        background:
          "linear-gradient(to top left, rgba(240, 240, 240, 0.6), rgba(255, 255, 255, 1))",
      },
      buttonText: "Subscribe",
      buttonProps: {
        variant: "outlined",
        startIcon: <AddIcon />,
        style: {
          borderRadius: "10px",
        },
        ...commonPodPlanButtonEvent,
      },
    },
    Active: {
      title: "Engagement community",
      subTitle: renderText(),
      cardStyle: {
        background: "linear-gradient(to top left, #eef5ff, #ffffff)",
      },
      color: "#0A72C3",
      ...(!isGiven
        ? {
            buttonText:
              isPodCancellationRequested || isPodCancelManually
                ? "Resubscribe"
                : "Unsubscribe",
            buttonProps: {
              ...(isPodCancellationRequested || isPodCancelManually
                ? {
                    variant: "outlined",
                    startIcon: <AddIcon />,
                    style: {
                      borderRadius: "10px",
                    },
                  }
                : {
                    variant: "text-animated",
                  }),
              ...commonPodPlanButtonEvent,
            },
          }
        : {
            text: "Complementary",
          }),
    },
    Failed: {
      title: "Engagement community",
      subTitle: "Payment failed",
      cardStyle: {
        background:
          "linear-gradient(to top left, rgba(255, 0, 0, 0.1), rgba(255, 0, 0, 0))",
      },
      color: "#D61F1F",
      buttonText: "Renew subscription",
      buttonProps: {
        variant: "text-animated",
        ...commonPodPlanButtonEvent,
      },
    },
  };

  const EAIActionMap = {
    Trial: {
      title: EAICurrentPlanName,
      subTitle: `Trial expires on ${currentEAIPlanEndDate}`,
      buttonText: "Upgrade",
      buttonProps: {
        variant: "outlined",
        startIcon: <AddIcon />,
        style: {
          borderRadius: "10px",
        },
        ...commonEAIPlanButtonEvent,
      },
      cardStyle: {
        background:
          "linear-gradient(to top left, rgba(240, 240, 240, 0.6), rgba(255, 255, 255, 1))",
      },
    },
    "Engage AI": {
      title: EAICurrentPlanName,
      subTitle: "Limited features",
      buttonText: "Upgrade",
      buttonProps: {
        variant: "outlined",
        startIcon: <AddIcon />,
        style: {
          borderRadius: "10px",
        },
        ...commonEAIPlanButtonEvent,
      },
      cardStyle: {
        background:
          "linear-gradient(to top left, rgba(240, 240, 240, 0.6), rgba(255, 255, 255, 1))",
      },
    },
    Starter: {
      title: EAICurrentPlanName,
      subTitle: renderEAISubtitle(),
      cardStyle: {
        background: "linear-gradient(to top left, #eef5ff, #ffffff)",
      },
      color: "#0A72C3",
      ...renderEAIButton("#0A72C3"),
    },
    Growth: {
      title: EAICurrentPlanName,
      subTitle: renderEAISubtitle(),
      cardStyle: {
        background: "linear-gradient(to top left, #eef5ff, #ffffff)",
      },
      color: "#0A72C3",
      ...renderEAIButton("#0A72C3"),
    },
    Premium: {
      title: EAICurrentPlanName,
      subTitle: renderEAISubtitle(),
      cardStyle: {
        background: "linear-gradient(to top left, #fdf5e6, #ffffff)",
      },
      color: "#D79F3F",
      ...renderEAIButton("#D79F3F"),
    },
    Failed: {
      title: EAICurrentPlanName,
      subTitle: "Payment failed",
      cardStyle: {
        background:
          "linear-gradient(to top left, rgba(255, 0, 0, 0.1), rgba(255, 0, 0, 0))",
      },
      color: "#D61F1F",
      buttonText: "Renew subscription",
      buttonProps: {
        variant: "text-animated",
        ...commonEAIPlanButtonEvent,
      },
    },
  };

  // ====================== VARIABLES
  const EAIPlanCard =
    EAIActionMap[isEAIPaymentFailed ? "Failed" : EAICurrentPlan];
  const isPodActive = isPodSubscribed ? "Active" : "Inactive";
  const PodPlanCard = PodActionMap[isPodPaymentFailed ? "Failed" : isPodActive];

  // ====================== RETURN
  return (
    <Box
      gridColumn={{ xs: "span 12", lg: "span 8" }}
      height="100%"
      width={"100%"}
    >
      <Stack
        border="1px solid #E0E2E4"
        borderRadius={"12px"}
        py={3}
        px={3}
        backgroundColor="#FFF"
        height={"100%"}
      >
        <Box pb={2}>
          <Typography color="#1F292E" fontSize={"1.15rem"} fontWeight={500}>
            Current Plan
          </Typography>
        </Box>
        <Stack direction={"row"} gap={3} width={"100%"} height={"100%"}>
          <PlanCard
            title={`${EAIPlanCard?.title} Plan`}
            plan={EAIPlan?.plan}
            isTrial={isTrial}
            loading={!EAIPlan}
            subTitle={EAIPlanCard?.subTitle}
            style={{
              ...EAIPlanCard?.cardStyle,
              border: "0.5px solid rgba(0, 0, 0, 0.10)",
            }}
            icon={
              <CreditCardIcon
                style={{
                  width: "1.875rem",
                  height: "1.875rem",
                  color: EAIPlanCard?.color,
                }}
              />
            }
            color={EAIPlanCard?.color}
            text={EAIPlanCard?.text}
            buttonText={EAIPlanCard?.buttonText}
            buttonProps={EAIPlanCard?.buttonProps}
            onClick={onHandleEAIPlanClick}
          />
          <PlanCard
            title="Engagement Community"
            subTitle={PodPlanCard?.subTitle}
            loading={!PodPlan}
            style={{
              ...PodPlanCard?.cardStyle,
              border: "0.5px solid rgba(0, 0, 0, 0.10)",
            }}
            icon={
              <GroupsIcon
                style={{
                  width: "1.875rem",
                  height: "1.875rem",
                  color: PodPlanCard?.color,
                }}
              />
            }
            text={PodPlanCard?.text}
            color={PodPlanCard?.color}
            buttonText={PodPlanCard?.buttonText}
            buttonProps={PodPlanCard?.buttonProps}
            onClick={onHandlePodPlanClick}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default CurrentPlanCard;
