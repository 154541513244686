import { Stack, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import CountdownTimer from "../CountdownTimer";
import { isEmpty } from "lodash";
export const SessionWrapper = (props) => {
  const { children, time } = props;
  // ====================== RETURN
  return (
    <Stack
      border={"1px solid #E0E2E4"}
      borderRadius={"1rem"}
      overflow={"hidden"}
    >
      <Stack
        borderBottom={"1px solid #E0E2E4"}
        sx={{
          background:
            "linear-gradient(90deg, rgba(214, 31, 31, 0.00) 0%, rgba(214, 31, 31, 0.03) 100%), #FFF",
        }}
        justifyContent={"space-between"}
        flexDirection={"row"}
        p={2}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Typography color="#1F292E" fontWeight={600} fontSize={"1.125rem"}>
            Live community session
          </Typography>
          <FiberManualRecordIcon fontSize="small" sx={{ color: "#D61F1F" }} />
        </Stack>
        {!isEmpty(time) && (
          <Stack direction={"row"} alignItems={"center"} gap={0.5}>
            <TimerOutlinedIcon fontSize="small" color="#D9D9D9" />
            <CountdownTimer
              targetTime={time}
              color="#415058"
              fontWeight={600}
              fontSize={".875rem"}
            />
          </Stack>
        )}
      </Stack>
      <Stack p={3} backgroundColor="#FFF">
        {children}
      </Stack>
    </Stack>
  );
};
