import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import useCenteredTableData from "../../Hooks/useCenteredTableData";
import CustomisedTable from "../../ReusableComponents/CustomisedTable";
import TableFilter from "../../ReusableComponents/TableFilter";
import TableFooterFree from "../../ReusableComponents/TableFooterFree";
import { Box, IconButton, MenuItem, Paper, Select, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DeleteListService, DeleteMultipleListsService } from "../../../../Services/GetProspectsPosts";
import Pagination from "../../ReusableComponents/Pagination";
import toast from "react-hot-toast";
import StyledSpinner from "../../ReusableComponents/StyledSpinner";
import { FaPlus } from "react-icons/fa6";
import StyledButton from "../../ReusableComponents/Button";
import { useNurtureContext } from "../../../../Context/NurtureContext";
import { mixpanelEvent } from "../../../../Utils/mixpanelUtils";

const styles = {
  Paper: {
    background: "#fff",
    padding: "5px",
    border: "1px solid #D1D9E2",
    borderRadius: "10px",
    width: "100%",
    position: "relative",
  },
  Box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "11px 0 8px 0",
    width: "100%",
    position: "relative",
    "& .paginationBtns": {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
  btn: {
    height: "40px",
    width: "130px",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    paddingBlock: "8px",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "white",
    backgroundColor: "#0A72C3",
    alignSelf: "end",
  },

  footerText: {
    marginRight: "auto",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#0A72C3",
  },
  cellProps: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#1E1E1E",
  },
  FilterInput: {
    backgroundColor: "#F0F1F3",
    borderRadius: "100px",
    padding: "4px 10px !important",
    maxWidth: "140px",
    fontSize: "12px",
    fontWeight: "600",
    color: "#7C8B9D99",
    "&::placeholder": {
      fontSize: "12px",
      fontWeight: "600",
      color: "#7C8B9D99",
    },
  },
  FilterSelect: {
    backgroundColor: "#F0F1F3",
    borderRadius: "100px",
    padding: "4px 10px !important",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#7C8B9D99",
    "& .MuiSelect-select:focus": {
      background: "none",
    },
    "& svg": {
      height: "24px",
      width: "24px",
      color: "#7C8B9D99",
      marginRight: "4px",
    },
  },
};

function Lists() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userGroup, premiumUser } = useOutletContext();
  const {
    setRefreshPostsKey,
    setRefreshProspectsKey,
    setRefreshListsKey,
    prospects: prospectsData,
    isLoadingLists: isLoading,
    setIsLoadingLists: setIsLoading,
    lists,
  } = useNurtureContext();

  const [selectedList, setSelectedList] = useState("Choose a list");
  const [allLists, setAllLists] = useState(lists || []);
  const [filteredLists, setFilteredLists] = useState(lists || []);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [currentPageLists, setCurrenPageLists] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);

  const currentPage = !searchParams.get("page") ? 1 : parseInt(searchParams.get("page"));

  const handleChange = (event) => {
    setSelectedList(event.target.value);
  };
  const handleDeleteList = async (rowIndex) => {
    try {
      setIsLoading(true);
      const { id } = currentPageLists[rowIndex];
      const res = await DeleteListService(id);
      if (res.status === 200) {
        setRefreshListsKey((oldKey) => oldKey + 1);
        setRefreshProspectsKey((oldKey) => oldKey + 1);
        setRefreshPostsKey((oldKey) => oldKey + 1);
        setRowsSelected([]);
        toast.success("List deleted successfully");
      }
    } catch (err) {
      toast.error("Failed to delete list");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteMultipleLists = async (selectedRows) => {
    try {
      setIsLoading(true);
      const rowsIds = selectedRows.data.map((row) => row.dataIndex);
      const selectedListIds = rowsIds.map((_id) => currentPageLists[_id].id);
      const res = await DeleteMultipleListsService(selectedListIds);
      if (res.status === 200) {
        setRefreshListsKey((oldKey) => oldKey + 1);
        setRefreshProspectsKey((oldKey) => oldKey + 1);
        setRefreshPostsKey((oldKey) => oldKey + 1);
        setRowsSelected([]);
        toast.success("Lists deleted successfully");
      }
    } catch (err) {
      toast.error("Failed to delete the lists");
    } finally {
      setIsLoading(false);
    }
  };

  const listsWithProspectCount = useMemo(() => {
    return lists?.reduce((acc, list) => {
      const prospectCount = prospectsData?.filter((prospect) => prospect["list id"] === list.id).length || 0;
      acc[list.title] = {
        ...list,
        numberProspect: prospectCount,
      };
      return acc;
    }, {});
  }, [lists, prospectsData]);

  useEffect(() => {
    const from = (currentPage - 1) * resultsPerPage;
    const to = from + resultsPerPage - 1;
    setCurrenPageLists(filteredLists.slice(from, to + 1));
  }, [currentPage, resultsPerPage, filteredLists]);

  const filterData = useCallback(() => {
    let draft = allLists;
    if (selectedList !== "Choose a list") {
      draft = draft.filter((data) => data.title === selectedList);
    }
    setFilteredLists(draft);
  }, [allLists, selectedList]);

  useEffect(() => {
    filterData();
  }, [filterData, selectedList]);

  useEffect(() => {
    searchParams.set("page", 1);
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, resultsPerPage]);

  useEffect(() => {
    if (lists) {
      setAllLists(lists);
      setFilteredLists(lists);
    }
  }, [lists]);

  const listsLimit = useMemo(() => {
    if (userGroup === "Starter") return 10;
    // Trial users have same limit as Pro users
    if (userGroup === "Growth Plan" || userGroup === "Trial") return 100;
    if (userGroup === "Member" || userGroup === "Premium") return Infinity;
    // free users
    if (userGroup === "Engage_AI") return 1;
    return 1;
  }, [userGroup]);

  // --------------------------------------------------------------------------------------
  // TABLE
  // --------------------------------------------------------------------------------------

  const tableOptions = {
    search: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectToolbarPlacement: "above",
    selectableRows: "multiple",
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: false,
    rowsSelected,
    rowsPerPage: resultsPerPage,
    textLabels: {
      body: {
        noMatch: `You don't have any list yet. Click on "New List" to add one.`,
      },
    },
    customToolbar: () => (
      <TableFilter premiumUser={premiumUser}>
        <Select
          variant="standard"
          value={selectedList}
          onChange={handleChange}
          sx={styles.FilterSelect}
          disableUnderline={true}
          IconComponent={ExpandMoreIcon}
        >
          <MenuItem value="Choose a list">Choose a list </MenuItem>
          {lists &&
            lists.length &&
            lists.map((list) => {
              return (
                <MenuItem key={list.id} value={list.title}>
                  {list.title}
                </MenuItem>
              );
            })}
        </Select>
      </TableFilter>
    ),
    customToolbarSelect: (selectedRows) => {
      return (
        <IconButton
          className="deleteAll"
          onClick={() => handleDeleteMultipleLists(selectedRows)}
          sx={{ marginRight: "20px" }}
        >
          <img style={{ width: "18px", height: "18px" }} src="/images/deleteIcon.svg" alt="delete icon" />
        </IconButton>
      );
    },

    customFooter: () => (
      <tfoot>
        <tr>
          <td style={{ display: "flex", alignItems: "center", height: "50px", paddingInline: "20px" }}>
            {premiumUser ? (
              <Box sx={styles.Box}>
                <Pagination
                  ressource="Lists"
                  resulstsChoices={[10, 15, 50, 100]}
                  resultsPerPage={resultsPerPage}
                  setResultsPerPage={setResultsPerPage}
                  count={filteredLists?.length}
                />
              </Box>
            ) : (
              <TableFooterFree ressource="lists" />
            )}
          </td>
        </tr>
      </tfoot>
    ),
  };

  const tableColumns = [
    {
      name: "title",
      label: "List Name",
      options: {
        sort: false,
        setCellProps: () => ({
          style: { ...styles.cellProps, width: "15%" },
        }),
      },
    },
    {
      name: "email_notification",
      label: "Email Notification",
      options: {
        sort: false,
        ...useCenteredTableData({
          styles: { width: "25%" },
          className: "cellProps no-padding",
        }),
      },
    },
    {
      name: "number_of_prospects_in_list",
      label: "Number of Prospects",
      options: {
        sort: false,
        ...useCenteredTableData({
          styles: { ...styles.cellProps, width: "25%" },
          className: "cellProps no-padding",
        }),
        customBodyRenderLite: (dataIndex) => {
          const listTitle = currentPageLists[dataIndex].title;
          const { numberProspect } = listsWithProspectCount[listTitle] ?? {};
          return numberProspect || 0;
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        sort: false,
        ...useCenteredTableData({
          styles: { width: "35%" },
          className: "no-padding",
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <Box display="flex" alignItems="center" justifyContent="center" gap="1rem">
              <Link
                to={`edit/${currentPageLists[dataIndex]?.id}`}
                onClick={() => {
                  mixpanelEvent("segment_viewed", {
                    list_name: currentPageLists[dataIndex].title,
                    screen_name: "Segments",
                  });
                }}
              >
                <img style={{ width: "15px", height: "15px" }} src="/images/editIcon.svg" alt="edit icon" />
              </Link>
              <div style={{ cursor: "pointer" }} onClick={() => handleDeleteList(dataIndex)}>
                <img style={{ width: "15px", height: "15px" }} src="/images/deleteIcon.svg" alt="delete icon" />
              </div>
            </Box>
          );
        },
      },
    },
  ];

  const table = {
    columns: tableColumns,
    options: tableOptions,
    data: currentPageLists || [],
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb="1.5rem" gap="8px">
        {allLists.length >= listsLimit ? (
          <Tooltip title={"Please upgrade to a higher plan to create more lists."} placement="bottom-end">
            <span>
              <StyledButton disabled={true}>
                <FaPlus style={{ height: "16px", width: "16px" }} />
                <span>Add List</span>
              </StyledButton>
            </span>
          </Tooltip>
        ) : (
          <StyledButton
            onClick={() => {
              navigate("add");
            }}
          >
            <FaPlus style={{ height: "16px", width: "16px" }} />
            <span>Add List</span>
          </StyledButton>
        )}
      </Box>
      <Paper sx={!isLoading ? styles.Paper : { ...styles.Paper, minHeight: "100%" }}>
        <StyledSpinner isLoading={isLoading} />
        <CustomisedTable table={table} />
      </Paper>
    </>
  );
}

export default Lists;
