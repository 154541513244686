import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export const planMap = {
  Trial: "Pro",
  "Engage AI": "Free",
  Starter: "Starter",
  Growth: "Pro",
  Premium: "Elite",
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const descriptionItem = (isStarter) => [
  {
    title: "Cancel subscription 😔",
    value: "cancel",
    description: ["Lose access to advanced features and related data."],
    textColor: "#415058",
    backgroundColor: "#D61F1F",
    icon: CloseIcon,
  },
  {
    title: `${
      isStarter ? "Keep my current plan" : "Downgrade to a lower plan"
    } 👍`,
    value: "downgrade",
    description: [
      "Pay less, keep access to advanced features.",
      "We improve features to better suit your needs.",
    ],
    textColor: "#415058",
    backgroundColor: "#0A72C3",
    icon: CheckIcon,
  },
];

export const cancellationReasons = ["Uncollectible invoice", "Payment failed"];
