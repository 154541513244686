import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Button from "../../Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { formatPodDateTime } from "../utils";
import { debounce, isEmpty } from "lodash";
import { SubmitPodLink } from "../../../Services/PodComunnityService";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
const isValidLinkedInPost = (url) => {
  const linkedInPostRegex =
    // eslint-disable-next-line no-useless-escape
    /^https:\/\/www\.linkedin\.com\/(posts|feed\/update|pulse|in\/[^\/]+\/recent-activity\/all)\/.*/;
  return linkedInPostRegex.test(url);
};

export const JoinSessionModal = (props) => {
  // ====================== PROPS
  const { isOpen, currentPod, closeModal, refetch, isEdit } = props;

  // ====================== STATE
  const [buttonLoading, setButtonLoading] = useState(false);
  const [indicatorLoading, setIndicatorLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [link, setLink] = useState("");
  const [embedLink, setEmbedLink] = useState("");
  const [error, setError] = useState("");
  const defaultPodLink = currentPod?.link || "";

  // ====================== EFFECTS
  useEffect(() => {
    if (!isEmpty(defaultPodLink)) {
      setLink(defaultPodLink);
    }
  }, [defaultPodLink]);

  // ====================== VARIABLES
  const { engagementOpenTimeISO, engagementDeadlineISO } = currentPod || {};

  const { date: openDate, time: openTime } = useMemo(() => {
    return formatPodDateTime(engagementOpenTimeISO);
  }, [engagementOpenTimeISO]);

  const { time: closeTime } = useMemo(() => {
    return formatPodDateTime(engagementDeadlineISO);
  }, [engagementDeadlineISO]);

  // ====================== API
  const onHandleSubmit = useCallback(
    async (isIndicator) => {
      if (!isValidLinkedInPost(link)) {
        setError("Please provide a valid LinkedIn post URL");
        return;
      }
      setError("");
      if (isIndicator) {
        setIndicatorLoading(true);
      } else {
        setButtonLoading(true);
      }

      try {
        const body = {
          link,
          pod_id: "TIC1",
          tz: currentPod.timezone,
          session_id: currentPod.sessionId,
          get_embed_link: isIndicator,
        };

        const response = await SubmitPodLink(body);

        const result = await response.json();
        if (result.status === "success" && result.data) {
          setEmbedLink(result.data.embed_url);

          if (!isIndicator && !isEdit) {
            setIsSubmitted(true);
            refetch();
          }
        }

        if (result.status === "error") {
          setError(result.message);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setButtonLoading(false);
        setTimeout(() => {
          setIndicatorLoading(false);
        }, 1500);
        if (!isIndicator && isEdit) {
          refetch();
          closeModal();
        }
      }
    },
    [link, currentPod, refetch, closeModal, isEdit]
  );

  const debouncedSubmit = useMemo(
    () => debounce(onHandleSubmit, 1000),
    [onHandleSubmit]
  );

  useEffect(() => {
    if (isEmpty(link)) {
      setEmbedLink("");
    }
    if (link.trim()) {
      debouncedSubmit(true);
    }

    return () => {
      debouncedSubmit.cancel();
    };
  }, [link, debouncedSubmit]);

  // ====================== HANDLER
  const onHandleChange = (event) => {
    setLink(event.target.value);
  };

  const onOpenNewTab = () => {
    window.open(embedLink, "_blank");
  };

  // ====================== VARIABLES
  const disabledButton =
    buttonLoading ||
    isEmpty(link) ||
    !isEmpty(error) ||
    indicatorLoading ||
    !isValidLinkedInPost(link);

  // ====================== RENDER
  const renderEndEndorment = () => {
    if (indicatorLoading) {
      return (
        <Stack justifyContent={"center"} alignItems={"center"} pl={1}>
          <CircularProgress size={20} />
        </Stack>
      );
    }
    if (embedLink) {
      return (
        <Stack justifyContent={"center"} alignItems={"center"} pl={1}>
          <OpenInNewIcon
            sx={{ cursor: "pointer", color: "#0A72C3" }}
            onClick={onOpenNewTab}
          />
        </Stack>
      );
    }
    return null;
  };
  const renderContent = () => {
    if (isSubmitted && !isEdit) {
      return (
        <Stack
          pb={1}
          pt={6}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2.5}
          textAlign={"center"}
        >
          <Box
            p={2}
            backgroundColor="rgba(10, 114, 195, 0.10)"
            borderRadius={"1rem"}
          >
            <AccessAlarmsIcon sx={{ color: "#0A72C3" }} fontSize="large" />
          </Box>
          <Typography color="#1F292E" fontWeight={700} fontSize="1.25rem">
            Session starts at {openTime}
          </Typography>
          <Typography color="#415058" fontSize={"1rem"} width={"70%"}>
            You have successfully joined the community session, which will run
            from {openTime} to {closeTime}.
          </Typography>
          <Button
            startIcon={<CloseIcon />}
            style={{ paddingInline: "2rem" }}
            onClick={closeModal}
          >
            Close
          </Button>
        </Stack>
      );
    }
    return (
      <Stack gap={3}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            background:
              "linear-gradient(90deg, rgba(10, 114, 195, 0.00) 0%, rgba(10, 114, 195, 0.05) 100%), #FFF;",
          }}
          border="1px solid #E0E2E4"
          borderRadius={".65rem"}
          p={1.5}
          alignItems={"center"}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Typography color="#1F292E" fontSize={"1rem"} fontWeight={600}>
              {openDate}
            </Typography>
            <CircleIcon sx={{ width: 6, height: 6 }} color="#415058" />
            <Typography fontSize={"1rem"} color="#415058" fontWeight={600}>
              {openTime} - {closeTime}
            </Typography>
          </Stack>
          <Typography color="#415058" fontWeight={600} fontSize={".875rem"}>
            {currentPod.timezone}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography color="#415058" fontWeight={600} fontSize={"1rem"}>
            Your Linkedin post URL
          </Typography>
          <Stack gap={3}>
            <Stack direction={"row"} height={"100%"}>
              <Stack
                backgroundColor="rgba(160, 167, 171, 0.10)"
                border="1px solid #E0E2E4"
                justifyContent={"center"}
                borderRadius={"8px 0 0 8px"}
                p={1.85}
              >
                <LinkedInIcon sx={{ color: "#0a66c2" }} />
              </Stack>
              <TextField
                variant="outlined"
                fullWidth
                value={link}
                onChange={onHandleChange}
                placeholder="Paste your Linkedin post URL here"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#E0E2E4",
                    },
                  },
                }}
                InputProps={{
                  sx: {
                    borderRadius: "0 8px 8px 0",
                  },
                  endAdornment: renderEndEndorment(),
                }}
              />
            </Stack>
            {embedLink && (
              <Stack border="1px solid #E0E2E4" borderRadius={"8px"} p={1.5}>
                <iframe
                  src={embedLink}
                  title="linkedinPostEmbed"
                  style={{ border: "none", overflow: "hidden !important" }}
                  scrolling="no"
                  height={100}
                />
              </Stack>
            )}
            {!isEmpty(error) && (
              <Alert
                severity="error"
                sx={{
                  border: "1px solid #D61F1F",
                  borderRadius: "8px",
                  color: "#D61F1F",
                  fontWeight: 500,
                }}
              >
                {error}
              </Alert>
            )}
          </Stack>
        </Stack>
        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          gap={2}
          pt={1}
        >
          <Button
            variant="text-animated"
            onClick={closeModal}
            style={{ fontWeight: 600, color: "#0A72C3", fontSize: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onHandleSubmit(false)}
            style={{ fontWeight: 600, paddingInline: "2rem" }}
            loading={buttonLoading}
            disabled={disabledButton}
          >
            Submit post
          </Button>
        </Stack>
      </Stack>
    );
  };
  // ====================== RETURN
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      PaperProps={{
        sx: {
          borderRadius: "18px",
        },
      }}
      maxWidth={"sm"}
      fullWidth
    >
      {!isSubmitted && (
        <Stack
          p={"28px 24px 12px 24px"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={1.5}
        >
          <AddCircleIcon />
          <DialogTitle
            color="#1F292E"
            fontSize={"1.65rem"}
            fontWeight={700}
            lineHeight={"1.2"}
            display={"flex"}
            alignItems={"center"}
            sx={{
              padding: 0,
            }}
          >
            Join session
          </DialogTitle>
        </Stack>
      )}
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          backgroundColor: "#ECEDEE",
          borderRadius: "3px",
          right: 25,
          top: 25,
          color: "#415058",
          "&:hover": {
            backgroundColor: "#E0E2E4",
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};
