import domain from "./DomainService";

export const GetPodSession = (userId) => {
  // Test URL
  // return fetch(
  //   `https://wpm3vptw7tv5exddn6whep6szi0nhoqj.lambda-url.ap-southeast-2.on.aws?userId=${userId}`
  // );

  return fetch(
    `https://wv4ozxj5m2qq5o7ctdlhdq5qiq0ftgtk.lambda-url.ap-southeast-2.on.aws/?userId=${userId}`
  );
};

export function SubmitPodLink(body) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Authorization: "JWT " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  return fetch(domain() + "submit-link", options);
}

export function DeletePost(body) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  return fetch(domain() + "delete-link", options);
}

export function GetEngagement(sessionId) {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  return fetch(domain() + `engagement?session_id=${sessionId}`, options);
}

export function MarkComplete(sessionId) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: sessionId }),
  };
  return fetch(domain() + "mark-complete", options);
}

export function GetAccountability(sessionId) {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  return fetch(domain() + `accountability?session_id=${sessionId}`, options);
}

export function GetPodHistory(userId) {
  return fetch(
    `https://f5n7nmiatbszdz4r63dtkzhaxa0arkmc.lambda-url.ap-southeast-2.on.aws/?userId=${userId}`);
}
