import { Alert, Skeleton, Stack, Typography } from "@mui/material";
import { PodCard } from "./PodCard";
import ErrorIcon from "@mui/icons-material/Error";
import { JoinSessionModal } from "./containers/JoinSessionModal";
import { useState, useEffect, useCallback, useContext } from "react";
import toast from "react-hot-toast";
import { GetPodSession } from "../../Services/PodComunnityService";
import { formatPodDateTime } from "./utils";
import { CancelPostModal } from "./containers/CancelPostModal";
import { Context } from "../../Store/Store";
export const PodCardList = () => {
  // ====================== STATE
  const [podSession, setPodSession] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state] = useContext(Context);

  const [isJoinSessionOpen, setIsJoinSessionOpen] = useState({
    pod: {},
    isOpen: false,
  });
  const [isCancelOpen, setIsCancelOpen] = useState({
    pod: {},
    isOpen: false,
  });

  // ====================== VARIABLES
  const userId = state?.user?.data?.id;

  // ====================== HANDLERS
  const onHandleJoinSession = (pod, isEdit = false) => {
    setIsJoinSessionOpen({ pod, isOpen: true, isEdit: isEdit });
  };

  const onHandleCancelPost = (pod) => {
    setIsCancelOpen({ pod, isOpen: true });
  };

  // ====================== API
  const fetchPodSession = useCallback(async () => {
    if (!userId) return;
    setLoading(true);
    try {
      const res = await GetPodSession(userId);
      const data = await res.json();
      setPodSession([...data]);
    } catch (error) {
      toast.error("Error fetching pod session. Please try again later");
    } finally {
      setLoading(false);
    }
  }, [userId]);

  // ====================== EFFECT
  useEffect(() => {
    fetchPodSession();
  }, [fetchPodSession]);

  // ====================== VARIABLES
  const joinedSession = podSession.filter((session) => session.joined === true);
  const communitySession = podSession.filter(
    (session) => session.joined === false
  );

  // ====================== HANDLERS
  const closeJoinSessionModal = () => {
    setIsJoinSessionOpen((prevState) => ({
      ...prevState,
      isOpen: false,
      isEdit: false,
    }));
  };

  const closeCancelPostModal = () => {
    setIsCancelOpen((prevState) => ({ ...prevState, isOpen: false }));
  };

  const renderJoinedPod = () => {
    return (
      <Stack gap={2.5}>
        <Typography color="#415058" fontWeight={600} fontSize={"1rem"}>
          Joined sessions
        </Typography>
        {joinedSession.map((pod) => {
          const isLive = pod.engagementOpen;

          const {
            date: openDate,
            time: openTime,
            currentDateString,
          } = formatPodDateTime(pod.engagementOpenTimeISO);

          const { date: closeDate, time: closeTime } = formatPodDateTime(
            pod.engagementDeadlineISO
          );

          return (
            <PodCard
              key={pod.sessionId}
              openDate={openDate}
              openTime={openTime}
              openDateString={currentDateString}
              closeDate={closeDate}
              closeTime={closeTime}
              timezones={pod.timezone}
              pod={pod}
              isLive={isLive}
              membersJoined={pod.membersJoined}
              onJoinSession={(editPost) => onHandleJoinSession(pod, editPost)}
              onCancelPost={() => onHandleCancelPost(pod)}
            />
          );
        })}
      </Stack>
    );
  };

  const renderCommunityPod = () => {
    return (
      communitySession &&
      communitySession.length > 0 && (
        <Stack gap={2.5}>
          <Typography color="#415058" fontWeight={600} fontSize={"1rem"}>
            Community sessions
          </Typography>
          <Alert
            severity="warning"
            icon={<ErrorIcon sx={{ color: "#EAAB3E" }} />}
            sx={{
              border: "1px solid #EAAB3E",
              borderRadius: "1rem",
              color: "#8B5800",
              fontWeight: 500,
            }}
          >
            Each session is open for link submission 2 hours before its start
            time.
          </Alert>
          {communitySession.map((pod) => {
            const isLive = pod.engagementOpen;

            const {
              date: openDate,
              time: openTime,
              currentDateString: openDateString,
            } = formatPodDateTime(
              isLive ? pod.engagementOpenTimeISO : pod.podOpenTimeISO
            );

            const { date: closeDate, time: closeTime } = formatPodDateTime(
              isLive ? pod.engagementDeadlineISO : pod.cutoffTimeISO
            );

            return (
              <PodCard
                key={pod.sessionId}
                openDate={openDate}
                openTime={openTime}
                closeDate={closeDate}
                openDateString={openDateString}
                closeTime={closeTime}
                timezones={pod.timezone}
                pod={pod}
                isLive={isLive}
                membersJoined={pod.membersJoined}
                onJoinSession={() => onHandleJoinSession(pod)}
              />
            );
          })}
        </Stack>
      )
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Stack gap={2.5}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"15%"}
            height={50}
          />
          <Skeleton variant="rounded" height={130} width={"100%"} />
          <Skeleton variant="rounded" height={130} width={"100%"} />
          <Skeleton variant="rounded" height={130} width={"100%"} />
          <Skeleton variant="rounded" height={130} width={"100%"} />
        </Stack>
      );
    }

    return (
      <>
        {joinedSession && joinedSession.length > 0 && renderJoinedPod()}
        {communitySession &&
          communitySession.length > 0 &&
          renderCommunityPod()}
      </>
    );
  };

  // ====================== RETURN
  return (
    <>
      {isJoinSessionOpen.isOpen && (
        <JoinSessionModal
          isOpen={isJoinSessionOpen.isOpen}
          closeModal={closeJoinSessionModal}
          currentPod={isJoinSessionOpen.pod}
          isEdit={isJoinSessionOpen.isEdit}
          refetch={fetchPodSession}
        />
      )}
      <CancelPostModal
        currentPod={isCancelOpen.pod}
        isOpen={isCancelOpen.isOpen}
        closeModal={closeCancelPostModal}
        refetch={fetchPodSession}
      />
      <Stack gap={5} pt={5}>
        {renderContent()}
      </Stack>
    </>
  );
};
