import React, { useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";

import { Stack } from "@mui/material";
import {
  ModalCancelActionButton,
  ModalPlainButton,
  ModalPrimaryActionButton,
} from "../../components/ModalButton";
import { useModal } from "../ModalProvider";
import { RadioButton } from "../../components/RadioButton";
import { DealDescription } from "../../components/DealDescription";
import { descriptionItem } from "../../constant";
import { SubmitCancelReason } from "../../../../Services/BillingService";
import StripePortalSessionService from "../../../../Services/StripeService";
import toast from "react-hot-toast";
import { useGlobalContext } from "../../../../Context/GlobalContext";

export const ChooseDeal = () => {
  // ====================== STATE
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  // ====================== CONTEXT
  const { closeModal, formData } = useModal();
  const {
    plan: { eai },
  } = useGlobalContext();

  // ====================== VARIABLES
  const isStarter = eai?.plan === "Starter";

  // ====================== HANDLERS
  const onHandleRadioChange = (value) => {
    setValue(value);
  };

  const onHandleChooseLowerPlan = () => {
    closeModal();
    setTimeout(() => {
      const element = document.getElementById("pricing-plan");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 300); // Delay scrolling to allow modal transition
  };

  const onHandleCancelSubscription = async () => {
    setLoading(true);
    try {
      await SubmitCancelReason(formData);
      try {
        const result = await StripePortalSessionService();
        const stripePortalSession = await result.json();
        if (stripePortalSession?.data?.url) {
          window.open(stripePortalSession.data.url, "_blank");
        }
      } catch (error) {
        toast.error(
          "Error cancelling subscription, please contact Admin for support"
        );
        console.error("Error fetching current plan:", error);
      } finally {
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        "Error cancelling subscription, please contact Admin for support"
      );
      console.error("Error fetching current plan:", error);
    }
  };
  // ====================== RENDER
  const renderButton = () => {
    if (value === "cancel") {
      return (
        <>
          <ModalCancelActionButton
            fullWidth
            loading={loading}
            onClick={onHandleCancelSubscription}
          >
            Cancel Subscription
          </ModalCancelActionButton>
        </>
      );
    }

    return (
      <ModalPrimaryActionButton fullWidth onClick={onHandleChooseLowerPlan}>
        Choose a lower plan
      </ModalPrimaryActionButton>
    );
  };

  const renderModalFooter = () => {
    if (isStarter && value !== "cancel") {
      return (
        <ModalPrimaryActionButton fullWidth onClick={closeModal}>
          Keep my current plan
        </ModalPrimaryActionButton>
      );
    }
    return (
      <>
        <ModalPlainButton fullWidth onClick={closeModal}>
          Keep my current plan
        </ModalPlainButton>
        {renderButton()}
      </>
    );
  };

  const renderOptions = () => {
    return descriptionItem(isStarter).map((item, index) => {
      return (
        <RadioButton
          key={index}
          onChange={() => onHandleRadioChange(item.value)}
          value={item.value}
          isCancel={item.value === "cancel"}
        >
          <DealDescription
            description={item.description}
            Icon={item.icon}
            textColor={item?.textColor}
            backgroundColor={item?.backgroundColor}
            title={item.title}
          />
        </RadioButton>
      );
    });
  };

  return (
    <Stack width={"100%"} gap={2}>
      <RadioGroup
        name="deal"
        value={value}
        onChange={(e) => onHandleRadioChange(e.target.value)}
      >
        <Stack gap={2} width="100%">
          {renderOptions()}
        </Stack>
      </RadioGroup>

      {value !== "" && (
        <Stack direction={"row"} gap={1} sx={{ display: "flex", mt: 2 }}>
          {renderModalFooter()}
        </Stack>
      )}
    </Stack>
  );
};
