import { Stack, Typography } from "@mui/material";

export const Header = (props) => {
  const {
    icon,
    title,
    subTitle,
    titleProps,
    subTitleProps,
    rightView,
    ...restProps
  } = props;
  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      justifyContent={"space-between"}
      gap={2}
      {...restProps}
    >
      <Stack gap={0.5}>
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          {icon}
          <Typography
            variant="h5"
            fontWeight={"bold"}
            color="#1F292E"
            {...titleProps}
          >
            {title}
          </Typography>
        </Stack>
        <Typography color="#415058" {...subTitleProps}>
          {subTitle}
        </Typography>
      </Stack>
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        {rightView}
      </Stack>
    </Stack>
  );
};
