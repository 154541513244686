import { Box, FormLabel, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { EditListService, GetListByIdService } from "../../../../Services/GetProspectsPosts";
import { Controller, useForm } from "react-hook-form";
import { PrimaryButton, SecondaryButton } from "../../ReusableComponents/StyledButton";
import AddUpdate from "../../ReusableComponents/AddUpdate";
import { StyledTextField } from "../../ReusableComponents/StyledTextField";
import toast from "react-hot-toast";
import StyledSpinner from "../../ReusableComponents/StyledSpinner";
import { useNurtureContext } from "../../../../Context/NurtureContext";

const PAGE_TITLE = "Edit your list details";

const styles = {
  Btn: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "16px",
    color: "#0A72C3",
    fontWeight: "600",
    background: "none",
    border: "none",
    margin: "0",
  },
  Title: {
    fontSize: "16px",
    color: "#0A72C3",
    fontWeight: "700",
    marginInline: "auto",
  },
  FormBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  Form: {
    maxWidth: "420px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginBottom: "4rem",
  },
  FormGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "1rem",
  },
  FormLabel: {
    fontSize: "16px",
    color: "#1E1E1E",
    fontWeight: "600",
    lineHeight: "20px",
    fontFamily: "Poppins",
    margin: "0",
    "&.Mui-focused": {
      color: "#000",
    },
  },
  FormBtn: {
    fontSize: "14px",
    width: "167px",
    height: "40px",
  },
};

function UpdateList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [listData, setListData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { premiumUser } = useOutletContext();
  const { setRefreshListsKey, setRefreshProspectsKey } = useNurtureContext();

  const initialFormData = {
    ...listData,
    title: listData?.title ?? "",
    email_notification: listData?.email_notification && premiumUser ? listData?.email_notification : "Weekly",
    day: listData?.days_to_scrape?.[0] ?? "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = useForm({
    defaultValues: initialFormData,
    values: initialFormData,
  });

  const formValues = watch();

  const onSubmit = async (editData) => {
    try {
      setIsLoading(true);
      let obj;
      obj = {
        listId: id,
        listData: { ...editData, hour: "8am", timezone: 120, day: "Mon" },
      };
      delete obj.listData["number_of_prospects_in_list"];
      const res = await EditListService(obj);
      if (res.status === 200) {
        setRefreshListsKey((oladKey) => oladKey + 1);
        setRefreshProspectsKey((oladKey) => oladKey + 1);
        toast.success("List updated successfully");
        navigate(-1);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    GetListByIdService(id).then((res) => {
      res.json().then((json) => {
        setListData(json.data[0]);
      });
    });
  }, [id]);

  return (
    <AddUpdate ressource="lists" title={PAGE_TITLE}>
      <StyledSpinner isLoading={isLoading} />
      <form style={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={styles.FormGroup}>
          <FormLabel sx={styles.FormLabel} htmlFor="title">
            List Name
          </FormLabel>
          <Controller
            name="title"
            control={control}
            rules={{ required: "Please provide a list name." }}
            render={({ field }) => (
              <StyledTextField
                {...field}
                variant="standard"
                height="50px"
                error={!!errors?.title}
                helperText={errors?.title?.message}
                placeholder="List #1"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            )}
          />
        </Box>
        <Box sx={styles.FormGroup}>
          <FormLabel sx={styles.FormLabel} htmlFor="email_notification">
            Email notification
          </FormLabel>
          <Controller
            name="email_notification"
            control={control}
            rules={{ required: "Please select either you want daily or weekly email notification." }}
            render={({ field }) => (
              <StyledTextField
                {...field}
                select
                variant="standard"
                height="50px"
                error={!!errors?.email_notification}
                helperText={errors?.email_notification?.message}
                InputProps={{
                  disableUnderline: true,
                }}
              >
                <MenuItem key={0} value="Daily" disabled={!premiumUser}>
                  Daily
                </MenuItem>
                <MenuItem key={1} value="Weekly">
                  Weekly
                </MenuItem>
                <MenuItem key={2} value="Off">
                  None
                </MenuItem>
              </StyledTextField>
            )}
          />
        </Box>
        {(formValues.email_notification !== "Daily" || formValues.email_notification !== "Off") && (
          <Box sx={styles.FormGroup}>
            <FormLabel sx={styles.FormLabel} htmlFor="day">
              Receive Day
            </FormLabel>
            <Controller
              name="day"
              control={control}
              rules={{
                required:
                  formValues.email_notification !== "Daily" || formValues.email_notification !== "Off"
                    ? "Please choose a receive day."
                    : false,
                validate: (value) => value !== "Day to receive email notification" || "Please choose a valid day.",
              }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  select
                  variant="standard"
                  height="50px"
                  error={!!errors?.day}
                  helperText={errors?.day?.message}
                  disabled={formValues.email_notification === "Daily" || formValues.email_notification === "Off"}
                  InputProps={{
                    disableUnderline: true,
                  }}
                >
                  <MenuItem key={0} value="Day to receive email notification">
                    Day to receive email notification
                  </MenuItem>
                  <MenuItem key={1} value="Mon">
                    Monday
                  </MenuItem>
                  <MenuItem key={2} value="Tue">
                    Tuesday
                  </MenuItem>
                  <MenuItem key={3} value="Wed">
                    Wednesday
                  </MenuItem>
                  <MenuItem key={4} value="Thu">
                    Thursday
                  </MenuItem>
                  <MenuItem key={5} value="Fri">
                    Friday
                  </MenuItem>
                  <MenuItem key={6} value="Sat">
                    Saturday
                  </MenuItem>
                  <MenuItem key={7} value="Sun">
                    Sunday
                  </MenuItem>
                </StyledTextField>
              )}
            />
          </Box>
        )}
        <Box display="flex" justifyContent="center" gap="1rem" mt="2rem">
          <PrimaryButton disabled={!isDirty} type="submit" sx={styles.FormBtn}>
            Save
          </PrimaryButton>
          <SecondaryButton onClick={() => navigate(-1)} sx={styles.FormBtn}>
            Cancel
          </SecondaryButton>
        </Box>
      </form>
    </AddUpdate>
  );
}

export default UpdateList;
