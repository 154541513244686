import { FormControlLabel, Radio, Stack } from "@mui/material";

export const RadioButton = (props) => {
  const { value, isCancel = true, children, onChange } = props;
  const color = isCancel ? "#D61F1F" : "#0A72C3";
  return (
    <Stack
      border={`1px solid ${color}`}
      borderRadius={"8px"}
      cursor="pointer"
      onChange={onChange}
      py={2}
      px={3}
    >
      <FormControlLabel
        value={value}
        control={
          <Radio
            sx={{
              color,
              "&, &.Mui-checked": {
                color,
              },
            }}
          />
        }
        sx={{ alignItems: "flex-start" }}
        label={
          <Stack pl={1} gap={2}>
            {children}
          </Stack>
        }
      />
    </Stack>
  );
};
