import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
} from "@mui/material";
import Button from "../../Button";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";

export const InstallExtensionModal = (props) => {
  const { open } = props;

  // ====================== HANDLERS
  const onHandleClose = () => {
    setIsOpen(false);
  };

  const onHandleOpenExtensionPage = () => {
    const userAgent = navigator.userAgent;
    let browserName = "";

    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Firefox";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
    }

    switch (browserName) {
      case "Firefox":
        window.open(
          "https://addons.mozilla.org/fr/firefox/addon/engage-ai/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search",
          "_blank"
        );
        break;

      case "Chrome":
        window.open(
          "https://chromewebstore.google.com/detail/engage-ai-chatgpt-for-soc/nelhhkchoapcbpcgpmmiahfkcdhgecaf?utm_source=ext_app_menu",
          "_blank"
        );
        break;

      default:
        window.open(
          "https://chromewebstore.google.com/detail/engage-ai-chatgpt-for-soc/nelhhkchoapcbpcgpmmiahfkcdhgecaf?utm_source=ext_app_menu",
          "_blank"
        );
        break;
    }
  };
  // ====================== STATE
  const [isOpen, setIsOpen] = useState(open);

  // ====================== RENDER
  const renderContent = () => {
    return (
      <Stack gap={3} pt={1} justifyContent={"center"} alignItems={"center"}>
        <Typography
          color="#415058"
          fontSize={"1.15rem"}
          fontWeight={500}
          textAlign={"center"}
        >
          During the session community, you will surely need the EAI Chrome
          extension. It helps you write engaging comments with the help of AI.
        </Typography>
        <Stack justifyContent="center" gap={2} alignItems={"center"}>
          <Button
            startIcon={<DownloadIcon />}
            style={{
              fontWeight: 600,
              paddingInline: "2rem",
            }}
            onClick={onHandleOpenExtensionPage}
          >
            Install Engage AI Extension
          </Button>
          <Button
            variant={"text"}
            style={{
              color: "#415058",
              fontWeight: 600,
            }}
            onClick={onHandleClose}
          >
            Maybe later
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onHandleClose}
      PaperProps={{
        sx: {
          borderRadius: "18px",
        },
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <Stack gap={2} justifyContent={"center"} alignItems={"center"} pt={4}>
        <Stack p={1.5} sx={{ backgroundColor: "#0A72C3", borderRadius: "8px" }}>
          <InstallDesktopIcon fontSize="large" style={{ color: "#FFF" }} />
        </Stack>
        <DialogTitle
          color="#1F292E"
          fontSize={"1.65rem"}
          fontWeight={700}
          lineHeight={"1.2"}
          justifyContent={"center"}
          display={"flex"}
          alignItems={"center"}
          sx={{
            padding: 0,
          }}
        >
          Install extension
        </DialogTitle>
      </Stack>
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};

export default InstallExtensionModal;
