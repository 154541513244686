import { Stack } from "@mui/material";

export const ContainerWrapper = (props) => {
  const { children, ...restProps } = props;
  return (
    <Stack pt="1.25rem" px={"1.5rem"} pb={"4rem"} {...restProps}>
      {children}
    </Stack>
  );
};
