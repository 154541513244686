import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GroupsIcon from "@mui/icons-material/Groups";
import CircleIcon from "@mui/icons-material/Circle";
import { PodSessionCard } from "../../PodSessionCommunity/PodSessionCard";
import { useEffect, useState } from "react";
import { GetAccountability } from "../../../Services/PodComunnityService";
import { isEmpty, times } from "lodash";
import Button from "../../Button";
import { useNavigate } from "react-router-dom";
import { formatUtcToLocal } from "../utils";

export const ViewEngagementModal = (props) => {
  const { isOpen, closeModal, currentEngagement } = props;

  // ====================== VARIABLES
  const sessionId = currentEngagement.sessionId;
  const { date, time } = formatUtcToLocal(currentEngagement?.date);

  // ====================== HOOKS
  const navigate = useNavigate();

  // ====================== STATE
  const [loading, setLoading] = useState(true);
  const [accountability, setAccountability] = useState([]);

  useEffect(() => {
    const fetchAccountability = async () => {
      if (!sessionId) return;
      setLoading(true);
      try {
        const res = await GetAccountability(sessionId);
        const data = await res.json();
        if (data.status === "success") {
          setAccountability([...data.data.data]);
        }
      } catch (error) {
        console.error("error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAccountability();
  }, [sessionId]);

  // ====================== HANDLER
  const onHandleReport = () => {
    return navigate("/ReportMember");
  };

  // ====================== RENDER
  const renderRightView = () => {
    return (
      <>
        <Button
          variant="outlined"
          color="error"
          style={{
            color: "#D61F1F",
            border: "1px solid #D61F1F",
            borderRadius: ".65rem",
            fontWeight: 600,
          }}
          onClick={onHandleReport}
        >
          Report
        </Button>
      </>
    );
  };
  const renderLoading = () => {
    if (loading) {
      return times(3, (index) => {
        return (
          <Box key={index}>
            <Skeleton
              variant="rectangular"
              height={80}
              sx={{
                borderRadius: ".5rem",
              }}
            />
          </Box>
        );
      });
    }

    if (isEmpty(accountability)) {
      return (
        <Stack
          border="1px solid #E0E2E4"
          borderRadius={"8px"}
          style={{
            background:
              "linear-gradient(90deg, rgba(10, 114, 195, 0.00) 0%, rgba(10, 114, 195, 0.05) 100%), #FFF",
          }}
          justifyContent={"center"}
          alignItems={"center"}
          py={10}
        >
          <Typography fontWeight={500} color="#415058">
            Unable to load engagements for this post
          </Typography>
        </Stack>
      );
    }

    return (
      <>
        {accountability.map((i) => {
          return (
            <Box key={i.sessionId}>
              <PodSessionCard
                isCompletedEngage
                pod={i}
                rightItem={renderRightView}
              />
            </Box>
          );
        })}
      </>
    );
  };
  const renderContent = () => {
    return (
      <Stack gap={3}>
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Typography color="#1F292E" fontSize={".875rem"} fontWeight={600}>
            {date}
          </Typography>
          <CircleIcon sx={{ width: 6, height: 6 }} color="#415058" />
          <Typography fontSize={".875rem"} color="#415058" fontWeight={600}>
            {time}
          </Typography>
        </Stack>
        <Stack
          sx={{
            maxHeight: "20rem",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "3px", // Thin scrollbar
            },
            "&::-webkit-scrollbar-track": {
              background: "#c4e4fc",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#0A72C3",
              borderRadius: "15px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#3A7BFC", // Darker blue on hover
            },
            height: "100%",
          }}
          gap={1.5}
          pr={1.5}
          pb={1}
        >
          {renderLoading()}
        </Stack>
      </Stack>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      PaperProps={{
        sx: {
          borderRadius: "18px",
        },
      }}
      maxWidth={"md"}
      fullWidth
    >
      <Stack
        p={"28px 24px 12px 24px"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={1.5}
      >
        <GroupsIcon />
        <DialogTitle
          color="#1F292E"
          fontSize={"1.65rem"}
          fontWeight={700}
          lineHeight={"1.2"}
          display={"flex"}
          alignItems={"center"}
          sx={{
            padding: 0,
          }}
        >
          Post engagements
        </DialogTitle>
      </Stack>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          backgroundColor: "#ECEDEE",
          borderRadius: "3px",
          right: 25,
          top: 25,
          color: "#415058",
          "&:hover": {
            backgroundColor: "#E0E2E4",
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ overflow: "hidden" }}>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};
