import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const styles = {
  pagination: {
    display: "flex",
    alignItems: "center",
    justifySelf: "center",
    gap: "2rem",
  },
  paginationBtn: {
    borderRadius: "8px",
    height: "30px",
    width: "30px",
    color: "#0A72C3",
    padding: "0",
    border: "none",
    background: "none",
    margin: "0",
  },
  paginationBtnActif: {
    background: "#0A72C3",
    color: "white",
  },
  NextBtn: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "16px",
    color: "#0A72C3",
    fontWeight: "600",
    background: "none",
    border: "none",
    margin: "0",
  },
  FooterText: {
    marginRight: "auto",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#0A72C3",
    whiteSpace: "nowrap",
  },
  select: {
    fontWeight: "600",
    height: "25px",
    fontSize: "14px",
    border: "none",
    background: "none",
    color: "#0A72C3",
    "& .MuiSelect-icon": { color: "#0A72C3" },
  },
};

function Pagination({ ressource, count, resulstsChoices, resultsPerPage, setResultsPerPage }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = !searchParams.get("page") ? 1 : parseInt(searchParams.get("page"));
  const pageCount = Math.ceil(count / resultsPerPage);
  function prevPage() {
    const prev = currentPage === 1 ? currentPage : currentPage - 1;
    searchParams.set("page", prev);
    setSearchParams(searchParams);
  }

  function goToPage(page) {
    searchParams.set("page", page);
    setSearchParams(searchParams);
  }

  function nextPage() {
    const next = currentPage === pageCount ? currentPage : currentPage + 1;
    searchParams.set("page", next);
    setSearchParams(searchParams);
  }

  const handleChange = (event) => {
    setResultsPerPage(event.target.value);
  };

  return (
    <Box
      sx={{
        ...styles.pagination,
        width: ressource === "Posts" ? "auto" : "100%",
        marginTop: ressource === "Posts" ? "1rem" : "0",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap=".5rem"
        sx={{
          position: "absolute",
          left: ressource === "Posts" && pageCount <= 1 ? "50%" : "0",
          bottom: ressource === "Posts" && pageCount <= 1 ? "0" : "",
          transform: ressource === "Posts" && pageCount <= 1 ? "translateX(-50%)" : "",
        }}
      >
        <Typography sx={styles.FooterText}>{ressource} Per Page:</Typography>
        <Select
          variant="standard"
          value={resultsPerPage}
          onChange={handleChange}
          disableUnderline={true}
          sx={styles.select}
        >
          {resulstsChoices.map((choice) => (
            <MenuItem key={choice} value={choice}>
              {choice}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {pageCount > 1 && (
        <Box sx={{ marginInline: "auto" }} display="flex" alignItems="center" gap="4px">
          {currentPage > 1 && (
            <button disabled={currentPage === 1} onClick={prevPage} style={styles.NextBtn}>
              <img
                style={{ width: "12px", height: "12px", transform: "rotateY(180deg)" }}
                src="/images/rightChevronIcon.svg"
                alt="next icon"
              />
              <span>Prev</span>
            </button>
          )}

          {/* {Array.from({ length: pageCount }, (_, i) => (
            <button
              key={i}
              style={
                i + 1 === currentPage ? { ...styles.paginationBtn, ...styles.paginationBtnActif } : styles.paginationBtn
              }
              onClick={() => goToPage(i + 1)}
            >
              {i + 1}
            </button>
          ))} */}
          {(() => {
            const pages = [];
            let startPage = Math.max(currentPage - 4, 1);
            let endPage = Math.min(startPage + 7, pageCount);

            if (endPage - startPage < 7) {
              startPage = Math.max(endPage - 7, 1);
            }

            for (let i = startPage; i <= endPage; i++) {
              pages.push(
                <button
                  key={i}
                  style={
                    i === currentPage ? { ...styles.paginationBtn, ...styles.paginationBtnActif } : styles.paginationBtn
                  }
                  onClick={() => goToPage(i)}
                >
                  {i}
                </button>
              );
            }

            return pages;
          })()}

          {currentPage < pageCount && (
            <button disabled={currentPage === pageCount} onClick={nextPage} style={styles.NextBtn}>
              <span>Next</span>
              <img style={{ width: "12px", height: "12px" }} src="/images/rightChevronIcon.svg" alt="next icon" />
            </button>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Pagination;
