import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { useModal } from "./ModalProvider";
import UnsubscribeForm from "./step/UnsubscribeForm";
import CloseIcon from "@mui/icons-material/Close";
import { FirstStepConfirmation } from "./step/FirstStepConfirmation";
import { ChooseDeal } from "./step/ChooseDeal";

export const UnsubscribeModal = () => {
  const { isOpen, closeModal, step } = useModal();

  const renderStep = () => {
    switch (step) {
      case 1:
        return <FirstStepConfirmation />;
      case 2:
        return <UnsubscribeForm />;
      case 3:
        return <ChooseDeal />;
      default:
        return <FirstStepConfirmation />;
    }
  };

  const renderTitle = () => {
    switch (step) {
      case 1:
        return "Do you really want to cancel your subscription? 🤔";
      case 2:
        return "Sorry to see you go 😔";
      case 3:
        return "Choose A Deal";
      default:
        return "Do you really want to cancel your subscription? 🤔";
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      PaperProps={{
        sx: {
          borderRadius: "18px",
        },
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <Stack>
        <DialogTitle
          color={"#D61F1F"}
          fontWeight={700}
          fontSize={"1rem"}
          sx={{ paddingBottom: ".5rem !important" }}
        >
          Cancel subscription
        </DialogTitle>
        <DialogTitle
          color="#1F292E"
          fontSize={"1.65rem"}
          fontWeight={700}
          lineHeight={"1.2"}
          sx={{
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
          }}
        >
          {renderTitle()}
        </DialogTitle>
      </Stack>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          backgroundColor: "#ECEDEE",
          borderRadius: "3px",
          right: 25,
          top: 25,
          color: "#415058",
          "&:hover": {
            backgroundColor: "#E0E2E4",
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{renderStep()}</DialogContent>
    </Dialog>
  );
};

export default UnsubscribeModal;
