import { Box, Stack, Typography } from "@mui/material";
import { MetricsBox } from "../MetricsBox";
import WarningIcon from "@mui/icons-material/Warning";
import ReportIcon from "@mui/icons-material/Report";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Chart from "react-apexcharts";
import { Tooltip } from "../Tooltip";
import HelpIcon from "@mui/icons-material/Help";
const options = {
  chart: {
    type: "donut",
  },
  labels: ["Monitored prospects", "Distractions"],
  colors: ["#0A72C3", "#DFD4CB"],
  stroke: {
    width: 0,
  },
  legend: {
    show: true,
    position: "bottom",
    markers: {
      radius: 12,
      offsetX: -10,
    },
    itemMargin: {
      horizontal: 14,
    },
    labels: { colors: "#707981" },
    formatter: function (seriesName) {
      return seriesName.replace(" ", "<br>");
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
  },
  states: {
    hover: {
      filter: {
        type: "darken",
        value: 0.15,
      },
    },
    active: { filter: { type: "none", value: 0 } },
  },
};

export const InteractChart = (props) => {
  const { data } = props;

  // ====================== VARIABLES
  const percentageDistractions = 100 - Number(data);

  // ====================== HELPER
  const getTooltipAndIcon = () => {
    if (data >= 75) {
      return {
        text: "This percentage is within the recommended value, keep it up with your prospects!",
        icon: <CheckCircleIcon sx={{ color: "#64BE37" }} fontSize="large" />,
      };
    }
    if (data < 75 && data > 50) {
      return {
        text: "This percentage is below the recommended value, start engageing with more prospects!",
        icon: <WarningIcon sx={{ color: "#EAAB3E" }} fontSize="large" />,
      };
    }
    return {
      text: "This percentage is below the recommended value, start engaging with more prospects!",
      icon: <ReportIcon sx={{ color: "#D61F1F" }} fontSize="large" />,
    };
  };

  // ====================== RENDER
  const renderPreSection = () => {
    const { text, icon } = getTooltipAndIcon();

    return (
      <Stack paddingInline={3} width={"100%"}>
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Tooltip tooltipText={text} placement={"left"}>
            {icon}
          </Tooltip>
          <Typography color="#415058" fontSize={"2rem"} fontWeight={500}>
            {data}%
          </Typography>
        </Stack>
        <Typography color="#707981" fontSize={".875rem"}>
          with monitored prospects
        </Typography>
      </Stack>
    );
  };

  return (
    <MetricsBox
      title={"Who you've interacted with"}
      tooltipPlacement={"right"}
      tooltipText={
        "The proportion of profiles you have interacted with in Linkedin"
      }
      height="100%"
      headers={{
        preSection: renderPreSection(),
      }}
      {...props}
    >
      <Stack
        height={"100%"}
        width={"100%"}
        paddingInline={1}
        justifyContent={"center"}
        alignItems={"center"}
        paddingTop={1}
      >
        <Chart
          type="donut"
          options={options}
          series={[data, percentageDistractions]}
          width={280}
        />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={0.5}
        pt={2}
        pb={1}
      >
        <Typography fontSize={".75rem"} color="#0A72C3">
          What do these mean?
        </Typography>
        <Tooltip
          tooltipSx={{
            maxWidth: "12rem",
            paddingInline: 1.5,
          }}
          tooltipText={
            <Stack gap={2.5}>
              <Box>
                <Typography color="#FFF" fontSize={".875rem"}>
                  Monitored prospects
                </Typography>
                <Typography color="#839BB0" fontSize={".875rem"}>
                  LinkedIn profiles you have saved in Engage AI to monitor
                  closely.
                </Typography>
              </Box>
              <Box>
                <Typography color="#FFF" fontSize={".875rem"}>
                  Distractions
                </Typography>
                <Typography color="#839BB0" fontSize={".875rem"}>
                  Any random posts or non-monitored prospects you’ve engaged
                  with on your LinkedIn newsfeed that might not align with your
                  outreach strategy.
                </Typography>
              </Box>
            </Stack>
          }
          placement={"right"}
        >
          <HelpIcon sx={{ color: "#0A72C3", width: 18, height: 18 }} />
        </Tooltip>
      </Stack>
    </MetricsBox>
  );
};
