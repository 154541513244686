import { createContext, useContext, useState } from "react";
import UnsubscribeModal from "./UnsubscribeModal";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const openModal = () => {
    setStep(1);
    setFormData({});
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));
  const updateFormData = (newData) =>
    setFormData((prev) => ({ ...prev, ...newData }));

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        step,
        nextStep,
        prevStep,
        formData,
        updateFormData,
      }}
    >
      {children}
      <UnsubscribeModal />
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
