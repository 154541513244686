import { Stack } from "@mui/material";

export const ShadowBox = (props) => {
  const { children, ...restProps } = props;
  // ====================== RETURN
  return (
    <Stack
      boxShadow={"0px 4px 24px 0px rgba(90, 113, 130, 0.15);"}
      borderRadius={".875rem"}
      direction={"column"}
      paddingBlock={3}
      {...restProps}
    >
      {children}
    </Stack>
  );
};
