import { CircularProgress, Button as MUIButton } from "@mui/material";
export const Button = (props) => {
  const {
    children,
    startIcon,
    endIcon,
    variant = "contained",
    size = "medium",
    loading = false,
    ...restProps
  } = props;

  const renderLoading = () => {
    if (loading) return <CircularProgress color="inherit" size={20} />;
    return children;
  };

  if (variant === "text-animated") {
    return (
      <MUIButton
        {...restProps}
        variant={"text"}
        size={size}
        style={{
          borderRadius: "8px",
          paddingBlock: ".4rem",
          paddingInline: loading ? "4rem" : 0,
          transition: "all 0.3s ease-in-out",
          textTransform: "none",
          ...restProps?.style,
        }}
        sx={{
          "&:hover": {
            px: "1rem !important",
          },
        }}
        startIcon={startIcon}
        endIcon={endIcon}
        fontSize={"0.875rem"}
        fontWeight={500}
      >
        {renderLoading()}
      </MUIButton>
    );
  }

  return (
    <MUIButton
      {...restProps}
      variant={variant}
      size={size}
      style={{
        borderRadius: "8px",
        paddingBlock: ".4rem",
        paddingInline: loading ? "4rem" : "1rem",
        textTransform: "none",
        ...restProps?.style,
      }}
      startIcon={loading ? null : startIcon}
      endIcon={endIcon}
      fontSize={"0.875rem"}
      fontWeight={500}
      textTransform="none"
    >
      {renderLoading()}
    </MUIButton>
  );
};

export default Button;
