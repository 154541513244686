import { Box, Divider, Stack, Typography } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import TuneIcon from "@mui/icons-material/Tune";
import GroupsIcon from "@mui/icons-material/Groups";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import SupportIcon from "@mui/icons-material/Support";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import parse from "html-react-parser";
import StarIcon from "@mui/icons-material/Star";
import Button from "../../Button";
import { planMap } from "../constant";
import { mixpanelEvent } from "../../../Utils/mixpanelUtils";
import { useGlobalContext } from "../../../Context/GlobalContext";

export const PricingPlan = (props) => {
  const { plan, currentTab, stripePortalSessionUrl } = props;

  const {
    plan: { eai },
    isPayingUser,
  } = useGlobalContext();

  const userPlan = planMap?.[eai?.plan];
  const isPromoted = plan?.["Promoted"];
  const isFree = plan?.["Plan"] === "Free";
  const features = plan?.["Features"];
  const currentPlan = plan?.["Plan"];
  const priceMap = {
    monthly: plan?.["Monthly Price"],
    quarterly: plan?.["Quarterly Price"],
    annual: plan?.["Annual Price"],
  };

  const iconColor = isFree ? "#415058" : "#0A72C3";

  const levelMap = {
    Free: 0,
    Starter: 1,
    Pro: 2,
    Elite: 3,
  };

  const icons = [
    ChatIcon,
    LightbulbIcon,
    TuneIcon,
    GroupsIcon,
    EventRepeatIcon,
    SupportIcon,
    SupportAgentIcon,
  ];
  const featuresIcon = [
    ...icons.map((Icon, index) => (
      <Icon key={index} sx={{ color: iconColor }} />
    )),
    <StarIcon key="star" sx={{ color: "#EAAB3E" }} />,
  ];

  const onHandleClick = () => {
    if (isPayingUser) {
      window.open(stripePortalSessionUrl, "_blank");
    } else {
      window.open(plan?.["Upgrade link"], "_blank");
    }

    mixpanelEvent("plan_selected", {
      plan_name: plan?.["Plan"],
      plan_price: `$${plan?.["Monthly Price"]}`,
      plan_duration: currentTab,
      screen_name: "Billing Page",
    });
  };

  const renderPrice = (amount) => {
    if (amount === 0 || amount === "0") return "Free";
    return `$ ${amount}`;
  };

  const renderFeatures = (text, index) => {
    return (
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        {featuresIcon[index]}
        <Typography>{parse(text)}</Typography>
      </Stack>
    );
  };

  const renderFooter = () => {
    if (userPlan === currentPlan) {
      return (
        <Box
          color="#0A72C3"
          fontWeight={600}
          backgroundColor="rgba(10, 114, 195, 0.10)"
          py={1}
          px={3}
          borderRadius={"8px"}
          mt={2}
          textAlign={"center"}
          width={"fit-content"}
          alignSelf={"center"}
          justifySelf={"center"}
        >
          Current Plan
        </Box>
      );
    }
    if (isFree) return null;
    return (
      <Box width={"100%"} pt={2}>
        <Button
          style={{
            width: "100%",
            border: "2px solid #0A72C3",
            fontWeight: 600,
          }}
          variant="outlined"
          onClick={onHandleClick}
        >
          {levelMap[userPlan] > levelMap[currentPlan] ? "Downgrade" : "Upgrade"}
        </Button>
      </Box>
    );
  };

  return (
    <Stack
      justifyContent={"space-between"}
      gridColumn={"span 1"}
      height="100%"
      px={3}
      py={4}
      backgroundColor={!isFree ? "#FFF" : "transparent"}
      borderRadius={!isFree ? "14px" : "0"}
      boxShadow={
        !isFree
          ? "0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
          : "none"
      }
      position={"relative"}
    >
      {isPromoted && (
        <Box
          position={"absolute"}
          border="1px solid #EAAB3E"
          borderRadius={"10px"}
          p={0.5}
          px={1.5}
          backgroundColor="#FEFBF5"
          boxShadow={"0px 4px 4px 0px rgba(234, 171, 62, 0.12);"}
          top={-16}
          left={0}
          right={0}
          marginLeft={"auto"}
          marginRight={"auto"}
          color="#EAAB3E"
          width={"fit-content"}
          fontWeight={500}
        >
          👇 Our Best Seller
        </Box>
      )}
      <Stack>
        <Stack pb={2} gap={1}>
          <Typography color="#415058" fontWeight={700} fontSize={"1.15rem"}>
            {plan?.Plan}
          </Typography>
          <Typography fontSize={"2rem"} color="#1F292E" fontWeight={700}>
            {renderPrice(priceMap[currentTab])}
            {!isFree && (
              <span
                style={{
                  color: "#415058",
                  fontWeight: 500,
                  fontSize: ".875rem",
                }}
              >
                {" "}
                / month
              </span>
            )}
          </Typography>
        </Stack>
        <Divider />
        <Stack py={2} pt={3} gap={3}>
          {features.map((feature, index) => renderFeatures(feature, index))}
        </Stack>
      </Stack>
      <Box width={"100%"}>{renderFooter()}</Box>
    </Stack>
  );
};
