import { Button, styled } from "@mui/material";
import { Link } from "react-router-dom";

const CommonButtonStyles = styled(Button)`
  font-family: Inter;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  text-transform: Capitalize;
  border-radius: 10px;
  padding-block: 8px;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(CommonButtonStyles)`
  background: #0A72C3;
  color: white;
  &:hover,
  &:active {
    background: #0A72C3;
  }

  &:disabled {
    background: #e6e9ec;
    cursor: not-allowed;
  }
`;

export const SecondaryButton = styled(CommonButtonStyles)`
  background: #e6e9ec;
  color: #000;
  &:hover {
    background: #f0f2f5;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: white;
  &:hover {
    color: white;
    text-decoration: none;
  }
`;
