import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { ShadowBox } from "../ShadowBox";
import GroupSearchIcon from "../../../Images/Icons/group-search.svg";
import { isEmpty, times } from "lodash";
import GroupsIcon from "@mui/icons-material/Groups";
import { EmptyDataHolder } from "./EmptyDataHolder";
import { NextStepCard } from "./NextStepCard";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../Store/Store";
import toast from "react-hot-toast";
import { GetSuggestedNextStep } from "../../../Services/AdminDashboardService";
import { useNavigate } from "react-router-dom";

export const SuggestedNextStepContainer = () => {
  const [suggestedData, setSuggestionsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [state] = useContext(Context);
  const navigate = useNavigate();
  const user = state?.user?.data;
  const userId = user?.id;
  const isPayingUser = user?.group !== "Engage AI";

  // ====================== EFFECTS
  useEffect(() => {
    const fetchSuggestedNextStep = async () => {
      if (!userId) return;
      try {
        const res = await GetSuggestedNextStep(userId);
        const data = await res.json();
        setSuggestionsData({ ...data });
      } catch (error) {
        toast.error(
          "Error fetching suggested next steps. Please try again later"
        );
      } finally {
        setLoading(false);
      }
    };
    fetchSuggestedNextStep();
  }, [userId]);

  // ====================== VARIABLES
  const promptAction = suggestedData?.promptAction;
  const interaction = suggestedData?.interaction;
  const connection = suggestedData?.connection;
  const profileView = suggestedData?.conversation;

  const isDataEmpty =
    isEmpty(interaction) && isEmpty(connection) && isEmpty(profileView);
  const isPromptActionEmpty = promptAction && isDataEmpty;

  const renderContent = () => {
    if (loading) {
      return (
        <Box
          display={"grid"}
          gridTemplateColumns={"repeat(3, 1fr)"}
          height={"100%"}
          gap={3}
        >
          {times(3, (index) => (
            <Box
              gridColumn={"span 1"}
              height={"100%"}
              width={"100%"}
              minWidth={0}
              key={index}
            >
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "1rem" }}
                width={"100%"}
                height={400}
              />
            </Box>
          ))}
        </Box>
      );
    }

    if (isPromptActionEmpty) {
      return (
        <ShadowBox>
          <EmptyDataHolder
            icon={
              <GroupsIcon sx={{ width: 150, height: 150, color: "#C8CDD0" }} />
            }
            onClick={() => {
              navigate("/NurtureProspects/prospects");
            }}
            title="No Prospects added yet"
            description={
              <>
                <Typography fontSize={"0.875rem"}>
                  To see data here, start adding and monitoring
                </Typography>
                <Typography fontSize={"0.875rem"}>some prospects.</Typography>
              </>
            }
          />
        </ShadowBox>
      );
    }
    if (isDataEmpty) {
      return (
        <ShadowBox>
          <EmptyDataHolder
            icon={
              <img
                src={GroupSearchIcon}
                width={130}
                height={130}
                alt="group-search-icon"
              />
            }
            onClick={() => {
              navigate("/NurtureProspects/prospects");
            }}
            title={"Start monitoring prospects and check back later"}
            description={
              <>
                <Typography fontSize={"0.875rem"}>
                  Monitor your prospects and track interactions to build
                  stronger buisiness
                </Typography>
                <Typography fontSize={"0.875rem"}>
                  relationships. We need a 1-7 days to collect enough data to
                  display.
                </Typography>
              </>
            }
          />
        </ShadowBox>
      );
    }

    return (
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: {
            xs: "1fr",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          },
        }}
        height={"100%"}
        gap={3}
      >
        <Box gridColumn={"span 1"} height={"100%"} width={"100%"} minWidth={0}>
          <NextStepCard
            data={interaction}
            type="interaction"
            onClick={() => {
              navigate("/NurtureProspects/prospects");
            }}
          />
        </Box>
        <Box gridColumn={"span 1"} height={"100%"} width={"100%"} minWidth={0}>
          <NextStepCard
            data={connection}
            type="connection"
            onClick={() => {
              navigate("/NurtureProspects/prospects");
            }}
          />
        </Box>
        <Box gridColumn={"span 1"} height={"100%"} width={"100%"} minWidth={0}>
          <NextStepCard
            data={profileView}
            type="profileView"
            onClick={() => {
              navigate("/NurtureProspects/prospects");
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Stack gap={2.5} pt={1}>
      <Stack>
        <Typography color="#415058" fontWeight={600} fontSize={"1rem"}>
          Suggested next steps
        </Typography>
        <Typography color="#707981" fontSize=".875rem">
          Recommended actions to bring you closer with your monitored prospects
          based on their engagement with you.{" "}
          <span style={{ fontWeight: 600 }}>
            {isPayingUser ? "Updated daily" : "Updated weekly"}.
          </span>
        </Typography>
      </Stack>
      {renderContent()}
    </Stack>
  );
};
