import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Button from "../../Button";
import StripePortalSessionService from "../../../Services/StripeService";
import { useState } from "react";
import { Header } from "../../common/Header";
export const Heading = () => {
  // ====================== STATE
  const [historyLoading, setHistoryLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  // ====================== HANDLER
  const onHandleClick = async (target) => {
    if (target === "payment") {
      setPaymentLoading(true);
    }
    if (target === "history") {
      setHistoryLoading(true);
    }

    try {
      const result = await StripePortalSessionService();
      const stripePortalSession = await result.json();
      if (stripePortalSession?.data?.url) {
        window.open(stripePortalSession.data.url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching current plan:", error);
    } finally {
      setHistoryLoading(false);
      setPaymentLoading(false);
    }
  };

  // ====================== RETURN
  return (
    <Header
      icon={<CreditCardIcon />}
      title="Billing"
      subTitle="Change your plans according to your needs."
      rightView={
        <>
          <Button
            startIcon={<CreditCardIcon />}
            variant="outlined"
            style={{ border: "2px solid #0A72C3" }}
            loading={paymentLoading}
            disabled={paymentLoading}
            onClick={() => onHandleClick("payment")}
          >
            Payment Method
          </Button>
          <Button
            variant="contained"
            startIcon={<ReceiptLongIcon />}
            style={{
              paddingBlock: ".5rem",
            }}
            loading={historyLoading}
            disabled={historyLoading}
            onClick={() => onHandleClick("history")}
          >
            Payment History
          </Button>
        </>
      }
    />
  );
};
