export function GetCTACard() {
  return fetch(
    "https://f7fyokehipcbplsrdemotqihlq0siheu.lambda-url.ap-southeast-2.on.aws"
  );
}

export function GetBillingPlan(email) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({ email }),
  };

  return fetch(
    "https://r5na2baes7pfct3qjuacqqsgea0quhbm.lambda-url.ap-southeast-2.on.aws/",
    options
  );
}

export function GetPricingPlan() {
  return fetch(
    "https://arulyqoerg7kcbk24ytvpzdwki0itpvy.lambda-url.ap-southeast-2.on.aws/"
  );
}

export function SubmitCancelReason({ reason, note }) {
  const options = {
    method: "POST",
    body: JSON.stringify({
      timestamp: new Date().toISOString(),
      reason,
      note,
    }),
  };

  return fetch(
    "https://hooks.zapier.com/hooks/catch/8876124/2wk6la3/",
    options
  );
}

export const GetPaymentHistory = (email) => {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      email
    }),
  };

  return fetch(
    "https://sej6bin74d2vensmpscty3thtq0mcuqa.lambda-url.ap-southeast-2.on.aws?filter_by=current",
    options
  );
};

const BillingAPI = {
  GetCTACard,
  GetBillingPlan,
  GetPricingPlan,
  SubmitCancelReason,
};

export default BillingAPI;
