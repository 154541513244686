import Button from "../../Button";

export const ModalCancelActionButton = (props) => {
  const { children, ...restProps } = props;
  return (
    <Button
      sx={{
        textTransform: "none",
        backgroundColor: "#D61F1F",
        color: "#FFF",
        fontWeight: 600,
        "&:hover": {
          backgroundColor: "#C70000",
        },
        "&:disabled": {
          background: "#D61F1F",
          opacity: 0.5,
          color: "#FFF",
        },
      }}
      style={{
        paddingBlock: ".5rem",
      }}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export const ModalPrimaryActionButton = (props) => {
  const { children, ...restProps } = props;
  return (
    <Button
      sx={{
        textTransform: "none",
        backgroundColor: "#0A72C3",
        color: "#FFF",
        fontWeight: 600,
        "&:hover": {
          backgroundColor: "#085A99",
        },
      }}
      style={{
        paddingBlock: ".5rem",
      }}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export const ModalPlainButton = (props) => {
  const { children, ...restProps } = props;
  return (
    <Button
      sx={{
        textTransform: "none",
        color: "#415058",
        fontWeight: 600,

        "&:hover": {
          backgroundColor: "#F9F9F9",
        },
      }}
      variant="text"
      style={{
        paddingBlock: ".5rem",
      }}
      {...restProps}
    >
      {children}
    </Button>
  );
};
