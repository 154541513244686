import { Stack, Typography } from "@mui/material";
import { ShadowBox } from "./ShadowBox";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "./Tooltip";
import PercentageChip from "./PercentageChip";

export const MetricsBox = (props) => {
  const {
    children,
    title,
    tooltipText,
    tooltipPlacement,
    headers,
    ...restProps
  } = props;

  // ====================== VARIABLES
  const { preSection, count, percentage } = headers || {};

  // ====================== RETURN
  return (
    <ShadowBox {...restProps}>
      <Stack
        direction={"row"}
        gap={0.8}
        alignItems={"center"}
        paddingInline={3}
      >
        <Typography color="#415058" fontWeight={600} fontSize={"1rem"}>
          {title}
        </Typography>
        <Tooltip tooltipText={tooltipText} placement={tooltipPlacement}>
          <InfoIcon
            style={{
              color: "#707981",
            }}
          />
        </Tooltip>
      </Stack>
      <Stack direction={"row"} width={"100%"}>
        {preSection && preSection}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          px={3}
        >
          {count !== undefined && count !== null && (
            <Typography
              textAlign={"left"}
              color="#415058"
              fontWeight={500}
              fontSize={"2rem"}
            >
              {count}
            </Typography>
          )}
          {percentage !== undefined && percentage !== null && (
            <PercentageChip value={percentage} />
          )}
        </Stack>
      </Stack>
      {children}
    </ShadowBox>
  );
};
