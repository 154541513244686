import { Stack, Avatar, Typography } from "@mui/material";

function stringAvatar(name) {
  return {
    children: `${name.split(" ")?.[0]?.[0]}${name.split(" ")?.[1]?.[0]}`,
  };
}

export const PodSessionCard = (props) => {
  const {
    pod,
    isCompletedEngage,
    rightItem,
    rightItemContainerProps,
    onHandleComplete,
    ...restProps
  } = props;

  const isEngaged = pod?.is_complete;

  // ====================== RETURN
  return (
    <Stack
      border="1px solid #E0E2E4"
      paddingInline={2}
      paddingBlock={2}
      overflow={"hidden"}
      direction={"row"}
      borderRadius={isCompletedEngage ? ".875rem" : "1.1rem"}
      justifyContent={"space-between"}
      gap={8}
      style={{
        background: isCompletedEngage
          ? "linear-gradient(90.46deg, rgba(10, 114, 195, 0) 0%, rgba(10, 114, 195, 0.05) 100%)"
          : "#FFF",
      }}
      {...restProps}
    >
      <Stack
        direction={"row"}
        gap={2}
        alignItems="center"
        justifyContent={"space-between"}
        overflow={"hidden"}
      >
        <Avatar
          alt={pod?.name}
          {...stringAvatar(pod?.name || "U")}
          sx={{
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#1975d1",
          }}
        />
        <Stack
          direction={"column"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          gap={0.5}
        >
          <Typography color="#1F292E" fontWeight={600} fontSize={"1rem"}>
            {pod?.name}
          </Typography>
        </Stack>
      </Stack>
      {rightItem && (
        <Stack
          direction={"row"}
          gap={2}
          alignItems="center"
          {...rightItemContainerProps}
        >
          {rightItem(isEngaged, pod)}
        </Stack>
      )}
    </Stack>
  );
};
