import { Box } from "@mui/material";
import { CTACard } from "./components/CTACard";
import CurrentPlanCard from "./components/CurrentPlan";

export const CurrentPlan = () => {
  // ====================== RETURN
  return (
    <Box
      display="grid"
      gridTemplateColumns={"repeat(12, 1fr)"}
      gap={3}
      height="100%"
      gridAutoRows={"1fr"}
    >
      <CurrentPlanCard />
      <CTACard />
    </Box>
  );
};

export default CurrentPlan;
