import { Box, Stack, Typography } from "@mui/material";
import { MetricsBox } from "../MetricsBox";
import GroupsIcon from "@mui/icons-material/Groups";
import Button from "../../Button";
import Chart from "react-apexcharts";
import { constructGraphData } from "../utils";
import { useNavigate } from "react-router-dom";
const options = {
  chart: {
    parentHeightOffset: 0,
    background: "transparent !important",
    type: "line",
    zoom: { enabled: false },
    toolbar: { show: false },
  },
  legend: {
    show: false,
  },
  xaxis: {
    tickAmount: 2,
    offsetY: 8,
    labels: {
      rotate: 0,
      style: {
        colors: "#707981",
        fontSize: "14px",
      },
      formatter: (value) => {
        const date = new Date(value);
        return `${date.toLocaleString("default", {
          month: "short",
        })} ${date.getDate()}`;
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      color: "#D7D7DD",
    },
  },
  yaxis: {
    labels: {
      min: 0,
      style: {
        colors: "#707981",
        fontSize: "14px",
      },
      tickAmount: 5,
      formatter: function (value) {
        return value.toFixed(0);
      },
    },
  },
  stroke: {
    curve: "straight",
    width: 3,
  },
  markers: {
    show: false,
  },
  colors: ["#0A72C3", "#FF9D00", "#64BE37"],
};
export const PropsectResponseChart = (props) => {
  const { data } = props;

  const navigate = useNavigate();
  // ====================== VARIABLES
  const total = data?.total;
  const percentage = data?.percentageChange;
  const engagementTrend = data?.prospectsEngagementTrend;
  const commentingTrend = data?.prospectsCommentingTrend;
  const profileViewTrend = data?.prospectsProfileViewTrend;
  const trend = [
    {
      name: "Engaged with your comment",
      value: { ...engagementTrend },
      color: "#0A72C3",
    },
    {
      name: "Commented on your post",
      value: { ...commentingTrend },
      color: "#FF9D00",
    },
    {
      name: "Viewed your profile",
      value: { ...profileViewTrend },
      color: "#64BE37",
    },
  ];
  // ====================== RENDER
  const renderLabels = () => {
    return trend.map((i) => {
      return (
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Box bgcolor={i.color} borderRadius={"100%"} width={15} height={15} />
          <Typography color="#707981" fontSize={".875rem"}>
            {i.name}
          </Typography>
        </Stack>
      );
    });
  };
  const renderContent = () => {
    if (total === 0) {
      return (
        <Stack
          alignItems={"center"}
          gap={1}
          py={1}
          justifyContent={"center"}
          height={"100%"}
        >
          <GroupsIcon sx={{ width: 150, height: 150, color: "#C8CDD0" }} />
          <Stack alignItems={"center"} color="#415058" gap={1}>
            <Typography color="#415058" fontSize={"1rem"} fontWeight={600}>
              No prospects added yet
            </Typography>
            <Box textAlign={"center"} color="#707981">
              <Typography fontSize={".875rem"}>
                To see data here, start adding and
              </Typography>
              <Typography fontSize={".875rem"}>
                monitoring some prospects
              </Typography>
            </Box>
          </Stack>
          <Box pt={1}>
            <Button
              style={{
                fontWeight: "bold",
                paddingInline: "1.5rem",
                backgroundColor: "#0A72C3",
              }}
              onClick={() => navigate("/NurtureProspects/prospects")}
            >
              Add a prospect
            </Button>
          </Box>
        </Stack>
      );
    }
    return (
      <Stack
        justifyContent={"space-between"}
        direction={"column"}
        pt={1}
        pb={1}
        height={"100%"}
        paddingInline={1}
      >
        <Chart
          type="line"
          options={options}
          series={trend.map((i) => ({
            name: i.name,
            data: constructGraphData(i.value),
          }))}
          width={"100%"}
          height={190}
        />
        <Stack direction={"column"} gap={1} px={4}>
          {renderLabels()}
        </Stack>
      </Stack>
    );
  };

  // ====================== RETURN
  return (
    <MetricsBox
      title="Responses from prospects"
      tooltipText="The engagement activity from your saved prospects."
      tooltipPlacement={"top"}
      headers={{
        count: total > 0 ? total : null,
        percentage,
      }}
      height="100%"
      width="100%"
      paddingBottom={3}
      {...props}
    >
      {renderContent()}
    </MetricsBox>
  );
};
