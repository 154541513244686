import { Header } from "../common/Header";

import GroupsIcon from "@mui/icons-material/Groups";
import { ContainerWrapper } from "../common/ContainerWrapper";
import { Box, Stack, Typography } from "@mui/material";
import Button from "../Button";
import { PodCardList } from "./PodCardList";
import CommunityBanner from "../../Images/CommunityBanner.png";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate } from "react-router-dom";

export const PodCommunity = () => {
  const onHandleClick = () => {
    window.open("https://pod.engage-ai.co/en", "_blank").focus();
  };
  const redirect = useNavigate();
  // ====================== RETURN
  return (
    <ContainerWrapper>
      <Header
        title="Community"
        subTitle="Discover and join engagement sessions to boost your reach and grow your professional network."
        icon={<GroupsIcon />}
        rightView={
          <Button
            style={{
              border: "2px solid #0A72C3",
              fontWeight: 600,
            }}
            variant="outlined"
            startIcon={<HistoryIcon sx={{ width: 22, height: 22 }} />}
            onClick={() => redirect("/PodCommunity/Post/History")}
          >
            Posts history
          </Button>
        }
      />
      <Stack
        mt={4}
        direction={"row"}
        justifyContent={"space-between"}
        border={"1px solid #E0E2E4"}
        borderRadius={"1rem"}
        sx={{
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #0A72C3;",
        }}
        py={3}
        px={4}
        color="#FFF"
        position={"relative"}
        overflow={"hidden"}
      >
        <Box width="50%">
          <Stack gap={1} pb={3.5}>
            <Typography fontWeight={600} fontSize={"1.25rem"}>
              Linkedin Engagement Community
            </Typography>
            <Typography color="#D0D0D0">
              Take part in a community session where you'll interact with
              others' LinkedIn posts and receive meaningful engagement in return
              for yours.
            </Typography>
          </Stack>
          <Button onClick={onHandleClick}>Documentation & tips</Button>
        </Box>
        <img
          src={CommunityBanner}
          alt="Community Banner"
          height={"100%"}
          style={{
            mixBlendMode: "luminosity",
            position: "absolute",
            right: 35,
            top: 0,
          }}
        />
      </Stack>
      <PodCardList />
    </ContainerWrapper>
  );
};

export default PodCommunity;
