import { isEmpty, map } from "lodash";
import { ShadowBox } from "../ShadowBox";
import { EmptyDataHolder } from "./EmptyDataHolder";
import GroupsIcon from "@mui/icons-material/Groups";
import { Stack, Typography } from "@mui/material";
import { NextStepList } from "./NextStepList";

const typeMap = {
  interaction: {
    cardTitle: "Interact with their latest post",
    title: "Add new prospects",
    description: (
      <>
        <Typography fontSize={"0.875rem"}>
          We recommend to keep expanding your
        </Typography>
        <Typography fontSize={"0.875rem"}>
          network with new prospects
        </Typography>
      </>
    ),
    buttonText: "Add new prospects",
  },
  connection: {
    cardTitle: "Make a connection",
    title: "Keep engaging with your prospects",
    description: (
      <>
        <Typography fontSize={"0.875rem"}>
          We recommend to increase engagement
        </Typography>
        <Typography fontSize={"0.875rem"}>
          with your prospects before connecting.
        </Typography>
      </>
    ),
    buttonText: "See my prospects",
  },
  profileView: {
    cardTitle: "Start a conversation",
    title: "Keep engaging with your prospects",
    description: (
      <>
        <Typography fontSize={"0.875rem"}>
          We recommend to make a connection first
        </Typography>
        <Typography fontSize={"0.875rem"}>
          before starting a conversation
        </Typography>
      </>
    ),
    buttonText: "See my prospects",
  },
};

export const NextStepCard = (props) => {
  const { data, onClick, type } = props;
  const renderContent = () => {
    if (isEmpty(data)) {
      return (
        <EmptyDataHolder
          icon={
            <GroupsIcon sx={{ width: 100, height: 100, color: "#C8CDD0" }} />
          }
          title={typeMap[type].title}
          description={typeMap[type].description}
          onClick={onClick}
          buttonText={typeMap[type].buttonText}
          width="100%"
        />
      );
    }
    return (
      <Stack gap={3.5}>
        {map(data, (item, index) => {
          return <NextStepList key={index} data={item} />;
        })}
      </Stack>
    );
  };
  return (
    <ShadowBox px={3} gap={2} height={"100%"} minHeight={"24rem"} width="100%">
      <Typography color="#707981" fontWeight={600}>
        {typeMap[type].cardTitle}
      </Typography>
      {renderContent()}
    </ShadowBox>
  );
};
