// ------------------------------------------------------------------------------
// IMPORTS
// ------------------------------------------------------------------------------
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm, Form } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";

import RegisterNewUserService from "../../Services/RegisterNewUserService";
import LoginService from "../../Services/LoginService";
import { Context } from "../../Store/Store";

import { handleSuccessLogin } from "./Common/Functions";
import { StyledTextField } from "./Common/TextField";
import { commonStyles } from "./Common/CommonStyles";
import StyledButton from "../NurtureProspects/ReusableComponents/Button";
import { StyledLink } from "../NurtureProspects/ReusableComponents/StyledLink";

import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { FcGoogle } from "react-icons/fc";
import { MdErrorOutline } from "react-icons/md";
import { MdLightbulbOutline } from "react-icons/md";

// ------------------------------------------------------------------------------
// STYLES
// ------------------------------------------------------------------------------
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "48px",

    "& h1": {
      fontSize: "32px",
      fontWeight: "600",
      fontFamily: "Inter, sans-serif",
      color: "rgba(65, 80, 88, 1)",
      margin: "0",
    },
  },
  suggestionbox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "12px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#053a64",
    border: "1px solid rgba(196, 221, 241, 1)",
    background: "rgba(10, 114, 195, 0.05)",
    borderRadius: "8px",
    alignSelf: "stretch",
    fontFamily: "Inter, sans-serif",

    "& span": {
      textAlign: "left",
    },

    "& svg": {
      height: "24px",
      width: "24px",
      color: "rgba(10, 114, 195, 1)",
    },
  },
  form: {
    minWidth: "430px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",

    "& #googleAuth": {
      position: "relative",
      "& > button": {
        width: "100%",
        display: "flex !important",
        alignitems: "center !important",
        justifyContent: "center !important",
        gap: "12px !important",
        borderRaidus: "8px !important",
        fontFamily: "Inter !important",
        fontWeight: "500 !important",
        fontSize: "16px !important",
        padding: "12px 24px !important",
        color: "rgba(65, 80, 88, 1) !important",
        border: "1px solid rgba(224, 226, 228, 1) !important",
        textTransform: "none",
        lineHeight: "100%",
        transition: "all .3s",
        height: "48px",
        boxShadow: "none !important",
        margin: "0 !important",
        background: "rgba(255, 255, 255, 1) !important",

        "& > *": {
          margin: "0 !important",
          padding: "0 !important",
        },

        "&:hover": {
          background: "#fdfdfd !important",
        },

        "& svg": {
          height: "18px",
          width: "18px",
        },
      },
    },
  },
  separator: {
    position: "relative",
    textAlign: "center",
    "& span": {
      position: "relative",
      zIndex: "1",
      fontSize: "12px",
      fontWeight: "600",
      color: "rgba(65, 80, 88, 1)",
      paddingInline: "8px",
      background: "rgba(249, 251, 253, 1)",
    },
    "&::before": {
      content: '""',
      display: "inline-block",
      position: "absolute",
      top: "50%",
      left: "0",
      transform: "translateY(-50%)",
      height: "1px",
      width: "100%",
      background: "rgba(224, 226, 228, 1)",
    },
  },
};

// ------------------------------------------------------------------------------
// MAIN
// ------------------------------------------------------------------------------

function CreateAccount() {
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const context = useContext(Context);

  // -------------------------------------------------------------------------------------
  // STATE
  // -------------------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [emailError, setEmailError] = useState(null);

  // -------------------------------------------------------------------------------------
  // React Hook Form
  // -------------------------------------------------------------------------------------
  const formDefaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const {
    control: formController,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: formDefaultValues,
    values: formDefaultValues,
  });

  // -------------------------------------------------------------------------------------
  // HANDLERS
  // -------------------------------------------------------------------------------------

  // handleSuccessLogin
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const response = await LoginService({
        accessToken: tokenResponse.access_token,
      });
      const json = await response.json();

      if (response.status === 200) {
        handleSuccessLogin(json, context, true);
        navigate("/Home");
      } else {
        setSubmitResponse("Something went wrong. Please try again.");
      }
    },
  });

  const handleCreateAccount = async (data) => {
    try {
      setIsLoading(true);

      let group = "Engage AI";
      const queryParams = new URLSearchParams(window.location.search);
      const source = queryParams.get("source");
      source === "engageai" && (group = "Engage AI");

      const { firstName, lastName, email } = data;
      let password = null;

      const response = await RegisterNewUserService(
        firstName,
        lastName,
        email,
        password,
        group
      );
      const json = await response.json();

      if (response.status === 200) {
        window.fpr("referral", { email });
        navigate("/onboarding/EmailVerification", { state: { email } });
      } else {
        if (json?.message === "Account already exists")
          setEmailError("This email already exists.");
        else throw new Error(json?.message);
      }
    } catch (err) {
      setSubmitResponse("Something went wrong. Please contact support.");
    } finally {
      setIsLoading(false);
    }
  };

  // -------------------------------------------------------------------------------------
  // COMPONENT RENDERING
  // -------------------------------------------------------------------------------------

  return (
    <Box sx={styles.container}>
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <h1>Create account</h1>
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "rgba(65, 80, 88, 1)",
          }}
        >
          Already registered ? <StyledLink to="/login">Login</StyledLink>
        </span>
      </Box>
      <Form
        control={formController}
        onSubmit={handleSubmit(handleCreateAccount)}
      >
        <Box sx={{ ...styles.form, ...(smallDisplay && { minWidth: "100%" }) }}>
          <Box sx={styles.suggestionbox}>
            <MdLightbulbOutline />
            <span>
              We recommend using your <strong>work email</strong> - it keeps
              work and life separate.
            </span>
          </Box>
          <Box id="googleAuth">
            <Button onClick={() => handleGoogleLogin()}>
              <FcGoogle />
              <span>Continue with Google</span>
            </Button>
          </Box>
          <Box sx={styles.separator}>
            <span>Or sign up with e-mail</span>
          </Box>
          {!!submitResponse && (
            <Box sx={{ ...commonStyles.globalError, maxWidth: "530px" }}>
              <MdErrorOutline />
              <span>{submitResponse}</span>
            </Box>
          )}

          <Box
            display="flex"
            flexDirection={smallDisplay ? "column" : "row"}
            justifyContent="space-between"
            gap="16px"
          >
            <Box sx={commonStyles.formGroup}>
              <FormLabel htmlFor="firstName">First name</FormLabel>
              <Controller
                name="firstName"
                control={formController}
                rules={{ required: "required." }}
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    id="firstName"
                    variant="outlined"
                    placeholder="First Name"
                    error={!!formErrors?.firstName}
                    sx={{
                      ...(!!formErrors?.firstName && {
                        "& input": {
                          color: "var(--color-red)",
                          borderColor: "var(--color-red)",
                        },
                      }),
                    }}
                  />
                )}
              />
              {!!formErrors?.firstName && (
                <p style={{ ...commonStyles.errMsg, alignSelf: "flex-end" }}>
                  {formErrors?.firstName?.message}
                </p>
              )}
            </Box>
            <Box sx={commonStyles.formGroup}>
              <FormLabel htmlFor="lastName">Last name</FormLabel>
              <Controller
                name="lastName"
                control={formController}
                rules={{ required: "required." }}
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    id="lastName"
                    variant="outlined"
                    placeholder="Last Name"
                    error={!!formErrors?.lastName}
                    sx={{
                      ...(!!formErrors?.lastName && {
                        "& input": {
                          color: "var(--color-red)",
                          borderColor: "var(--color-red)",
                        },
                      }),
                    }}
                  />
                )}
              />
              {!!formErrors?.lastName && (
                <p style={{ ...commonStyles.errMsg, alignSelf: "flex-end" }}>
                  {formErrors?.lastName?.message}
                </p>
              )}
            </Box>
          </Box>
          <Box sx={commonStyles.formGroup}>
            <FormLabel htmlFor="email">E-mail</FormLabel>
            <Controller
              id="email"
              name="email"
              control={formController}
              rules={{
                required: "required.",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                  message: "Invalid email address",
                },
                validate: (value) =>
                  !/\+/.test(value) || "Invalid email address",
              }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  id="email"
                  variant="outlined"
                  placeholder="Email"
                  error={!!formErrors?.email || !!emailError}
                  autoComplete="off"
                  sx={{
                    ...((!!formErrors?.email || emailError) && {
                      "& input": {
                        color: "var(--color-red)",
                        borderColor: "var(--color-red)",
                      },
                    }),
                  }}
                />
              )}
            />
            {(!!formErrors?.email || emailError) && (
              <p style={{ ...commonStyles.errMsg, alignSelf: "flex-end" }}>
                {emailError ? emailError : formErrors?.email?.message}
              </p>
            )}
          </Box>
          <Box sx={commonStyles.actions}>
            <StyledButton
              disabled={isLoading}
              sx={{
                "& span": {
                  color: "white !important",
                  width: "24px !important",
                  height: "24px !important",
                },
              }}
              type="submit"
            >
              {isLoading ? <CircularProgress /> : "Sign up"}
            </StyledButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
}

export default CreateAccount;
