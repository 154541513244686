import { Stack } from "@mui/material";
import { CurrentPlan } from "./CurrentPlan";
import Plan from "./Plan";
import { ModalProvider } from "./containers/ModalProvider";
import { Heading } from "./components/Header";
import { ContainerWrapper } from "../common/ContainerWrapper";

export const BillingPageV2 = () => {
  // ====================== RETURN
  return (
    <ModalProvider>
      <ContainerWrapper>
        <Stack direction={"column"} gap={4}>
          <Heading />
          <CurrentPlan />
          <Plan />
        </Stack>
      </ContainerWrapper>
    </ModalProvider>
  );
};

export default BillingPageV2;
