/*global chrome*/

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import moment from "moment";

import { Context } from "../Store/Store";
import CheckUserPlanService from "../Services/CheckUserPlan";
import refreshTokenService from "../Services/RefreshToken";

import { constants } from "../Utils/constants";
import { GetPaymentHistory } from "../Services/BillingService";
import { cancellationReasons } from "../Components/Account/constant";
import StripePortalSessionService from "../Services/StripeService";
import toast from "react-hot-toast";

const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useContext(Context);
  const { email } = state?.user?.data ?? {};

  const user = JSON.parse(localStorage.getItem("user"));
  const savedPlan = user?.group;

  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const [plan, setPlan] = useState({});
  const [isPayingUser, setIsPayingUser] = useState(false);
  const [stripePortalSessionUrl, setStripePortalSessionUrl] = useState("");

  const isPaymentFailed =
    cancellationReasons.includes(plan?.eai?.cancellation_reason) ||
    cancellationReasons.includes(plan?.pod?.cancellation_reason);

  const checkExtensionInstalled = () => {
    if (typeof chrome !== "undefined" && chrome?.runtime) {
      chrome.runtime.sendMessage(
        constants.EXTENSION_ID,
        { type: "hello" },
        (response) => {
          if (response && response.installed) {
            setIsExtensionInstalled(true);
          } else {
            setIsExtensionInstalled(false);
          }
        }
      );
    } else {
      setIsExtensionInstalled(false);
    }
  };

  const checkUserPlan = useCallback(() => {
    if (!email) return;
    CheckUserPlanService(email).then((res) => {
      res.json().then((json) => {
        if (json.Plan && savedPlan && json.Plan !== savedPlan) {
          // user plan has changed => log out user
          const lastLogin = localStorage.getItem("lastLogin");
          localStorage.clear();
          if (lastLogin !== null) {
            localStorage.setItem("lastLogin", lastLogin);
          }
          // clear state
          dispatch({
            type: "RESET_STATE",
          });
        }
      });
    });
  }, [email, savedPlan, dispatch]);

  const checkTokenExpiration = useCallback(() => {
    const date = moment().format("YYYY-MM-DD HH:mm:ss");
    const token_exp = localStorage.getItem("tokenExp");

    if (!token_exp) return;
    if (moment(date).isBefore(token_exp)) return;

    // token expired, get new token
    refreshTokenService().then((res) => {
      res.json().then((json) => {
        if (res.status === 200) {
          const { access, refresh } = json;
          const newExpire = moment()
            .add(29, "days")
            .format("YYYY-MM-DD HH:mm:ss");

          localStorage.setItem("token", access);
          localStorage.setItem("refreshToken", refresh);
          localStorage.setItem("tokenExp", newExpire);
        }
      });
    });
  }, []);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      if (!state?.user?.data?.email) return;
      try {
        const res = await GetPaymentHistory(state?.user?.data?.email);
        const data = await res.json();
        if (data?.plan.length === 0) {
          toast.error(
            "Error fetching payment history, please contact Admin for support"
          );
          return;
        }
        const plan = data?.plan;
        setIsPayingUser(data?.isPaying);
        setPlan({
          pod: plan.find((item) => item.planType === "Pod"),
          eai: plan.find((item) => item.planType === "EAI"),
        });
      } catch (error) {
        toast.error("Something went wrong, please try again later");
      }
    };
    fetchPaymentHistory();
  }, [state?.user?.data?.email]);

  useEffect(() => {
    const fetchStripePortalSession = async () => {
      try {
        const result = await StripePortalSessionService();
        const stripePortalSession = await result.json();
        const url = stripePortalSession?.data?.url;
        if (url) {
          setStripePortalSessionUrl(url);
        }
      } catch (error) {
        console.error("Error fetching current plan:", error);
      }
    };
    fetchStripePortalSession();
  }, []);

  useEffect(() => {
    checkExtensionInstalled();

    const interval = setInterval(() => {
      checkUserPlan();
      checkTokenExpiration();
    }, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, [checkUserPlan, checkTokenExpiration]);

  return (
    <GlobalContext.Provider
      value={{
        isExtensionInstalled,
        checkExtensionInstalled,
        plan,
        isPaymentFailed,
        stripePortalSessionUrl,
        isPayingUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
