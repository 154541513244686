import React, { useState } from "react";
//import { Context } from '../../Store/Store';
//import { useHistory } from "react-router-dom";
// import Spinner from "../Spinner/Spinner";
import FormInputAccount from "../Account/FormInputAccount";
import styles from "./AccountPage.module.css";
import { Box } from "@mui/material";

const AccountPage = () => {
  // const [loading, setIsLoading] = useState(false);
  // const [podDate, setPodDate] = useState();
  // const [account, setAccountValues] = useState([]);
  // const [tz, setTz] = useState();
  const [section, setSection] = useState("account");
  //const [trialEndDate, setTrialEndDate] = useState();
  //const [trialExpired, setTrialExpired] = useState(false);
  //let history = useHistory();

  const handleChange = (id) => {
    if (section === id) {
      setSection();
    } else {
      setSection(id);
    }
  };

  return (
    <div>
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          padding: "1.5rem 2.5rem",
          flexDirection: "column",
          background: "var(--color-grey-50)",
        }}
      >
        <div style={{ flex: "1" }}>
          {/* {loading ? <Spinner className="spinner-container" /> : ""} */}
          <div>
            <div
              onClick={() => handleChange("account")}
              className="row mt-2"
              style={{
                fontWeight: "700",
                fontSize: "24px",
                color: "#2F3EAC",
                lineHeight: "36px",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <Box className="headerText" mb="1.5rem">
                <Box display="flex" alignItems="center" gap="8px">
                  {section === "account" ? (
                    <img style={{ height: "16px", width: "16px" }} src="/images/minus.svg" alt=""></img>
                  ) : (
                    <img style={{ height: "16px", width: "16px" }} src="/images/add.svg" alt=""></img>
                  )}
                  <h2 style={{color:"#0A72C3"}}>My Account</h2>
                </Box>
              </Box>
            </div>

            {/** Account */}
            {section === "account" ? (
              <div className={`${styles["form-style"]}`}>
                <FormInputAccount />
              </div>
            ) : (
              ""
            )}

            {/** FAQ + T&C 
            <div onClick={() => handleChange("faq")} className='row mt-2' style={{fontWeight:"700", fontSize:"24px", color:"#2F3EAC", lineHeight:"36px", gap:"10px", cursor:"pointer"}}>
              {section === "faq" ? <img src='/images/minus.svg' alt=''></img> : <img src='/images/add.svg' alt=''></img>}
              <div>Terms and Conditions</div>
            </div>
            {section === 'faq' ? 
              <div style={{margin:"5px 22px"}}>
                <div><a href="https://filtpod.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></div>
                <div><a href="https://filtpod.com/privacy-policy-lha/" target="_blank" rel="noopener noreferrer">Privacy Policy - Hashtag Analytics Chrome Extension</a></div>
                <div><a href="https://filtpod.com/privacy-policy-lead-nurturing/" target="_blank" rel="noopener noreferrer">Privacy Policy - Engage</a></div>
              </div>
              :''
            }*/}

            {/** Contact Us 
            <div onClick={() => handleChange("contact-us")} className='row mt-2' style={{fontWeight:"700", fontSize:"24px", color:"#2F3EAC", lineHeight:"36px", gap:"10px", cursor:"pointer"}}>
              {section === "contact-us" ? <img src='/images/minus.svg' alt=''></img> : <img src='/images/add.svg' alt=''></img>}
              <div>Contact Us</div>
            </div>
            {section === 'contact-us' ? 
              <div style={{margin:"5px 22px"}}>
                <div><a href = "mailto: hello@filtpod.com" style={{color:"#2F3EAC"}} >Send us an email: hello@filtpod.com</a></div>
              </div>
              :''
            }*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
