import { Box, Divider, Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "../Button";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import CountdownTimer from "../CountdownTimer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LoginIcon from "@mui/icons-material/Login";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

export const PodCard = (props) => {
  const {
    pod,
    openDate,
    openTime,
    timezones,
    membersJoined,
    closeTime,
    isLive,
    onJoinSession,
    openDateString,
    onCancelPost,
  } = props;

  const navigate = useNavigate();
  const renderButton = () => {
    const isSubmissionOpen = pod.submissionOpen;
    const isEngagementOpen = pod.engagementOpen;
    const joined = pod.joined;

    if (isSubmissionOpen && pod.joined) {
      return (
        <Stack direction={"row"} gap={1}>
          <Button
            variant="outlined"
            startIcon={<CloseIcon />}
            color="error"
            style={{
              color: "#D61F1F",
              border: "1px solid #D61F1F",
              fontWeight: 600,
            }}
            onClick={onCancelPost}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            startIcon={<ModeEditIcon />}
            onClick={() => onJoinSession(true)}
            style={{
              fontWeight: 600,
            }}
          >
            Update post
          </Button>
        </Stack>
      );
    }

    if (isSubmissionOpen && !isEngagementOpen) {
      return (
        <Button
          startIcon={<AddCircleIcon />}
          style={{ fontWeight: 600 }}
          onClick={onJoinSession}
        >
          Join Session
        </Button>
      );
    }

    if (!isSubmissionOpen && !isEngagementOpen) {
      return (
        <Button disabled style={{ fontWeight: 600 }}>
          Come Back Later
        </Button>
      );
    }

    if (isEngagementOpen && !isSubmissionOpen && !joined) {
      return (
        <Button disabled style={{ fontWeight: 600 }}>
          Closed
        </Button>
      );
    }

    if (isEngagementOpen && !isSubmissionOpen && joined) {
      return (
        <Button
          variant="outlined"
          endIcon={<LoginIcon />}
          color="error"
          style={{
            color: "#D61F1F",
            border: "1px solid #D61F1F",
            fontWeight: 600,
          }}
          onClick={() => navigate(`/PodCommunity/Session/${pod.sessionId}`)}
        >
          Enter
        </Button>
      );
    }
  };

  const renderTimeline = () => {
    return (
      <Typography
        color={isLive ? "#D61F1F" : "#415058"}
        fontWeight={600}
        fontSize={".875rem"}
        pt={"1px"}
      >
        <CountdownTimer
          targetTime={openDateString}
          render={(timeLeft) => {
            const parts = [];
            if (timeLeft.days) parts.push(`${timeLeft.days}d`);
            if (timeLeft.hours) parts.push(`${timeLeft.hours}h`);
            if (timeLeft.minutes || parts.length === 0)
              parts.push(`${timeLeft.minutes}m`);

            if (isLive && parts[0] === "0m") {
              return <>Session Live</>;
            }
            return <>Start in {parts.join(" ")}</>;
          }}
        />
      </Typography>
    );
  };

  return (
    <Stack
      border="1px solid #E0E2E4"
      borderRadius={".875rem"}
      p={2.5}
      gap={1.5}
      backgroundColor="#FFF"
      style={{
        background: isLive
          ? "linear-gradient(90deg, rgba(214, 31, 31, 0.00) 0%, rgba(214, 31, 31, 0.03) 100%), #FFF"
          : "#FFF",
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack gap={"4px"}>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Typography color="#1F292E" fontSize={"1rem"} fontWeight={600}>
              {openDate}
            </Typography>
            <CircleIcon sx={{ width: 6, height: 6 }} color="#415058" />
            <Typography fontSize={"1rem"} color="#415058" fontWeight={600}>
              {openTime} - {closeTime}
            </Typography>
          </Stack>
          <Typography color="#415058" fontWeight={600} fontSize={".95rem"}>
            Recommended for {timezones} time zones
          </Typography>
        </Stack>
        <Box>{renderButton()}</Box>
      </Stack>
      <Divider />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
          <TimerOutlinedIcon
            fontSize="small"
            sx={{
              color: isLive ? "#D61F1F" : "#415058",
            }}
          />
          {renderTimeline()}
        </Stack>
        {membersJoined > 30 && (
          <Stack>
            <Typography color="#415058" fontWeight={600} fontSize={".875rem"}>
              + {membersJoined} members joined
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
