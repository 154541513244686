import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
} from "@mui/material";
import Button from "../../Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeletePost } from "../../../Services/PodComunnityService";
import { useState } from "react";
import toast from "react-hot-toast";

export const CancelPostModal = (props) => {
  const { isOpen, currentPod, closeModal, refetch } = props;

  // ====================== STATE
  const [loading, setLoading] = useState(false);

  // ====================== HANDLERS
  const onCancelPost = async () => {
    setLoading(true);
    try {
      const payload = {
        session_id: currentPod.sessionId,
        link: currentPod.link,
      };
      const res = await DeletePost(payload);
      const result = await res.json();
      if (result.status === "success") {
        toast.success(
          'Thank you for letting us know.\nWe confirm that you won"t be joining the engagement session later.',
          {
            style: {
              maxWidth: "600px",
            },
          }
        );
        refetch();
        closeModal();
      } else {
        toast.error("Error cancelling post, please try again later");
      }
    } catch (error) {
      toast.error("Error cancelling post, please try again later");
    } finally {
      setLoading(false);
    }
  };

  // ====================== RENDER
  const renderContent = () => {
    return (
      <Stack gap={3} pt={1} justifyContent={"center"} alignItems={"center"}>
        <Typography color="#415058" fontSize={"1.125rem"} fontWeight={500}>
          Are you sure you want to cancel your submission for this session?
        </Typography>
        <Stack justifyContent="center" gap={2} direction="row">
          <Button
            variant={"outlined"}
            style={{
              border: "1px solid #415058",
              color: "#415058",
              fontWeight: 600,
              paddingInline: "2rem",
            }}
            onClick={closeModal}
            disabled={loading}
          >
            No, Keep it
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            style={{
              fontWeight: 600,
              paddingInline: "2rem",
            }}
            color="error"
            disabled={loading}
            onClick={onCancelPost}
            loading={loading}
          >
            Yes, Cancel
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      PaperProps={{
        sx: {
          borderRadius: "18px",
          paddingBlock: ".75rem",
        },
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle
        color="#1F292E"
        fontSize={"1.65rem"}
        fontWeight={700}
        lineHeight={"1.2"}
        justifyContent={"center"}
        display={"flex"}
        alignItems={"center"}
        sx={{
          paddingTop: "1rem",
        }}
      >
        Cancel Session Submission?
      </DialogTitle>

      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};
